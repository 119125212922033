import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleBooking, handleAdress, handleService } from '@services';
import {
  CheckFromTimeToLength,
  CheckColorForStatus,
} from '@utils/Functions/statiscialConvert';
import { UserOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Button, Modal, Select, Input, Pagination, notification } from 'antd';
import { convertStringToList } from '@utils/Functions/configArray';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';

const SMStatiscal = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [search, setSearch] = useState<any>('');
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bookingDate =
    queryParams.get('bookingDate') || dayjs().format('YYYY-MM-DD');
  const refresh = queryParams.get('refresh');
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState<any>();
  const handleEdit = () => {};
  const handleDelete = () => {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [services, setServices] = useState<any>([]);
  const [listStaff, setListStaff] = useState<any>([]);
  const [staffId, setStaffId] = useState<any>();
  const [listBed, setListBed] = useState<any>([]);
  const [bedId, setBedId] = useState<any>();
  const [listServices, setListServices] = useState<any>([]);
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2;
  const [startIndex, setStartIndex] = useState((currentPage - 1) * pageSize);
  const [endIndex, setEndIndex] = useState(startIndex + pageSize);

  const handleGetListSalonStatic = () => {
    const urlParams: any = getParamsFromUrl(location);
    setTableLoading(true);
    const params = { ...urlParams };
    if (!params.salonId) {
      params.salonId = UserInfor?.salonIds?.join(',');
    }
    if (!params.bookingDate) {
      params.bookingDate = dayjs().format('YYYY-MM-DD');
    }
    handleBooking
      .StatiscialBookingSM(params)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setData(data);
          setDataCount(optional);
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const [listServicesFull, setListServicesFull] = useState([]);
  function findSeervicesName(value) {
    const option = listServicesFull.find((option) => option.value === value);
    return option ? option.label : '';
  }
  const handleListServicesSQL = async () => {
    handleService
      .dropdownServiceMarketing()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListServicesFull(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (UserInfor?.accountId) {
      handleGetListSalonStatic();
      handleListServicesSQL();
    }
  }, [bookingDate, UserInfor, refresh]);
  const handleGetListBed = async (salonId, bookingDate, timeId) => {
    const body = {
      salonId: salonId,
      bookingDate: bookingDate,
      fromTime: timeId,
    };
    handleBooking
      .ListBedInTime(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListBed(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListservicesDefault = async (listStringServices) => {
    handleBooking
      .GetListServicesSQL(listStringServices)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListStaffReady = async (time) => {
    const params = {
      salonId: UserInfor?.salonIds?.join(','),
      date: bookingDate,
      time: time,
    };
    handleBooking
      .GetListStaffReadyParams(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          const newData = data
            .filter((item) => item.ready === true)
            .map((item) => ({
              value: item.id,
              label: item.name,
            }));
          setListStaff(newData);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDetail = (schedules: any, name, id) => {
    const ktvId: any = schedules?.ktv?.id;
    if (ktvId !== undefined && !isNaN(ktvId)) {
      setStaffId(Number(ktvId));
    }

    setIsModalOpen(true);
    setBedId(id);
    setModalData({ ...schedules, bedName: name, bedId: id });
    handleGetListBed(
      UserInfor?.salonIds?.join(','),
      bookingDate,
      schedules?.fromTime?.slice(0, 5),
    );
    if (schedules?.service && schedules?.service?.length > 0) {
      const listStringServices = schedules?.service.map((ser) => {
        return ser.id;
      });
      handleGetListservicesDefault(listStringServices.join(','));
    }
    handleGetListStaffReady(schedules?.fromTime?.slice(0, 5));
  };
  const handleAddServices = (data: any) => {
    const indexToRemove = services.findIndex(
      (service: any) => service.idSQL === data.idSQL,
    );

    if (indexToRemove !== -1) {
      // Nếu có, xoá phần tử đó khỏi mảng services
      const updatedServices = [...services];
      updatedServices.splice(indexToRemove, 1);
      console.log(updatedServices, 'updated services');
      setServices(updatedServices);
      // Sử dụng updatedServices để làm gì đó tiếp theo nếu cần
    } else {
      const newServices = [...services];
      newServices.push(data);
      console.log(newServices, 'new services');
      setServices(newServices);
    }
  };
  const handleSearchServices = async () => {
    if (!search) {
      setListServices([]);
      setCurrentData([]);
      return;
    }
    setStartIndex(0);
    setEndIndex(pageSize);
    handleBooking
      .SearchServicesByName(search)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  useEffect(() => {
    if (listServices && listServices?.length > 0) {
      const newData = listServices.slice(startIndex, endIndex);
      setCurrentData(newData);
    }
  }, [listServices, currentPage]);
  const handleOk = async () => {
    const listSer = services.map((se, i) => {
      return se.idSQL;
    });
    const body = {
      bedId: bedId,
      bookingDate: bookingDate,
      serviceIds: listSer,
      promotionIds: [],
      fromTime: modalData?.fromTime?.slice(0, 5),
      id: modalData.id,
      serviceTime: modalData?.serviceTime,
      staffServiceId: staffId,
    };
    console.log(body, 'body');
    try {
      handleBooking
        .UpdateStatiscialBookingSM(body)
        .then((response: any) => {
          const { data, statusCode } = response;
          if (statusCode === 200) {
            console.log(data, 'data');
            notification.success({
              message: 'Thành công',
              description: 'Cập nhật thông tin booking thành công !!',
            });
            handleGetListSalonStatic();
            handleCancel();
          } else {
            throw new Error('Error');
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalData(null);
    setListStaff([]);
    setStaffId(null);
    setListBed([]);
    setBedId(null);
    setListServices([]);
    setCurrentData([]);
    setCurrentPage(1);
    setStartIndex(0);
    setServices([]);
  };
  return (
    <div>
      <Modal
        title="Gán Dịch vụ - Nhân viên"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        okText="Cập nhật"
        cancelText="Hủy bỏ"
        destroyOnClose
      >
        <div className="h-[60vh] overflow-y-scroll">
          <div>Thông tin cơ bản</div>
          <div>Khách hàng: {modalData?.customer?.phoneNumber}</div>
          <div>Khung giờ khách đặt: {modalData?.fromTime?.slice(0, 5)}</div>
          <div className="text-[#1677FF] font-bold mb-2">CHỌN GIƯỜNG</div>
          <div className="flex flex-wrap">
            {listBed.map((item, index) => (
              <Button
                className="mb-1 mr-1 text-[13px]"
                size="small"
                key={index}
                onClick={() => {
                  if (bedId === item.ID) {
                    setBedId(null);
                  } else {
                    setBedId(item.ID);
                  }
                }}
                type={bedId === item.ID ? 'primary' : 'default'}
                disabled={!item.status}
              >
                Giường {item.NAME}
              </Button>
            ))}
          </div>
          <div className="text-[#1677FF] font-bold mb-2 mt-1">CHỌN DỊCH VỤ</div>
          <div className="font-bold">Dịch vụ đã chọn</div>
          {services.map((data, index) => (
            <div
              key={index}
              className="flex py-3 rounded-[10px] my-5 w-[50%]"
              style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
            >
              <div
                style={{
                  backgroundImage: `url(${
                    convertStringToList(data?.image_url)[0]
                  })`,
                }}
                className="cover w-[140px] h-[110px] flex-none rounded-[5px] bg-cover bg-center"
              ></div>
              <div className="ml-5">
                <div className="mb-1">{data.name}</div>
                <div className="">
                  Giá: {data.cost?.toLocaleString('vi-VN')} đ
                </div>
                <div className="my-1">Thời gian: {data.time} phút</div>
                {services.some(
                  (service: any) => service.idSQL === data.idSQL,
                ) ? (
                  <Button
                    onClick={() => handleAddServices(data)}
                    className="text-white bg-red-600"
                  >
                    Xoá dịch vụ
                  </Button>
                ) : (
                  <Button onClick={() => handleAddServices(data)}>
                    Chọn dịch vụ
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div className="font-bold">Bổ sung dịch vụ</div>
          <div className="flex lg:mx-[20%]">
            <Input
              placeholder="Nhập tên dịch vụ"
              className="h-[40px]"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              type="primary"
              onClick={handleSearchServices}
              className="h-[40px]"
            >
              Tìm kiếm
            </Button>
          </div>
          <div>
            {currentData.map((data, index) => (
              <div
                key={index}
                className="lg:mx-[20%] max-lg:mx-[10%] flex border-b-[1px] border-[#999] py-3 px-5 my-3 rounded-[10px]"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
              >
                <div
                  style={{
                    backgroundImage: `url(${
                      convertStringToList(data?.image_url)[0]
                    })`,
                  }}
                  className="cover w-[140px] h-[110px] flex-none rounded-[5px] bg-cover bg-center"
                ></div>
                <div className="ml-5">
                  <div className="mb-1">{data.name}</div>
                  <div className="">
                    Giá: {data.cost?.toLocaleString('vi-VN')} đ
                  </div>
                  <div className="my-1">Thời gian: {data.time} phút</div>
                  {services.some(
                    (service: any) => service.idSQL === data.idSQL,
                  ) ? (
                    <Button
                      onClick={() => handleAddServices(data)}
                      className="text-white bg-red-600"
                    >
                      Xoá dịch vụ
                    </Button>
                  ) : (
                    <Button onClick={() => handleAddServices(data)}>
                      Chọn dịch vụ
                    </Button>
                  )}
                </div>
              </div>
            ))}
            <Pagination
              current={currentPage}
              total={listServices.length}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={handlePageChange}
              size="default"
              className="text-center sm:mt-10 sm:pb-10 max-sm:mt-5 max-sm:pb-5"
            />
          </div>
          <div className="flex items-center">
            <div className="text-[#1677FF] font-bold mb-2 mt-1">
              CHỌN NHÂN VIÊN
            </div>
            <Select
              className="ml-10 w-[300px]"
              showSearch
              placeholder="Lựa chọn nhân viên phục vụ"
              options={listStaff}
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={staffId}
              onChange={(value) => setStaffId(value)}
            ></Select>
          </div>
        </div>
      </Modal>
      <div className="flex justify-between mb-5">
        <div className="">
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-gradient-to-r from-[#1677FF90] to-[#1677FF30]"></div>
            <div className="ml-2">Khách chưa đến</div>
          </div>
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-gradient-to-r from-[#e34f3c90] to-[#e34f3c30]"></div>
            <div className="ml-2">Khách chờ tư vấn</div>
          </div>
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-gradient-to-r from-[#e6ab3c90] to-[#e6ab3c30]"></div>
            <div className="ml-2">Khách đang chờ phục vụ</div>
          </div>
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-gradient-to-r from-[#54ba3290] to-[#54ba3230]"></div>
            <div className="ml-2">Khách đang được phục vụ</div>
          </div>
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-gradient-to-r from-[#7732d190] to-[#7732d130]"></div>
            <div className="ml-2">Khách đã phục vụ xong</div>
          </div>
          <div className="flex items-center mb-1">
            <div className="w-[50px] h-[20px] bg-white"></div>
            <div className="ml-2">Khách đã huỷ</div>
          </div>
        </div>
        <div className="border-[0.5px] border-[#888] px-5 py-2 rounded-[5px]">
          <div className="flex">
            <div className="w-[230px] text-[#777]">Tổng khách chưa đến:</div>
            <div className="font-bold">{dataCount?.countBOOKED}</div>
          </div>
          <div className="flex">
            <div className="w-[230px] text-[#777]">Tổng khách chờ tư vấn:</div>
            <div className="font-bold">{dataCount?.countINSALON}</div>
          </div>
          <div className="flex">
            <div className="w-[230px] text-[#777]">
              Tổng khách đang chờ phục vụ:
            </div>
            <div className="font-bold">
              {dataCount?.countWAIT_FOR_KTV_CONFIRM}
            </div>
          </div>
          <div className="flex">
            <div className="w-[230px] text-[#777]">
              Tổng khách đang được phục vụ:
            </div>
            <div className="font-bold">{dataCount?.countINPROCESS}</div>
          </div>
          <div className="flex">
            <div className="w-[230px] text-[#777]">
              Tổng khách đã phục vụ xong:
            </div>
            <div className="font-bold">{dataCount?.countDONE}</div>
          </div>
          <div className="flex">
            <div className="w-[230px] text-[#777]">Tổng khách huỷ:</div>
            <div className="font-bold">{dataCount?.countCANCEL}</div>
          </div>
        </div>
      </div>
      <div
        className="overflow-x-scroll pb-[30px] mb-5"
        style={{ scrollSnapType: 'x mandatory' }}
      >
        <div className="flex mb-[10px]">
          <div className="w-[100px] flex-none  text-center py-2"></div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            08:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            09:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            10:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            11:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            12:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            13:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            14:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            15:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            16:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            17:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            18:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            19:30
          </div>
          <div className="w-[130px] flex-none bg-white  text-center py-2">
            20:30
          </div>
        </div>
        {data &&
          data.map((dataBed, index) => (
            <div className="flex mt-[5px] relative" key={index}>
              <div className="w-[110px] flex-none bg-white  text-center ml-[-20px] mr-[10px] h-[50px] flex flex-col justify-center">
                Giường {dataBed?.name}
              </div>
              <div className="relative">
                {dataBed &&
                  dataBed.schedules &&
                  dataBed.schedules.map((schedules, i) => (
                    <div
                      className={`cursor-pointer top-0 absolute flex-none h-[50px]`}
                      style={{
                        left: CheckFromTimeToLength(schedules?.fromTime),
                        width: schedules?.serviceTime * 130 + 'px',
                        backgroundImage: CheckColorForStatus(schedules?.status),
                      }}
                      onClick={() =>
                        handleDetail(schedules, dataBed.name, dataBed.id)
                      }
                    >
                      <div className="flex flex-col justify-center h-full">
                        <div className="flex items-center mx-1 ml-2">
                          <ShoppingOutlined className="text-[11px] mr-1" />
                          <div className="h-8 overflow-hidden">
                            <div className="text-[10px] relative top-[1px] truncate break-all flex flex-wrap">
                              {!schedules?.service ? (
                                <div>Chưa có dịch vụ</div>
                              ) : (
                                schedules.service.map((ser, k) => (
                                  <div key={k} className="flex items-center">
                                    <span>{findSeervicesName(ser?.id)}</span>
                                    {k !== schedules.service.length - 1 && (
                                      <span>, </span>
                                    )}
                                  </div>
                                ))
                              )}
                              {/* Hiển thị dấu ba chấm */}
                              {schedules?.service &&
                                schedules.service.length > 0 && (
                                  <div className="text-[#000] ml-[2px]">
                                    ...
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="flex items-center mx-1 ml-2">
                          <UserOutlined className="text-[10px] mr-1" />
                          <div className="text-[10px] relative top-[1px]">
                            {!schedules?.ktv ? (
                              'Chưa có nhân viên'
                            ) : (
                              <>{schedules?.ktv?.name}</>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SMStatiscal;
