import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleClinic } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddSalon as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../index.scss';

export const AddBillCheckout = () => {
  const navigate = useNavigate();
  const initialValues = {
    gender: true,
  };
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState<any>();
  const [selectedCityName, setSelectedCityName] = useState('');
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState<any>();
  const [selectedDistrictName, setSelectedDistrictName] = useState('');
  const [wards, setWards] = useState([]);
  const [selectedWards, setSelectedWards] = useState<any>();
  const [wardsName, setWardsName] = useState('');

  const [listPermission, setListPermission] = useState([]);
  const [permission, setPermission] = useState('');
  const [role, setRole] = useState('');

  const [imageUrl, setImageUrl] = useState<string>('');

  const title = 'Thêm mới Salon';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'salon';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      logoUrl: imageUrl,
      address: values.address,
      clinicCode: values.clinicCode,
    };
    console.log(body, 'body');
    handleClinic
      .CreateClinic(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo thông tin phòng khám',
          });
          navigate('/clinic');
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <CategoryForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh Salon</h4>
            <UploadCustomV2
              setImageUrl={setImageUrl}
              urlImage={imageUrl}
              imageClass={ImageCalssEnum.CardPhoto}
              forlderName={forlderName}
            ></UploadCustomV2>
          </div>
        </div>
      </div>
    </>
  );
};
