import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleFast } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterSalon } from '@components/Filter/Salon';
import TabelComponent from '@components/Table';
import { breadcrumbListInventory as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListInventory as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { FilterConfig } from '@components/Filter';
import '../index.scss';

export const ListInventory = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);

  const title = 'Danh sách tồn kho vật tư của Salon';
  const titleButton = 'Tạo phiếu đề nghị xuất kho';
  const routerAdd = '/inventory/list/add';
  const [token, setToken] = useState<any>();
  const handleLoginFast = async () => {
    const body = {
      Username: 'F3',
      Password: 'F3@$^2024!#',
    };
    handleFast
      .LoginFast(body)
      .then((response: any) => {
        if (response) {
          setToken(response.token);
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };
  const handleGetListCategory = async () => {
    const urlParams = { salonCode: 'SL001', dTo: '2024-03-13' };
    setTableLoading(true);
    handleFast
      .GetTonKhoVatTu(urlParams, token)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    handleLoginFast();
  }, []);
  useEffect(() => {
    if (token) {
      handleGetListCategory();
    }
  }, [token]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/service/category/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleDeleteStudent = (CategoryId: string) => {};
  const [dataFilter, setDataFilter] = useState([]);
  const keyName = 'CATEGORYCOLUMN';
  useEffect(() => {
    setDataFilter([
      {
        value: '1',
        label: 'Tên danh mục',
        type: 'input',
        name: 'name',
        placeholder: 'Nhập tên danh mục dịch vụ',
      },
      {
        value: '2',
        label: 'Hiện Booking',
        type: 'select',
        name: 'activeBooking',
        list: [
          {
            value: true,
            label: 'Hiện Booking',
          },
          {
            value: false,
            label: 'Ẩn Booking',
          },
        ],
        placeholder: 'Chọn trạng thái Booking',
      },
      {
        value: '3',
        label: 'Trạng thái',
        type: 'select',
        name: 'active',
        list: [
          {
            value: true,
            label: 'Hoạt động',
          },
          {
            value: false,
            label: 'Không hoạt động',
          },
        ],
        placeholder: 'Chọn trạng thái',
      },
    ]);
  }, []);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} vật tư</span>
    );
  };
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa danh mục"
          open={open}
          onOk={() => handleDeleteStudent(modalData.id)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          destroyOnClose
          // okButtonProps={{
          //   disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          // }}
        >
          <p>
            Bạn đồng ý xóa danh mục dịch vụ: <strong>{modalData.name}</strong>{' '}
            khỏi hệ thống ?
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns()}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
