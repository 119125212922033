import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleCheckout } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailTopupCustomer as breadcrumbItems } from '@components/Breadcrumb';
import { TopupForm } from '@components/Form/Topup';
import '../index.scss';

export const DetailTopupCustomer = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<any>({ config: false });

  const [imageUrl, setImageUrl] = useState<string>('');
  const [title, setTitle] = useState('Nạp Topup cho khách hàng');
  const titleButton = '';
  const routerAdd = '';

  const handleGetDetailTopup = async (categoryId) => {
    handleCheckout
      .detailTopup(categoryId)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setInitialValues({
            name: data.name,
            price: data.amount,
            phoneNumber: data.phoneNumber,
            config: false,
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDetailTopup(id);
    }
  }, [id]);
  const onFinish = (values: any) => {
    const body = {
      id: id,
      amount: values.amount,
    };
    console.log(body, 'body');
    handleCheckout
      .updateTopup(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Nạp Topup cho khách hàng thành công',
          });
          navigate('/customer/list-topup?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="py-10 mt-5 bg-white">
        <TopupForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
