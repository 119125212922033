import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { handleCustomer } from '@services';
import { useNavigate, useParams } from 'react-router-dom';

const TopupHistory = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [listBill, setListBill] = useState<any>([]);
  const handleGetListBill = async () => {
    const params = {
      pageIndex: 1,
      pageSize: 1000,
    };
    setLoading(true);
    handleCustomer
      .GetListTopupCutomers(id, params)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListBill(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetListBill();
    }
  }, [id]);
  return (
    <>
      {loading ? (
        <div className="flex justify-center py-20 mt-5 bg-white">
          <Spin />
        </div>
      ) : (
        <>
          {listBill && listBill.length > 0 ? (
            listBill.map((item, index) => (
              <div className="w-full mt-5 ">
                <div className="border-[1px] border-[#1677FF] w-[100px] text-center px-2 py-1 rounded-md text-[#1677FF]">
                  {item?.createAt}
                </div>
                <div className="flex items-start w-full px-4 py-4 mt-3 bg-white">
                  <div className="w-full ml-2">
                    <div className="flex justify-between mt-4">
                      <div className="w-full">
                        <div className="flex bg-[#dedede70] px-[7px] py-[2px] justify-between">
                          <div className="font-bold">TỔNG TIỀN</div>
                          <div className="w-[180px] text-right font-bold">
                            + {item?.amountIn?.toLocaleString('vi-VN')} đ
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="mt-5">Khách hàng chưa có lịch mua Topup nào</div>
          )}
        </>
      )}
    </>
  );
};

export default TopupHistory;
