import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleVoucher } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddVoucher as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { VoucherForm } from '@components/Form/Voucher';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import dayjs from 'dayjs';
import '../index.scss';

export const AddVoucher = () => {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [initialValues, setInitialValues] = useState<any>({
    config: false,
    activeBooking: true,
    active: true,
    discountType: 1,
  });
  const hanldeGenerateCode = async () => {
    handleVoucher
      .GenerateCodeVoucher()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setInitialValues({
            config: false,
            code: data,
            discountType: 1,
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    hanldeGenerateCode();
  }, []);
  const [imageUrl, setImageUrl] = useState<string>('');

  const title = 'Thêm mới ưu đãi';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'voucher';
  const [condition, setCondition] = useState([]);
  const onFinish = (values: any) => {
    // console.log(dayjs(values.startDate).format('YYYY-MM-DD'), 'values');
    if (values?.endDate?.isBefore(values.startDate)) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ngày kết thúc ưu đãi không thể trước ngày tạo ưu đãi!',
      });
    }
    const body = {
      name: values.name,
      code: values.code,
      categoryId: values.categoryId,
      spaServiceId: values.spaServiceId,
      discountType: values.discountType,
      discountValue: values.discountValue,
      description: values.description,
      active: values.active,
      activeBooking: values.activeBooking,
      imageUrl: imageUrl,
      startDate: values.startDate
        ? dayjs(values.startDate).format('YYYY-MM-DD')
        : null,
      endDate: values.endDate
        ? dayjs(values.endDate).format('YYYY-MM-DD')
        : null,
      conditionFreshers: condition,
      promotionMapApplyConditions: [],
      giftItems: values.giftItems,
      giftServiceIds: values.giftServiceIds,
      discountMax:
        values.discountType === 1 ? values.discountMax : values.discountValue,
    };
    console.log(body, 'body');
    handleVoucher
      .CreateVoucher(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo ưu đãi mới',
          });
          navigate('/voucher/list?pageSize=10&pageIndex=1');
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <VoucherForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            condition={condition}
            setCondition={setCondition}
            category={category}
            setCategory={setCategory}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh ưu đãi (một ảnh)</h4>
            <UploadCustomV2
              setImageUrl={setImageUrl}
              urlImage={imageUrl}
              imageClass={ImageCalssEnum.CardPhoto}
              forlderName={forlderName}
            ></UploadCustomV2>
          </div>
        </div>
      </div>
    </>
  );
};
