import React from 'react';
import { OPageSize } from '@configs/pageSize';
import { Table, Pagination } from 'antd';

const TabelComponent = ({
  columns,
  data,
  tableLoading,
  currentPage,
  handlePageChange,
  totalItems,
  customTotal,
}) => {
  return (
    <div className="table w-full mt-5">
      <Table
        columns={columns}
        dataSource={data}
        loading={tableLoading}
        pagination={false}
      />
      {totalItems !== null && (
        <div className="pagination-table-wrap">
          <Pagination
            current={currentPage}
            onChange={handlePageChange}
            total={totalItems}
            showTotal={() => customTotal()}
            pageSize={Number(OPageSize)}
            className="pagination-table"
          />
        </div>
      )}
    </div>
  );
};

export default TabelComponent;
