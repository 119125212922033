import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleSalon } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddSalon as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { SalonForm } from '@components/Form/Salon';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import '../index.scss';

export const AddSalon = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    activeBooking: true,
    active: true,
    config: false,
    latitude: 21.031885338435483,
    longitude: 105.78201669411709,
  });
  const [salonImagesUrl, setSalonImagesUrl] = useState([]);
  const [qrImagesUrl, setQRImagesUrl] = useState([]);
  const [rsmId, setRSMId] = useState();
  const [asmId, setASMId] = useState();
  const title = 'Thêm mới Salon';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'salon';

  const validateEmail = (value) => {
    if (value.includes(' ')) {
      return 'Email Không được chứa khoảng trắng';
    }
    // Sử dụng biểu thức chính quy để kiểm tra định dạng email
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    if (!emailPattern.test(value)) {
      return 'Định dạng email không hợp lệ';
    }
    return false;
  };
  const onFinish = (values: any) => {
    try {
      // validate email
      if (values.email) {
        if (validateEmail(values.email)) {
          notification.error({
            message: validateEmail(values.email),
          });
          return;
        }
      }
      const newListSalon = salonImagesUrl.map((item) => {
        return item.response;
      });
      const newListQR = qrImagesUrl.map((item) => {
        return item.response;
      });
      const body = {
        name: values.name,
        code: values.code,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        wardCode: values.wardCode,
        addressMore: values.addressMore,
        latitude: values.latitude,
        longitude: values.longitude,
        phone: values.phone,
        email: values.email,
        regionId: values.regionId,
        numberOfBeds: values.numberOfBeds,
        salonImagesUrl:
          removeNullAndUndefined(newListSalon).length > 0
            ? removeNullAndUndefined(newListSalon)
            : undefined,
        qrImagesUrl:
          removeNullAndUndefined(newListQR).length > 0
            ? removeNullAndUndefined(newListQR)
            : undefined,
        managerName: values.managerName,
        signature: values.signature,
        active: values.active,
        activeBooking: values.activeBooking,
        description: values.description,
        // fanpageUrl: values.fanpageUrl,
        // fanpageId: values.fanpageId,
      };
      console.log(body, 'body');
      handleSalon
        .CreateSalon(body)
        .then((response: any) => {
          const { message, statusCode } = response;
          if (statusCode === 200) {
            notification.success({
              message: 'Thành công',
              description: 'Bạn đã thành công tạo thông tin Salon',
            });
            navigate('/salon/list?pageSize=10&pageIndex=1');
          } else {
            notification.error({
              message: 'Lỗi',
              description: message,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <SalonForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            setInitialValues={setInitialValues}
            rsmId={rsmId}
            setRSMId={setRSMId}
            asmId={asmId}
            setASMId={setASMId}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh Salon</strong> (không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={salonImagesUrl}
              setFileList={setSalonImagesUrl}
              forlderName={forlderName}
              MaxCount={5}
            />
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh mã thanh toán QR </strong>(không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={qrImagesUrl}
              setFileList={setQRImagesUrl}
              forlderName={forlderName}
              MaxCount={5}
            />
          </div>
        </div>
      </div>
    </>
  );
};
