import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleSalon, handleAdress } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbTimekeepingAwaiting as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListTimekeepingAwait as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import '../index.scss';

export const ListAwaitingApproval = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const title = 'Danh sách lịch làm việc chờ duyệt';
  const titleButton = 'Đăng ký lịch làm';
  const routerAdd = '/timekeeping/create';

  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      const urlParams = getParamsFromUrl(location);
      setTableLoading(true);
      handleSalon
        .GetListSalon(urlParams)
        .then((response: any) => {
          const { statusCode, data, optional } = response;
          if (statusCode === 200) {
            setData(data);
            setCurrentPage(Number(optional?.pageIndex));
            setTotalItems(Number(optional?.getTotalElements));
            setTableLoading(false);
          } else {
            notification.error({
              message: 'Lỗi',
              description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
            });
            setTableLoading(false);
          }
        })
        .catch((error) => {
          setTableLoading(false);
          console.error(error);
        });
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/salon/${record.id}`);
  };

  const handleDelete = (record: any) => {
    // showModal(record);
  };
  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Mã/Tên Salon',
          type: 'input',
          name: 'code',
          placeholder: 'Nhập tên/ mã Salon',
        },
        {
          value: '2',
          label: 'Số điện thoại',
          type: 'input',
          name: 'phone',
          placeholder: 'Nhập số điện thoại Salon',
        },
        {
          value: '3',
          label: 'Trạng thái',
          type: 'select',
          name: 'active',
          placeholder: 'Lựa chọn trạng thái Salon',
          list: [
            {
              value: true,
              label: 'Hoạt động',
            },
            {
              value: false,
              label: 'Không hoạt động',
            },
          ],
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'TIMEKEPPINGAWAITCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} lịch làm chờ duyệt
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
