import { instance } from '@configs/instance';

const getListCategory = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/category-manager/get-category`,
    {
      params: param,
    },
  );
};

const createCategory = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/category-manager/create-category`,
    body,
  );
};
const updateCategory = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/category-manager/update-category`,
    body,
  );
};

const getDetailCategory = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/category-manager/get-category/${id}`,
  );
};

const getDropListCategory = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/category-manager/category-drop-list`,
  );
};

const updateBooking = (body): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/category-manager/update-active-booking`,
    body,
  );
};
const updateActive = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/category-manager/update-active`,
    body,
  );
};
const deleteCategory = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_ERP + `/category-manager/remove/${id}`,
  );
};
export default {
  getListCategory,
  createCategory,
  updateCategory,
  getDetailCategory,
  updateBooking,
  updateActive,
  deleteCategory,
  getDropListCategory,
};
