import AddressEndpoint from '@api/address';

export const GetListRegionRSM = async (): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getListRegionRSM();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListRegionASM = async (rsmId): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getListRegionASM(rsmId);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListCitys = async (): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getListCitys();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListDistricts = async (
  cityCode: string,
): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getListDistricts(cityCode);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetListWards = async (
  districtCode: string,
): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getListWards(districtCode);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetLatLong = async (address: string): Promise<any | Error> => {
  try {
    const response = await AddressEndpoint.getLatLongAPI(address);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
