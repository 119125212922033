import { instance } from '@configs/instance';

const getListCutomers = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/all`,
    {
      params: param,
    },
  );
};

const getDetailInforCutomers = (customerId: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/${customerId}/info`,
  );
};

const updateInforCutomers = (body: any): Promise<any> => {
  return instance.patch(
    process.env.REACT_APP_API_BOOKING +
      `/api/v1/customer/update-customer-by-id`,
    body,
  );
};

const getListBillCutomers = (customerId: any, param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/${customerId}/bills`,
    {
      params: param,
    },
  );
};

const getListPromotionCutomers = (
  customerId: any,
  param: any,
): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING +
      `/api/v1/customer/${customerId}/get-promotions`,
    {
      params: param,
    },
  );
};

const getListTopupCutomers = (customerId: any, param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING +
      `/api/v1/customer/${customerId}/topups-history`,
    {
      params: param,
    },
  );
};

const getListServicesCutomers = (customerId: any, param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING +
      `/api/v1/customer/${customerId}/services`,
    {
      params: param,
    },
  );
};
const getDetailCutomerByPhone = (phone: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/all?phone=${phone}`,
  );
};

const createCustomer = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/create-customer-info`,
    body,
  );
};

export default {
  getListCutomers,
  getDetailInforCutomers,
  updateInforCutomers,
  getListBillCutomers,
  getListPromotionCutomers,
  getListTopupCutomers,
  getListServicesCutomers,
  getDetailCutomerByPhone,
  createCustomer,
};
