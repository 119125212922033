import SalaryEndpoint from '@api/salary';
import axios from 'axios';
import Cookies from 'js-cookie';
import { notification } from 'antd';
export const ListSalary = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.listSalary(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const ListConfigSalary = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.listConfigSalary(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const CreateConfigSalary = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.createConfigSalary(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateConfigSalary = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.updateConfigSalary(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const DetailConfigSalary = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.detailConfigSalary(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
const getAccessTokenFromCookie = () => {
  return Cookies.get('accessToken'); // Thay đổi key này nếu cần
};

export const DownloadExcelSalary = async (userId: any, setLoading) => {
  try {
    setLoading(true); // Bắt đầu tải file, set loading = true

    const accessToken = getAccessTokenFromCookie();
    const url =
      process.env.REACT_APP_API_STAFF +
      `/salary/extra-bonus/export?userId=${userId}`;

    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const urlBlob = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = urlBlob;
    a.download = 'exported_data.xlsx';

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(urlBlob);
    document.body.removeChild(a);
  } catch (error) {
    console.error('Error downloading Excel:', error);
  } finally {
    setLoading(false); // Kết thúc tải file, set loading = false dù có lỗi hay không
  }
};

export const GetListSalaryBonus = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalaryEndpoint.getListSalaryBonus(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
