import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleSalon, handleAdress, handleBooking } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { CheckinFilterConfig } from '@components/Filter/CheckinFilterNormal';
import TabelComponent from '@components/Table/TableResponsive';
import { breadcrumbListCheckout as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListCheckout as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import '../index.scss';

export const ListCheckout = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([
    {
      value: '08:30',
      label: 'Khung giờ 08:30',
    },
    {
      value: '09:30',
      label: 'Khung giờ 09:30',
    },
    {
      value: '10:30',
      label: 'Khung giờ 10:30',
    },
    {
      value: '11:30',
      label: 'Khung giờ 11:30',
    },
    {
      value: '12:30',
      label: 'Khung giờ 12:30',
    },
    {
      value: '13:30',
      label: 'Khung giờ 13:30',
    },
    {
      value: '14:30',
      label: 'Khung giờ 14:30',
    },
    {
      value: '15:30',
      label: 'Khung giờ 15:30',
    },
    {
      value: '16:30',
      label: 'Khung giờ 16:30',
    },
    {
      value: '17:30',
      label: 'Khung giờ 17:30',
    },
    {
      value: '18:30',
      label: 'Khung giờ 18:30',
    },
    {
      value: '19:30',
      label: 'Khung giờ 19:30',
    },
    {
      value: '20:30',
      label: 'Khung giờ 20:30',
    },
  ]);
  const [dataFilter, setDataFilter] = useState([]);
  const title = 'Checkout - Danh sách khách chờ thanh toán';
  const titleButton = '';
  const routerAdd = '';

  const handleGetListBooking = async () => {
    const urlParams: any = getParamsFromUrl(location);
    if (!urlParams.bookingDate) {
      urlParams.bookingDate = dayjs().format('YYYY-MM-DD');
    }

    // urlParams.bookingDate = '2024-02-18';
    urlParams.status = '5';
    if (Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV') {
      urlParams.salonId = UserInfor?.salonIds[0];
      console.log('đã push');
    }
    setTableLoading(true);
    handleBooking
      .GetListBooking(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      UserInfor?.accountId &&
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListBooking();
    }
  }, [location, UserInfor]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEdit = async (record: any) => {
    console.log(record, 'record');
    navigate(`/checkout/list/${record.id}`);
  };

  const handleDelete = (record: any) => {
    console.log(record, 'record');
    navigate(`/customer/debt/${record.id}`);
  };

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Số điện thoại khách hàng',
          type: 'input',
          name: 'phoneNumber',
          placeholder: 'Số điện thoại khách hàng',
        },
        {
          value: '2',
          label: 'Khung giờ đặt lịch',
          type: 'select',
          name: 'fromTime',
          placeholder: 'Khung giờ đặt lịch',
          list: listCity,
        },
        {
          value: '3',
          label: 'Ngày',
          type: 'datepicker',
          name: 'bookingDate',
          placeholder: 'Lựa chọn ngày',
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'CHECKOUTCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} khách chờ thanh toán
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <CheckinFilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
