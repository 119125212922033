import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleSalon, handleAdress } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailSalon as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { SalonForm } from '@components/Form/Salon';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import '../index.scss';

export const DetailSalon = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const [salonImagesUrl, setSalonImagesUrl] = useState([]);
  const [qrImagesUrl, setQRImagesUrl] = useState([]);
  const [rsmId, setRSMId] = useState();
  const [asmId, setASMId] = useState();
  const [title, setTitle] = useState('Chi tiết Salon');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'salon';

  const handleGetDataSalonDetail = async (salonId) => {
    handleSalon
      .GetDetailSalon(salonId)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setTitle(data.name);
          setInitialValues({
            name: data.name,
            code: data.code,
            provinceCode: data.provinceCode,
            districtCode: data.districtCode,
            wardCode: data.wardCode,
            addressMore: data.addressMore,
            phone: data.phone,
            email: data.email,
            managerName: data.managerName,
            longitude: data.longitude,
            latitude: data.latitude,
            signature: data.signature,
            activeBooking: data.activeBooking,
            active: data.active,
            // fanpageUrl: data.fanpageUrl,
            // fanpageId: data.fanpageId,
            numberOfBeds: data.numberOfBeds,
            description: data.description,
            config: true,
            regionRsmId: data.rsm?.value,
            regionId: data.asm?.value,
          });
          setRSMId(data.rsm?.value);
          setASMId(data.asm?.value);
          if (data.salonImagesUrl && data.salonImagesUrl?.length > 0) {
            const listNews = data.salonImagesUrl.map((item, index) => {
              return {
                uid: (index + 1).toString(),
                lastModified: null,
                lastModifiedDate: '',
                name: item,
                size: 490015,
                type: 'image/png',
                percent: 100,
                originFileObj: {
                  uid: index + 1,
                  name: item,
                },
                status: 'done',
                response: item,
                thumbUrl: item,
                xhr: undefined,
              };
            });
            setSalonImagesUrl(listNews);
          }
          if (data.qrImagesUrl && data.qrImagesUrl?.length > 0) {
            const listNews = data.qrImagesUrl.map((item, index) => {
              return {
                uid: index,
                lastModified: null,
                lastModifiedDate: '',
                name: 'oke',
                size: 490015,
                type: 'image/png',
                percent: 100,
                originFileObj: {
                  uid: index,
                  name: item,
                },
                status: 'done',
                response: item,
                thumbUrl: item,
              };
            });

            setQRImagesUrl(listNews);
          }
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataSalonDetail(id);
    }
  }, [id]);
  const onFinish = (values: any) => {
    try {
      const newListSalon = salonImagesUrl.map((item) => {
        return item.response;
      });
      const newListQR = qrImagesUrl.map((item) => {
        return item.response;
      });
      const body = {
        id: Number(id),
        name: values.name,
        code: values.code,
        provinceCode: values.provinceCode,
        districtCode: values.districtCode,
        wardCode: values.wardCode,
        addressMore: values.addressMore,
        address: values.address,
        phone: values.phone,
        email: values.email,
        salonImagesUrl:
          removeNullAndUndefined(newListSalon).length > 0
            ? removeNullAndUndefined(newListSalon)
            : undefined,
        qrImagesUrl:
          removeNullAndUndefined(newListQR).length > 0
            ? removeNullAndUndefined(newListQR)
            : undefined,
        longitude: values.longitude,
        latitude: values.latitude,
        signature: values.signature,
        activeBooking: values.activeBooking,
        active: values.active,
        numberOfBeds: values.numberOfBeds,
        // fanpageUrl: values.fanpageUrl,
        // fanpageId: values.fanpageId,
        description: values.description,
        regionId: values.regionId,
      };
      console.log(body, 'body');
      handleSalon
        .UpdateSalon(body)
        .then((response: any) => {
          const { statusCode } = response;
          if (statusCode === 200) {
            notification.success({
              message: 'Thành công',
              description: 'Bạn đã thành công cập nhật thông tin Salon',
            });
            handleGetDataSalonDetail(id);
            // navigate('/salon/list');
          } else {
            throw new Error('Invalid');
          }
        })
        .catch((error) => {
          console.log(error, 'error');
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <SalonForm
            initialValues={initialValues}
            setInitialValues={setInitialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            rsmId={rsmId}
            setRSMId={setRSMId}
            asmId={asmId}
            setASMId={setASMId}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh Salon</strong> (không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={salonImagesUrl}
              setFileList={setSalonImagesUrl}
              forlderName={forlderName}
              MaxCount={5}
            />
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh mã thanh toán QR </strong>(không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={qrImagesUrl}
              setFileList={setQRImagesUrl}
              forlderName={forlderName}
              MaxCount={5}
            />
          </div>
        </div>
      </div>
    </>
  );
};
