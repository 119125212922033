import { instance } from '@configs/instance';

const getListSalon = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/salon-manager/get-salon`,
    {
      params: param,
    },
  );
};

const createSalon = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/salon-manager/create-salon`,
    body,
  );
};
const updateSalon = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/salon-manager/update-salon`,
    body,
  );
};

const getDetailSalon = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/salon-manager/get-salon/${id}`,
  );
};

const getDropListSalon = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/salon-manager/get-salon-drop-list`,
  );
};

const getDropListSalonFromRegion = (region: any): Promise<any> => {
  console.log(region, 'vai');
  return instance.get(
    process.env.REACT_APP_API_ERP +
      `/salon-manager/get-salon-by-region?region=rsm`,
  );
};

export default {
  getListSalon,
  createSalon,
  updateSalon,
  getDetailSalon,
  getDropListSalon,
  getDropListSalonFromRegion,
};
