import React, { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { QRCode, Button, Image, message } from 'antd'; // Thêm message từ antd để hiển thị thông báo
import { handleTimekeeping } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAttendance as breadcrumbItems } from '@components/Breadcrumb';
import '../index.scss';

export const Attendance = () => {
  const navigate = useNavigate();
  const [enableQR, setEnableQR] = useState(false);
  const [imageSrc, setImageSrc] = useState<string>('');
  const [countdown, setCountdown] = useState(30); // Thêm state để lưu trữ thời gian đếm ngược

  useEffect(() => {
    if (countdown === 0) {
      setEnableQR(false); // Khi hết thời gian đếm ngược, ẩn QR code
    } else {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown]);

  const handleGetQRCode = async () => {
    try {
      const response = await handleTimekeeping.GenerateQRCode();
      setImageSrc(`data:image/png;base64,${response.image}`);
      setEnableQR(true); // Hiển thị QR code khi nhận được response
      setCountdown(30); // Reset thời gian đếm ngược
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title="Điểm danh"
        titleButton=""
        routerAdd=""
      />
      <div className="flex flex-col items-center justify-center">
        {enableQR && <Image src={imageSrc} alt="QR Code" className="mt-8" />}
        <Button
          type="primary"
          className="mt-5"
          onClick={handleGetQRCode}
          disabled={enableQR}
        >
          Lấy Mã QR điểm danh mới
        </Button>
        {enableQR && <p>Hiệu lực QR trong {countdown} giây</p>}
      </div>
    </>
  );
};
