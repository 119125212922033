import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification, Button, Input, InputNumber } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { handleSalary } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddSalaryConfig as breadcrumbItems } from '@components/Breadcrumb';
import { SalaryForm } from '@components/Form/Salary';
import '../index.scss';

export const AddConfigSalary = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    config: true,
  });

  const [title, setTitle] = useState('Thêm mới cấu hình lương cơ bản');
  const titleButton = '';
  const routerAdd = '';

  const onFinish = async (values: any) => {
    const body = {
      name: values.name,
      department_id: 1,
      position_id: values.position_id,
      base_salary: values.base_salary,
      total_hours: values.total_hours,
    };
    console.log(body, 'body');
    handleSalary
      .CreateConfigSalary(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo cấu hình lương cơ bản mới',
          });
          navigate('/salary/config?pageSize=10&pageIndex=1');
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Lỗi',
          description: 'Đã có lỗi xảy ra trong quá trình xử lý dữ liệu',
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="py-10 mt-5 bg-white">
        <SalaryForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
