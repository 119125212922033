import React from 'react';
import { OPageSize } from '@configs/pageSize';
import { Table, Pagination } from 'antd';

const TabelComponent = ({
  columns,
  data,
  tableLoading,
  currentPage,
  handlePageChange,
  totalItems,
  customTotal,
}) => {
  return (
    <div className="table w-full mt-5 overflow-hidden">
      <Table
        columns={columns}
        dataSource={data}
        loading={tableLoading}
        pagination={false}
        scroll={{ x: 900 }}
        className="lg:w-[77vw] max-lg:w-[94vw] mt-5 overflow-scroll"
      />

      <div className="pagination-table-wrap">
        <Pagination
          current={currentPage}
          onChange={handlePageChange}
          total={totalItems}
          showTotal={() => customTotal()}
          pageSize={Number(OPageSize)}
          className="pagination-table"
        />
      </div>
    </div>
  );
};

export default TabelComponent;
