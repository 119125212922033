import { useState, useEffect } from 'react';
import { ColumnsStatisticalBooking as columns } from '@components/Table/Columns';
import TabelComponent from '@components/Table/TableResponsiveMax';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleBooking, handleAdress, handleService } from '@services';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { CalendarOutlined, UserOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

const AdminStatiscal = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refresh = queryParams.get('refresh');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [tableLoading, setTableLoading] = useState(false);
  const [data, setData] = useState([]);
  const handleEdit = () => {};
  const handleDelete = () => {};
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} Salon</span>
    );
  };

  const handleGetListSalonStatic = () => {
    const urlParams: any = getParamsFromUrl(location);
    setTableLoading(true);
    const params = { ...urlParams };
    if (!params.salonId) {
      params.salonId = UserInfor?.salonIds?.join(',');
    }
    if (!params.bookingDate) {
      params.bookingDate = dayjs().format('YYYY-MM-DD');
    }
    handleBooking
      .StatiscialBookingAdmin(params)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (UserInfor?.accountId && location) {
      handleGetListSalonStatic();
    }
  }, [location, UserInfor, refresh]);

  return (
    <div>
      <div>
        <div className="flex items-center">
          <CalendarOutlined className="relative top-[-1px] mr-[10px] text-[#888]" />
          <div>Số lượt booking</div>
        </div>
        <div className="flex items-center">
          <UserOutlined className="relative top-[-1px] mr-[10px] text-[#888]" />
          <div>Số nhân viên</div>
        </div>
      </div>
      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </div>
  );
};

export default AdminStatiscal;
