import { instance } from '@configs/instance';

const getListVoucher = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/promotion-manager/get-promotion`,
    {
      params: param,
    },
  );
};

const updateVoucher = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/promotion-manager/update-promotion`,
    body,
  );
};
const updateActive = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/promotion-manager/update-active`,
    body,
  );
};
const updateBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/promotion-manager/update-active-booking`,
    body,
  );
};
const createVoucher = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/promotion-manager/create-promotion`,
    body,
  );
};

const getDetailVoucher = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/promotion-manager/get-promotion/${id}`,
  );
};

const generateCodeVoucher = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP +
      `/promotion-manager/generate-promotion-code`,
  );
};
const getDropListApplicableSubjects = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP +
      `/promotion-manager/drop-list-type-apply-promotion`,
  );
};
const deleteVoucher = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_ERP + `/promotion-manager/remove/${id}`,
  );
};
export default {
  getListVoucher,
  updateVoucher,
  updateActive,
  updateBooking,
  createVoucher,
  getDetailVoucher,
  generateCodeVoucher,
  getDropListApplicableSubjects,
  deleteVoucher,
};
