import FastEndpoint from '@api/fast';
import { notification } from 'antd';
export const LoginFast = async (param: any): Promise<any | Error> => {
  try {
    const response = await FastEndpoint.loginFast(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetTonKhoVatTu = async (
  param: any,
  token: any,
): Promise<any | Error> => {
  try {
    const response = await FastEndpoint.getTonKhoVatTu(param, token);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
