import VoucherEndpoint from '@api/voucher';
import { notification } from 'antd';
export const GetListVoucher = async (param: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.getListVoucher(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateVoucher = async (body: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.updateVoucher(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateActive = async (body: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.updateActive(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.updateBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const CreateVoucher = async (body: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.createVoucher(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDetailVoucher = async (id: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.getDetailVoucher(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GenerateCodeVoucher = async (): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.generateCodeVoucher();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDropListApplicableSubjects = async (): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.getDropListApplicableSubjects();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const DeleteVoucher = async (id: any): Promise<any | Error> => {
  try {
    const response = await VoucherEndpoint.deleteVoucher(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
