import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification, Input, Button } from 'antd';
import { handleService } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddServices as breadcrumbItems } from '@components/Breadcrumb';
import UploadMutipleCustom from '@components/UploadImage/UploadMutiple';
import { ServicesForm } from '@components/Form/Services';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { removeNullAndUndefined } from '@utils/Functions/configArray';
import { v4 as uuidv4 } from 'uuid';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import '../index.scss';

export const AddServices = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({ config: false });
  const [imageUrl, setImageUrl] = useState<any>([]);
  const [listVideo, setListVideo] = useState([
    {
      id: uuidv4(),
      value: undefined,
    },
  ]);

  const title = 'Thêm mới dịch vụ';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'services';

  const hanldeGenerateCode = async () => {
    handleService
      .GenerateCodeService()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setInitialValues({
            config: false,
            code: data,
            bonusForCheckin: 0.02,
            bonusForStaff: 0.05,
            bonusForManager: 0,
            status: 0,
            active: true,
            activeBooking: true,
            combo: false,
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    hanldeGenerateCode();
  }, []);

  const onFinish = (values: any) => {
    const newListImages = imageUrl.map((item) => {
      return item.response;
    });
    const newListVideo = listVideo.map((item) => {
      return item.value;
    });
    const body = {
      name: values.name,
      code: values.code,
      categoryId: values.categoryId,
      combo: values.combo,
      comboServiceId: values.comboServiceId,
      cost: values.cost,
      bonusForCheckin: values.bonusForCheckin,
      bonusForStaff: values.bonusForStaff,
      bonusForManager: values.bonusForManager,
      serviceTime: values.serviceTime,
      activeBooking: values.activeBooking,
      active: values.active,
      status: values.status,
      description: values.description,
      imageUrl:
        removeNullAndUndefined(newListImages).length > 0
          ? removeNullAndUndefined(newListImages)
          : undefined,
      linkVideo: newListVideo,
    };
    console.log(body, 'body');
    handleService
      .CreateService(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo dịch vụ mới',
          });
          navigate('/service/list?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  const handleAddVideo = () => {
    const videos = [...listVideo];
    videos.push({
      id: uuidv4(),
      value: undefined,
    });
    setListVideo(videos);
  };
  const handleEditVideo = (id, newValue) => {
    const updatedVideos = listVideo.map((video) =>
      video.id === id ? { ...video, value: newValue } : video,
    );
    setListVideo(updatedVideos);
  };

  const handleDeleteVideo = (id) => {
    const updatedVideos = listVideo.filter((video) => video.id !== id);
    setListVideo(updatedVideos);
  };

  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <ServicesForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
        <div className="main-right">
          {/* <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Link video dịch vụ</strong>
            </h4>
            <div className="pb-4 mx-4 mt-4">
              {listVideo.map((data, i) => (
                <div key={i} className="flex mb-2">
                  <Input
                    placeholder="Nhập vào link video"
                    onChange={(e) => handleEditVideo(data.id, e.target.value)}
                  />
                  <Button onClick={() => handleDeleteVideo(data.id)}>
                    <DeleteOutlined className="relative top-[-2px]" />
                  </Button>
                </div>
              ))}
              <Button className="" onClick={() => handleAddVideo()}>
                <PlusCircleOutlined className="relative top-[-2px]" />
              </Button>
            </div>
          </div> */}
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              <strong>Ảnh dịch vụ </strong>(không quá 5 ảnh)
            </h4>
            <UploadMutipleCustom
              fileList={imageUrl}
              setFileList={setImageUrl}
              forlderName={forlderName}
              MaxCount={5}
            />
          </div>
        </div>
      </div>
    </>
  );
};
