import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleClinic } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterSalon } from '@components/Filter/Salon';
import TabelComponent from '@components/Table';
import { breadcrumbListSalon as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListSalon as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import '../index.scss';

export const ListServicesSalon = () => {
  const [loading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách Salon';
  const titleButton = 'Thêm mới Salon';
  const routerAdd = '/salon/add';

  useEffect(() => {
    if (location) {
      const urlParams = getParamsFromUrl(location);
      handleClinic
        .GetListClinics(urlParams)
        .then((response: any) => {
          const { statusCode, data, optional } = response;
          console.log(data, 'data');
          if (statusCode === 200) {
            setData(data);
            setCurrentPage(Number(optional?.pageIndex));
            setTotalItems(Number(optional?.getTotalElements));
            setTableLoading(false);
          } else {
            setTableLoading(false);
          }
        })
        .catch((error) => {
          setTableLoading(false);
          console.error(error);
        });
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/clinic/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleDeleteStudent = (permissionId: string) => {
    const body = {
      isActive: false,
      isDelete: true,
    };
    // handlePermission
    //   .UpdatePermission(permissionId, body)
    //   .then((response: any) => {
    //     const { statusCode, data } = response;
    //     if (statusCode === 201) {
    //       notification.success({
    //         message: 'Thành công',
    //         description: 'Xoá thành công dữ liệu phân quyền',
    //       });
    //       getDataTable();
    //       hideModal();
    //     } else {
    //       notification.error({
    //         message: 'Lỗi',
    //         description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const handleSelect1Change = (value) => {
    setSelect1Value(value);
    setSelect2Value(undefined);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [dataFilter, setDataFilter] = useState([]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} phòng khám
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa phân quyền"
          open={open}
          onOk={() => handleDeleteStudent(modalData._id)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          okButtonProps={{
            disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          }}
        >
          <p>
            {['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name) ? (
              <span style={{ color: 'red' }}>
                Thuộc nhóm quyền cố định của hệ thống! Không thể xoá
              </span>
            ) : (
              <>
                Bạn đồng ý xóa phân quyền <strong>{modalData.name}</strong> khỏi
                hệ thống ?
              </>
            )}
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterSalon />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
