import SalonEndpoint from '@api/salon';
import { notification } from 'antd';

export const GetListSalon = async (param: any): Promise<any | Error> => {
  try {
    const response = await SalonEndpoint.getListSalon(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDetailSalon = async (id: string): Promise<any | Error> => {
  try {
    const response = await SalonEndpoint.getDetailSalon(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const CreateSalon = async (body: any): Promise<any | Error> => {
  try {
    const response = await SalonEndpoint.createSalon(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateSalon = async (body: any): Promise<any | Error> => {
  try {
    const response = await SalonEndpoint.updateSalon(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDropListSalon = async (): Promise<any | Error> => {
  try {
    const response = await SalonEndpoint.getDropListSalon();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDropListSalonFromRegion = async (
  region: any,
): Promise<any | Error> => {
  try {
    console.log(region, 'region');
    const response = await SalonEndpoint.getDropListSalonFromRegion(region);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
