import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Select,
  Input,
  Modal,
  Checkbox,
  Pagination,
  InputNumber,
  notification,
} from 'antd';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { handleStaff, handleBooking, handleCustomer } from '@services';
import { convertStringToList } from '@utils/Functions/configArray';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
const { TextArea } = Input;

const CheckoutBill = ({
  dataDetail,
  listStringServices,
  setListStringServices,
  dataPrice,
  setDataPrice,
  dataVoucher,
}) => {
  const navigate = useNavigate();
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [paymoney, setPaymoney] = useState<any>(0);
  const [search, setSearch] = useState<any>('');
  const [services, setServices] = useState<any>([]);
  const [listServices, setListServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listStaff, setListStaff] = useState([]);
  const [staffCheckinId, setStaffCheckinId] = useState();
  const [staffKTVId, setStaffKTVId] = useState();
  const [staffTNId, setStaffTNId] = useState();
  const pageSize = 2;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalServices, setIsModalServices] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [totalMoney, setTotalMoney] = useState<any>(0);

  const handleGetListservicesDefault = async () => {
    handleBooking
      .GetListServicesSQL(listStringServices)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (dataDetail && dataDetail?.salonId && UserInfor?.accountId) {
      handleGetListStaff();
      setStaffCheckinId(dataDetail.staffCheckInId);
      setStaffKTVId(dataDetail.staffServiceId);
      setStaffTNId(UserInfor?.accountId);
    }
  }, [dataDetail, UserInfor?.accountId]);

  const handleGetListStaff = async () => {
    const params = {
      pageIndex: 1,
      pageSize: 100,
      salonId: dataDetail.salonId,
    };
    handleStaff
      .GetListStaff(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          if (data) {
            const newDataFilter = data.map((dataItem) => {
              return {
                label: dataItem.full_name,
                value: Number(dataItem.id),
              };
            });
            // bổ sung thêm admin hệ thống
            const newDatas = [
              ...newDataFilter,
              {
                label: 'ADMIN hệ thống',
                value: 1,
              },
            ];
            setListStaff(newDatas);
          }
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listStringServices) {
      handleGetListservicesDefault();
    }
  }, [listStringServices]);
  const columnsService = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: 'Mã DV',
      dataIndex: 'code',
      key: 'code',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'cost',
      render: (text) => <span>{text?.toLocaleString('vi-VN')}</span>,
      width: 120,
    },
  ];
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal2 = () => {
    setIsModalServices(true);
  };

  const handleOk2 = () => {
    setIsModalServices(false);
  };

  const handleCancel2 = () => {
    setIsModalServices(false);
  };
  const handleConfirm2 = () => {
    setIsModalServices(false);
  };

  const handleAddServices = (data: any) => {
    const indexToRemove = services.findIndex(
      (service: any) => service.idSQL === data.idSQL,
    );

    if (indexToRemove !== -1) {
      // Nếu có, xoá phần tử đó khỏi mảng services
      const updatedServices = [...services];
      updatedServices.splice(indexToRemove, 1);
      console.log(updatedServices, 'updated services');
      const listId = updatedServices.map((item, index) => {
        return item.idSQL;
      });
      setListStringServices(listId.join(','));
      setServices(updatedServices);
      // Sử dụng updatedServices để làm gì đó tiếp theo nếu cần
    } else {
      const newServices = [...services];
      newServices.push(data);
      console.log(newServices, 'new services');
      const listId = newServices.map((item, index) => {
        return item.idSQL;
      });
      setListStringServices(listId.join(','));
      setServices(newServices);
    }
  };
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
  };
  const handleSearchServices = async () => {
    if (!search) {
      setListServices([]);
      setCurrentData([]);
      return;
    }
    handleBooking
      .SearchServicesByName(search)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listServices && listServices.length > 0) {
      const newData = listServices.slice(startIndex, endIndex);
      setCurrentData(newData);
    }
  }, [listServices, currentPage]);
  useEffect(() => {
    if (services && services.length > 0) {
      const totalMinius = services.reduce(
        (sum, item) => sum + parseInt(item.time, 10),
        0,
      );
      setTotalMoney(
        services.reduce((sum, item) => sum + parseInt(item.cost, 10), 0),
      );
    }
  }, [services]);
  function calculateTimeDifference(startTime, endTime) {
    const startDate: any = new Date(`1970-01-01T${startTime}`);
    const endDate: any = new Date(`1970-01-01T${endTime}`);

    // Tính hiệu giữa hai thời điểm và chuyển đổi kết quả thành số (milliseconds)
    const timeDifference = endDate - startDate;

    // Chuyển đổi kết quả từ milliseconds thành giờ (1 giờ = 3600000 milliseconds)
    const hoursDifference = timeDifference / 3600000;

    return hoursDifference;
  }
  // chốt bill
  const handleConfirm = async () => {
    setIsModalOpen(false);

    const serviceIds = services.map((serviceId) => {
      return serviceId.idSQL;
    });
    const promotionIds = dataVoucher.map((promotion) => {
      return promotion.id;
    });
    const body = {
      bedId: dataDetail.bedId,
      bookingDate: dataDetail.bookingDate,
      serviceIds: serviceIds,
      promotionIds: promotionIds,
      fromTime: dataDetail.fromTime,
      price: totalMoney,
      id: dataDetail.id,
      staffServiceId: staffKTVId,
      staffCheckInId: staffCheckinId,
      serviceTime: calculateTimeDifference(
        dataDetail.fromTime,
        dataDetail.toTime,
      ),
    };
    console.log(body, 'body');
    // notification.success({
    //   message: 'Thành công',
    //   description:
    //     'Checkout thành công cho khách hàng, vui lòng tiếp tục thanh toán',
    // });
    // navigate(`/checkout/bill/edit/${dataDetail.id}`);
    handleBooking
      .CheckoutBooking(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          notification.success({
            message: 'Thành công',
            description:
              'Checkout thành công cho khách hàng, vui lòng tiếp tục thanh toán',
          });
          navigate(`/checkout/bill/edit/${data.id}`);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
        // notification.success({
        //   message: 'Lỗi',
        //   description: 'Có lỗi xảy ra trong quá trình checkout cho khách hàng',
        // });
      });
  };

  const handleCheckPrice = async () => {
    const serviceIds = services
      .map((sc) => sc.idSQL)
      .filter((id) => id !== null && id !== undefined);
    const promotionIds = dataDetail?.promotions
      .map((sc) => sc.id)
      .filter((id) => id !== null && id !== undefined);
    const body = {
      serviceIds: serviceIds,
      promotionIds: promotionIds,
    };
    handleBooking
      .CheckPriceByVoucher(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataPrice(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (services && services?.length > 0) {
      handleCheckPrice();
    } else {
      setDataPrice(null);
    }
  }, [services]);
  return (
    <>
      <div className="lg:flex-1 lg:ml-5">
        <div className="px-3 py-4 bg-white">
          <div className="flex items-center justify-between">
            <h3 className="font-bold">1. DỊCH VỤ</h3>
            <Button type="primary" size="small" onClick={showModal2}>
              Sửa dịch vụ
            </Button>
          </div>
          <Table
            columns={columnsService}
            dataSource={services}
            pagination={null}
            className="mt-4"
          />
          <div className="flex justify-end">
            <div className="mr-[30px] mt-3">
              <div className="flex justify-between">
                <div className="w-[130px]">Tổng giá dịch vụ:</div>
                <div className="">
                  {dataPrice?.totalMoney?.toLocaleString('vi-VN')} đ
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-[130px]">Giảm giá:</div>
                <div className="">
                  - {dataPrice?.totalDiscount?.toLocaleString('vi-VN')} đ
                </div>
              </div>
              <div className="flex justify-between">
                <div className="w-[130px]">Tổng Bill:</div>
                <div className="text-[green] font-bold">
                  {dataPrice?.totalBill?.toLocaleString('vi-VN')} đ
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-4 mt-5 bg-white">
          <div className="flex items-center justify-between">
            <h3 className="font-bold">2. NHÂN VIÊN PHỤC VỤ</h3>
          </div>
          <div className="flex justify-between mt-3 ml-5">
            <div>
              <div className="flex items-center">
                <div className="w-[150px] text-[#888]">Nhân viên Checkin:</div>
                <Select
                  showSearch
                  value={staffCheckinId}
                  onChange={(value) => setStaffCheckinId(value)}
                  className="w-[172px]"
                  placeholder="Lựa chọn nhân viên"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  options={listStaff}
                />
              </div>
              <div className="flex items-center mt-[5px]">
                <div className="w-[150px] text-[#888]">Nhân viên phục vụ:</div>
                <Select
                  showSearch
                  value={staffKTVId}
                  onChange={(value) => setStaffKTVId(value)}
                  className="w-[172px]"
                  placeholder="Lựa chọn nhân viên"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  options={listStaff}
                />
              </div>
              <div className="flex items-center mt-[5px]">
                <div className="w-[150px] text-[#888]">Nhân viên thu ngân:</div>
                <Select
                  showSearch
                  value={staffCheckinId}
                  onChange={(value) => setStaffCheckinId(value)}
                  className="w-[172px]"
                  placeholder="Lựa chọn nhân viên"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  options={listStaff}
                />
              </div>
            </div>
            {/* <div className="w-full ml-5">
              <div className="mb-2">Ghi chú checkout:</div>
              <TextArea placeholder="Nhập nội dung ghi chú" />
            </div> */}
          </div>
        </div>
        <Button
          type="primary"
          className="w-full mt-5 mb-20"
          onClick={handleConfirm}
        >
          XÁC NHẬN KHÁCH ĐÃ SỬ DỤNG DỊCH VỤ
        </Button>
        {/* <div className="px-3 py-4 mt-5 mb-10 bg-white">
          <div className="flex items-center justify-between">
            <h3 className="font-bold">3. CHỐT BILL</h3>
          </div>
          <div className="flex justify-between mt-3">
            <div className="w-[400px]">
              <div className="flex items-center justify-between w-full">
                <div className="w-[200px] text-[#888]">Tổng tiền:</div>
                <div>{totalMoney?.toLocaleString('vi-VN')} đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">Tổng giảm:</div>
                <div>0 đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">TỔNG BILL</div>
                <div>{totalMoney?.toLocaleString('vi-VN')} đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">
                  Số tiền KH thanh toán:
                </div>
              </div>
              <InputNumber
                placeholder="Nhập vào số tiền khách trả"
                className="mt-2 w-[260px]"
                value={paymoney}
                onChange={(value) => setPaymoney(value)}
              />
              {paymoney >= totalMoney && (
                <div className="text-[#1677FF] italic mt-2 mb-2">
                  Trả lại tiền thừa:{' '}
                  {(Number(paymoney) - Number(totalMoney))?.toLocaleString(
                    'vi-VN',
                  )}
                  đ
                </div>
              )}
              {paymoney < totalMoney && (
                <div className="flex items-center mt-[5px] w-full justify-between bg-[#8888881d] py-1 ">
                  <div className="w-[200px] text-[#888]">GHI NỢ</div>
                  <div>
                    {(Number(totalMoney) - Number(paymoney))?.toLocaleString(
                      'vi-VN',
                    )}
                    đ
                  </div>
                </div>
              )}
              <Button
                type="primary"
                className="w-full mt-4"
                onClick={showModal}
              >
                In hoá đơn & Mời khách xác nhận giá
              </Button>
            </div>
            <div className="ml-5" style={{ width: 'calc(100% - 420px)' }}>
              <div className="flex justify-between">
                <Button className="w-[100px] h-[100px] text-center flex flex-col items-center">
                  <img
                    src="/icon/cash.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">TIỀN MẶT</div>
                </Button>
                <Button className="w-[100px] h-[100px] text-center flex flex-col items-center">
                  <img
                    src="/icon/card.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">THẺ</div>
                </Button>
                <Button className="w-[100px] h-[100px] text-center flex flex-col items-center">
                  <img
                    src="/icon/topup.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">TOPUP</div>
                </Button>
              </div>
              <Button type="primary" className="w-full mt-5">
                HOÀN TẤT BILL
              </Button>
              <Checkbox
                onChange={() => setError(!error)}
                value={error}
                className="mt-3 mr-2"
              />
              Báo lỗi
              {error && (
                <div>
                  <TextArea
                    className="w-full mt-2"
                    placeholder="Nhập thông tin lỗi"
                  />
                </div>
              )}
            </div>
          </div>
        </div> */}
      </div>
      <Modal
        title="XÁC NHẬN DỊCH VỤ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <div className="border-t-[1px] pt-5">
          <div className="font-bold">1. DỊCH VỤ</div>
          <Table
            columns={columnsService}
            dataSource={services}
            pagination={null}
            className="mt-4"
          />
          <Button
            type="primary"
            className="w-full mt-3"
            onClick={handleConfirm}
          >
            MỜI KHÁCH XÁC NHẬN DỊCH VỤ
          </Button>
        </div>
      </Modal>
      <Modal
        title="Chỉnh sửa dịch vụ"
        open={isModalServices}
        onOk={handleOk2}
        onCancel={handleCancel2}
        footer={null}
        width={800}
      >
        <div className="border-t-[1px] pt-5">
          <div className="font-bold">1. DỊCH VỤ</div>
          <div className="flex mx-10 mt-5">
            <Input
              placeholder="Nhập tên dịch vụ"
              className="h-[40px]"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              type="primary"
              onClick={handleSearchServices}
              className="h-[40px]"
            >
              Tìm kiếm
            </Button>
          </div>
          <div>
            {currentData.map((data, index) => (
              <div
                key={index}
                className="mx-10 flex border-b-[1px] border-[#999] py-3"
              >
                <div
                  style={{
                    backgroundImage: `url(${convertStringToList(
                      data?.image_url,
                    )})`,
                  }}
                  className="cover w-[140px] h-[110px] flex-none rounded-[5px] bg-cover bg-center"
                ></div>
                <div className="ml-5">
                  <div className="mb-1">{data.name}</div>
                  <div className="">
                    Giá: {data.cost?.toLocaleString('vi-VN')} đ
                  </div>
                  <div className="my-1">Thời gian: {data.time} phút</div>
                  {services &&
                  services?.some(
                    (service: any) => service.idSQL === data.idSQL,
                  ) ? (
                    <Button
                      onClick={() => handleAddServices(data)}
                      className="text-white bg-red-600"
                    >
                      Xoá dịch vụ
                    </Button>
                  ) : (
                    <Button onClick={() => handleAddServices(data)}>
                      Chọn dịch vụ
                    </Button>
                  )}
                </div>
              </div>
            ))}
            <Pagination
              current={currentPage}
              total={listServices.length}
              pageSize={pageSize}
              showSizeChanger={false}
              onChange={handlePageChange}
              size="default"
              className="text-center sm:mt-10 sm:pb-10 max-sm:mt-5 max-sm:pb-5"
            />
          </div>
          <Button
            type="primary"
            className="w-full mt-3"
            onClick={handleConfirm2}
          >
            XÁC NHẬN DỊCH VỤ{' '}
            {services && services.length > 0 && (
              <span className="ml-3">({services.length} dịch vụ)</span>
            )}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CheckoutBill;
