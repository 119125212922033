// MainRouter.tsx
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page404 from '@pages/Errors/404';
import Page403 from '@pages/Errors/403';
import Page500 from '@pages/Errors/500';

import DashboardLayout from '@layouts/DashboardLayout';
import HomePage from '@pages/Home';
import { AddSalon } from '@pages/Salon/Add';
import { DetailSalon } from '@pages/Salon/Detail';
import { ListSalon } from '@pages/Salon/List';

import { ListCategory } from '@pages/Category/List';
import { AddCategory } from '@pages/Category/Add';
import { DetailCategory } from '@pages/Category/Detail';
import { ListServices } from '@pages/Services/List';
import { AddServices } from '@pages/Services/Add';
import { DetailServices } from '@pages/Services/Detail';
import { ListServicesSalon } from '@pages/ServicesSalon/List';

import { AddVoucher } from '@pages/Voucher/Add';
import { DetailVoucher } from '@pages/Voucher/Detail';
import { ListVoucher } from '@pages/Voucher/List';
import { SendVoucher } from '@pages/Voucher/Send';

import { AddStaff } from '@pages/Staff/Add';
import { ChangeStaff } from '@pages/Staff/Change';
import { DetailStaff } from '@pages/Staff/Detail';
import { ListStaff } from '@pages/Staff/List';

import { ListCustomer } from '@pages/Customer/List';
import { ListTopupCustomer } from '@pages/Customer/ListTopup';
import { AddTopupCustomer } from '@pages/Customer/AddTopup';
import { DetailTopupCustomer } from '@pages/Customer/DetailTopup';
import { DetailCustomer } from '@pages/Customer/Detail';
import { ListDebtCustomer } from '@pages/Customer/ListDebt';

import { AddBooking } from '@pages/Checkin/Add';
import { WebCheckin } from '@pages/Checkin/Chekin';
import { WebCheckinAdd } from '@pages/Checkin/ChekinAdd';
import { WebCheckinSalon } from '@pages/Checkin/CheckinSalon';
import { WebCheckinPhucVu } from '@pages/Checkin/CheckinPhucVu';
import { WebCheckinOnPhucVu } from '@pages/Checkin/CheckinOnPhucVu';
import { WebCheckinTuVanKhach } from '@pages/Checkin/ChekinTuVanKhach';
import { DetailBooking } from '@pages/Checkin/Detail';
import { ListBooking } from '@pages/Checkin/List';
import { Timeline } from '@pages/Checkin/Timeline';

import { AddBillCheckout } from '@pages/Checkout/Add';
import { DetailBillCheckout } from '@pages/Checkout/Detail';
import { ListCheckout } from '@pages/Checkout/List';
import { ListBillCheckout } from '@pages/Checkout/ListBill';
import { ViewBillCheckout } from '@pages/Checkout/ViewBill';
import { EditBillCheckout } from '@pages/Checkout/EditBill';

import { AddTimeKeeping } from '@pages/Timekeeping/AddV2';
import { Attendance } from '@pages/Timekeeping/Attendance';
import { ListTimeKeeping } from '@pages/Timekeeping/List';
import { ListAwaitingApproval } from '@pages/Timekeeping/AwaitingApproval';

import { ConfigSalary } from '@pages/Salary/Config';
import { ConfigSalaryDetail } from '@pages/Salary/Detail';
import { AddConfigSalary } from '@pages/Salary/AddConfig';
import { ListSalary } from '@pages/Salary/List';
import { BonusSalary } from '@pages/Salary/Bonus';

import { StatisticalBooking } from '@pages/Checkin/Statistical';
import { StatisticalBill } from '@pages/Checkout/Statistical';

import { ListInventory } from '@pages/Inventory/List';

const MainRouter = () => {
  return (
    <div>
      <Routes>
        {/* Sử dụng DashboardLayout cho các routes của MainRouter */}
        <Route
          path="/"
          element={
            <DashboardLayout>
              <HomePage />
            </DashboardLayout>
          }
        />
        <Route
          path="/salon/list"
          element={
            <DashboardLayout>
              <ListSalon />
            </DashboardLayout>
          }
        />
        <Route
          path="/salon/add"
          element={
            <DashboardLayout>
              <AddSalon />
            </DashboardLayout>
          }
        />
        <Route
          path="/salon/:id"
          element={
            <DashboardLayout>
              <DetailSalon />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/category"
          element={
            <DashboardLayout>
              <ListCategory />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/category/add"
          element={
            <DashboardLayout>
              <AddCategory />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/category/:id"
          element={
            <DashboardLayout>
              <DetailCategory />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/list"
          element={
            <DashboardLayout>
              <ListServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/list/add"
          element={
            <DashboardLayout>
              <AddServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/list/:id"
          element={
            <DashboardLayout>
              <DetailServices />
            </DashboardLayout>
          }
        />
        <Route
          path="/service/config"
          element={
            <DashboardLayout>
              <ListServicesSalon />
            </DashboardLayout>
          }
        />
        <Route
          path="/voucher/list"
          element={
            <DashboardLayout>
              <ListVoucher />
            </DashboardLayout>
          }
        />
        <Route
          path="/voucher/list"
          element={
            <DashboardLayout>
              <ListVoucher />
            </DashboardLayout>
          }
        />
        <Route
          path="/voucher/list/add"
          element={
            <DashboardLayout>
              <AddVoucher />
            </DashboardLayout>
          }
        />
        <Route
          path="/voucher/send"
          element={
            <DashboardLayout>
              <SendVoucher />
            </DashboardLayout>
          }
        />
        <Route
          path="/voucher/list/:id"
          element={
            <DashboardLayout>
              <DetailVoucher />
            </DashboardLayout>
          }
        />
        <Route
          path="/staff/list"
          element={
            <DashboardLayout>
              <ListStaff />
            </DashboardLayout>
          }
        />
        <Route
          path="/staff/list/add"
          element={
            <DashboardLayout>
              <AddStaff />
            </DashboardLayout>
          }
        />
        <Route
          path="/staff/list/:id"
          element={
            <DashboardLayout>
              <DetailStaff />
            </DashboardLayout>
          }
        />
        <Route
          path="/staff/change"
          element={
            <DashboardLayout>
              <ChangeStaff />
            </DashboardLayout>
          }
        />
        <Route
          path="/customer/list-customer"
          element={
            <DashboardLayout>
              <ListCustomer />
            </DashboardLayout>
          }
        />
        <Route
          path="/customer/list-topup"
          element={
            <DashboardLayout>
              <ListTopupCustomer />
            </DashboardLayout>
          }
        />
        <Route
          path="/customer/list-topup/add"
          element={
            <DashboardLayout>
              <AddTopupCustomer />
            </DashboardLayout>
          }
        />
        <Route
          path="/customer/list-topup/:id"
          element={
            <DashboardLayout>
              <DetailTopupCustomer />
            </DashboardLayout>
          }
        />

        <Route
          path="/customer/debt/:id"
          element={
            <DashboardLayout>
              <ListDebtCustomer />
            </DashboardLayout>
          }
        />
        <Route
          path="/customer/:id"
          element={
            <DashboardLayout>
              <DetailCustomer />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/statistical"
          element={
            <DashboardLayout>
              <StatisticalBooking />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/list-booking"
          element={
            <DashboardLayout>
              <ListBooking />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/list-booking/add"
          element={
            <DashboardLayout>
              <AddBooking />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/list-booking/:id"
          element={
            <DashboardLayout>
              <DetailBooking />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/list"
          element={
            <DashboardLayout>
              <WebCheckin />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/list/add"
          element={
            <DashboardLayout>
              <WebCheckinAdd />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/in-salon"
          element={
            <DashboardLayout>
              <WebCheckinSalon />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/in-salon/:id"
          element={
            <DashboardLayout>
              <WebCheckinTuVanKhach />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/cho-phuc-vu"
          element={
            <DashboardLayout>
              <WebCheckinPhucVu />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkin/dang-phuc-vu"
          element={
            <DashboardLayout>
              <WebCheckinOnPhucVu />
            </DashboardLayout>
          }
        />
        {/* <Route
          path="/checkin/timeline"
          element={
            <DashboardLayout>
              <Timeline />
            </DashboardLayout>
          }
        /> */}
        <Route
          path="/checkout/statistical"
          element={
            <DashboardLayout>
              <StatisticalBill />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/list"
          element={
            <DashboardLayout>
              <ListCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/list/add"
          element={
            <DashboardLayout>
              <AddBillCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/bill/view/:id"
          element={
            <DashboardLayout>
              <ViewBillCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/bill/edit/:id"
          element={
            <DashboardLayout>
              <EditBillCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/list/:id"
          element={
            <DashboardLayout>
              <DetailBillCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/checkout/bill"
          element={
            <DashboardLayout>
              <ListBillCheckout />
            </DashboardLayout>
          }
        />
        <Route
          path="/timekeeping/attendance"
          element={
            <DashboardLayout>
              <Attendance />
            </DashboardLayout>
          }
        />
        <Route
          path="/timekeeping/create"
          element={
            <DashboardLayout>
              <AddTimeKeeping />
            </DashboardLayout>
          }
        />
        <Route
          path="/timekeeping/list"
          element={
            <DashboardLayout>
              <ListTimeKeeping />
            </DashboardLayout>
          }
        />
        <Route
          path="/timekeeping/awaiting"
          element={
            <DashboardLayout>
              <ListAwaitingApproval />
            </DashboardLayout>
          }
        />
        <Route
          path="/salary/list"
          element={
            <DashboardLayout>
              <ListSalary />
            </DashboardLayout>
          }
        />
        <Route
          path="/salary/config"
          element={
            <DashboardLayout>
              <ConfigSalary />
            </DashboardLayout>
          }
        />
        <Route
          path="/salary/config/add"
          element={
            <DashboardLayout>
              <AddConfigSalary />
            </DashboardLayout>
          }
        />
        <Route
          path="/salary/config/:id"
          element={
            <DashboardLayout>
              <ConfigSalaryDetail />
            </DashboardLayout>
          }
        />
        <Route
          path="/salary/bonus"
          element={
            <DashboardLayout>
              <BonusSalary />
            </DashboardLayout>
          }
        />
        {/* Kho */}
        <Route
          path="/inventory/list"
          element={
            <DashboardLayout>
              <ListInventory />
            </DashboardLayout>
          }
        />
        <Route
          path="/403"
          element={
            <DashboardLayout>
              <Page403 />
            </DashboardLayout>
          }
        />

        <Route
          path="/500"
          element={
            <DashboardLayout>
              <Page500 />
            </DashboardLayout>
          }
        />

        <Route
          path="/*"
          element={
            <DashboardLayout>
              <Page404 />
            </DashboardLayout>
          }
        />
      </Routes>
    </div>
  );
};

export default MainRouter;
