import CheckoutEndpoint from '@api/checkout';
import { notification } from 'antd';

export const GetListStaff = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.getListStaff(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const sendOTPDebt = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.sendOTPDebt(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const verifyOTPDebt = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.verifyOTPDebt(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const paymentBill = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.paymentBill(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const createTopup = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.createTopup(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};
export const updateTopup = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.updateTopup(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const listTopup = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.listTopup(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const listBill = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.listBill(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const detailTopup = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.detailTopup(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const payAllBill = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.payAllBill(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const detailBill = async (param: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.detailBill(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const listBillCutomer = async (
  cutomerId: any,
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.listBillCutomer(cutomerId, param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const confirmBillError = async (body: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.confirmBillError(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const StatiscialBill = async (params: any): Promise<any | Error> => {
  try {
    const response = await CheckoutEndpoint.statiscialBill(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
