import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleClinic } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddBooking as breadcrumbItems } from '@components/Breadcrumb';
import { BookingForm } from '@components/Form/Booking';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../index.scss';

export const DetailBooking = () => {
  const navigate = useNavigate();
  const initialValues = {
    gender: true,
  };

  const title = 'Tạo mới Booking';
  const titleButton = '';
  const routerAdd = '';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      address: values.address,
      clinicCode: values.clinicCode,
    };
    console.log(body, 'body');
    handleClinic
      .CreateClinic(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo thông tin phòng khám',
          });
          navigate('/clinic');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="py-10 mt-5 bg-white">
        <BookingForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
