import ClinicEndpoint from '@api/clinic';

export const GetListClinics = async (param: any): Promise<any | Error> => {
  try {
    const response = await ClinicEndpoint.getListClinics(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const GetDetailClinics = async (id: string): Promise<any | Error> => {
  try {
    const response = await ClinicEndpoint.getDetailClinics(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};

export const CreateClinic = async (body: any): Promise<any | Error> => {
  try {
    const response = await ClinicEndpoint.createClinic(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    if (error instanceof Error) {
      return error;
    } else {
      return new Error('Unexpected error');
    }
  }
};
