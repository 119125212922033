import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleCategory } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailCategory as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../index.scss';

export const DetailCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<any>({ config: true });

  const [imageUrl, setImageUrl] = useState<string>('');
  const [title, setTitle] = useState('Thêm mới danh mục dịch vụ');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const handleGetDataSalonDetail = async (categoryId) => {
    handleCategory
      .GetDetailCategory(categoryId)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setTitle(data.name);
          setInitialValues({
            name: data.name,
            activeBooking: data.activeBooking,
            active: data.active,
            description: data.description,
            config: true,
          });
          setImageUrl(data.imageUrl);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDataSalonDetail(id);
    }
  }, [id]);
  const onFinish = (values: any) => {
    const body = {
      id: id,
      name: values.name,
      activeBooking: values.activeBooking,
      active: values.active,
      description: values.description,
      imageUrl: imageUrl,
    };
    console.log(body, 'body');
    handleCategory
      .UpdateCategory(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Câp nhật thông tin danh mục dịch vụ thành công',
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <CategoryForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              Ảnh Icon danh mục dịch vụ
            </h4>
            <UploadCustomV2
              setImageUrl={setImageUrl}
              urlImage={imageUrl}
              imageClass={ImageCalssEnum.CardPhoto}
              forlderName={forlderName}
            ></UploadCustomV2>
          </div>
        </div>
      </div>
    </>
  );
};
