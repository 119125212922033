import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleCheckout } from '@services';
import TabelComponent from '@components/Table';
import { breadcrumbStaticficalBill as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListTimeKeeping as columns } from '@components/Table/Columns';
import { getParamsFromUrlConfigUnLimited } from '@utils/Functions/getParams';
import { StaticFialFilter } from '@components/Filter/StaticfialBill';
import { useDispatch, useSelector } from 'react-redux';
import { ShopOutlined } from '@ant-design/icons';
import '../index.scss';

export const StatisticalBill = () => {
  const [loading] = useState(false);
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const dateTo = queryParams.get('dateTo');
  const dateFrom = queryParams.get('dateFrom');
  const refresh = queryParams.get('refresh');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [dataShiftA, setDataShiftA] = useState<any>();
  const [dataShiftB, setDataShiftB] = useState<any>();
  const [dataShiftC, setDataShiftC] = useState<any>();
  const [dataTotal, setDataTotal] = useState<any>();
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const salonId = queryParams.get('salonId');
  const title = 'Thống kê hoá đơn của salon';
  const titleButton = '';
  const routerAdd = '';
  const keyName = '';
  const handleGetListTimeKeeping = async () => {
    setTableLoading(true);
    const urlParams = getParamsFromUrlConfigUnLimited(location);
    if (Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV') {
      console.log('role SM');
      urlParams.salonId = UserInfor?.salonIds[0];
    }
    if (!dateFrom || !dateTo) {
      return;
    }
    if (
      !salonId &&
      Role !== 'SM' &&
      Role !== 'SHIFT_LEADER' &&
      Role !== 'KTV'
    ) {
      return;
    }
    console.log(urlParams, 'urlParams');
    handleCheckout
      .StatiscialBill(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setTableLoading(false);
          setDataShiftA(data?.shifts?.A);
          setDataShiftB(data?.shifts?.B);
          setDataShiftC(data?.shifts?.C);
          setDataTotal(data?.total);
        } else {
          setTableLoading(false);
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (UserInfor) {
      handleGetListTimeKeeping();
    }
  }, [salonId, UserInfor, dateFrom, dateTo, refresh]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleDelete = (record: any) => {
    console.log(record, 'record');
    showModal(record);
  };

  const [dataFilter, setDataFilter] = useState([]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} nhân viên
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <StaticFialFilter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
      />
      {/* <div className="flex items-center mb-8">
        <ShopOutlined className="text-[24px]" />
        <div className="ml-3 font-bold"></div>
      </div> */}
      <div className="flex justify-between mb-10">
        <div className="w-[47%]">
          <div className="bg-[#1677ff27] px-5">
            <div className="font-bold w-full border-t-[4px] border-[#1677FF] py-1">
              Ca 1 (08:30 - 12:30)
            </div>
          </div>
          {dataShiftA && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="">
                  <span className="text-[#888]">Tổng dịch vụ:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.totalServices} dịch vụ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán tiền mặt:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.paidByCashBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Tổng hoá đơn:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.totalBills} hoá đơn
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán chuyển khoản:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.paidByQRBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Doanh thu:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.income?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán Topup:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftA?.paidByTopUpBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
            </div>
          )}

          <div className="bg-[#1677ff27] px-5 mt-10">
            <div className="font-bold w-full border-t-[4px] border-[#1677FF] py-1">
              Ca 2 (12:30 - 16:30)
            </div>
          </div>
          {dataShiftB && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="">
                  <span className="text-[#888]">Tổng dịch vụ:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.totalServices} dịch vụ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán tiền mặt:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.paidByCashBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Tổng hoá đơn:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.totalBills} hoá đơn
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán chuyển khoản:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.paidByQRBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Doanh thu:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.income?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán Topup:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftB?.paidByTopUpBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="bg-[#1677ff27] px-5 mt-10">
            <div className="font-bold w-full border-t-[4px] border-[#1677FF] py-1">
              Ca 3 (16:30 - 21:00)
            </div>
          </div>
          {dataShiftC && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="">
                  <span className="text-[#888]">Tổng dịch vụ:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.totalServices} dịch vụ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán tiền mặt:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.paidByCashBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Tổng hoá đơn:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.totalBills} hoá đơn
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán chuyển khoản:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.paidByQRBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Doanh thu:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.income?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán Topup:</span>
                  <span className="ml-1 font-bold">
                    {dataShiftC?.paidByTopUpBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-[47%]">
          <div className="bg-[#1677ff27] px-5">
            <div className="font-bold w-full border-t-[4px] border-[#1677FF] py-1">
              Tổng doanh thu
            </div>
          </div>
          {dataTotal && (
            <div className="mt-5">
              <div className="flex justify-between">
                <div className="">
                  <span className="text-[#888]">Tổng dịch vụ:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.totalServices} dịch vụ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán tiền mặt:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.paidByCashBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Tổng hoá đơn:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.totalBills} hoá đơn
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán chuyển khoản:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.paidByQRBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
              <div className="flex justify-between mt-1">
                <div className="">
                  <span className="text-[#888]">Doanh thu:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.income?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
                <div className="">
                  <span className="text-[#888]">Thanh toán Topup:</span>
                  <span className="ml-1 font-bold">
                    {dataTotal?.paidByTopUpBills?.toLocaleString('vi-VN')} đ
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  );
};
