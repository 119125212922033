import React from 'react';
import { Input, Select, DatePicker, Form, InputNumber } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import {
  saveDataToStorage,
  getDataFromStorage,
} from '@utils/Functions/dataStorage';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';

const DynamicForm = ({
  columnSearch,
  setColumnSearch,
  dataFilter,
  setDataFilter,
  keyName,
  initialValues,
  form,
}) => {
  const handleremoveColumn = (item) => {
    const newSearch = [...columnSearch];
    const SearchFilter = newSearch.filter((a) => {
      return a.value !== item.value;
    });
    setColumnSearch(SearchFilter);
    saveDataToStorage(keyName, SearchFilter);
    const newSearchE = [...dataFilter];
    newSearchE.push(item);
    setDataFilter(newSearchE);
  };
  return (
    <>
      {columnSearch.map((item) => {
        console.log(item, 'item');
        switch (item.type) {
          case 'input':
            return (
              <div className="relative top-[4px]">
                <Form.Item
                  key={JSON.stringify(initialValues)}
                  name={item.name}
                  className="w-[260px] mr-5"
                >
                  <Input placeholder={item.placeholder} />
                </Form.Item>
                <CloseCircleOutlined
                  className="absolute top-[-3px] right-[12px] cursor-pointer text-[#888888] hover:text-[#1677FF]"
                  onClick={() => handleremoveColumn(item)}
                />
              </div>
            );
          case 'inputNumber':
            return (
              <div className="relative top-[4px]">
                <Form.Item
                  key={JSON.stringify(initialValues)}
                  name={item.name}
                  className="w-[260px] mr-5"
                >
                  <InputNumber
                    placeholder={item.placeholder}
                    min={0}
                    formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                    className="w-full"
                  />
                </Form.Item>
                <CloseCircleOutlined
                  className="absolute top-[-3px] right-[12px] cursor-pointer text-[#888888] hover:text-[#1677FF]"
                  onClick={() => handleremoveColumn(item)}
                />
              </div>
            );
          case 'select':
            return (
              <div className="relative top-[4px]">
                <Form.Item
                  key={item.defaultValue}
                  name={item.name}
                  className="w-[260px] mr-5"
                >
                  <Select
                    showSearch
                    placeholder={item.placeholder}
                    options={item.list}
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      removeVietnamese(option?.label ?? '').includes(
                        removeVietnamese(input),
                      )
                    }
                  ></Select>
                </Form.Item>
                <CloseCircleOutlined
                  className="absolute top-[-3px] right-[12px] cursor-pointer text-[#888888] hover:text-[#1677FF]"
                  onClick={() => handleremoveColumn(item)}
                />
              </div>
            );
          case 'mutiselect':
            return (
              <div className="relative top-[4px]">
                <Form.Item
                  key={item.defaultValue}
                  name={item.name}
                  className="w-[260px] mr-5"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    placeholder={item.placeholder}
                    options={item.list}
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      removeVietnamese(option?.label ?? '').includes(
                        removeVietnamese(input),
                      )
                    }
                  ></Select>
                </Form.Item>
                <CloseCircleOutlined
                  className="absolute top-[-3px] right-[12px] cursor-pointer text-[#888888] hover:text-[#1677FF]"
                  onClick={() => handleremoveColumn(item)}
                />
              </div>
            );
          case 'datepicker':
            return (
              <div className="relative top-[4px]">
                <Form.Item
                  key={item.defaultValue}
                  name={item.name}
                  className="w-[260px] mr-5"
                >
                  <DatePicker
                    key={item.name}
                    placeholder={item.placeholder}
                    className="w-full"
                    format={'DD/MM/YYYY'}
                  />
                </Form.Item>

                <CloseCircleOutlined
                  className="absolute top-[-3px] right-[12px] cursor-pointer text-[#888888] hover:text-[#1677FF]"
                  onClick={() => handleremoveColumn(item)}
                />
              </div>
            );
          default:
            return null;
        }
      })}
    </>
  );
};

export default DynamicForm;
