import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Radio,
  notification,
  Switch,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FieldType } from './type';
import {
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateAdress,
  validateFullName,
} from '@utils/Functions/validate';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';

export const BookingForm = ({ initialValues, onFinish, onFinishFailed }) => {
  const [listSalon, setListSalon] = useState([]);
  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item<FieldType>
        label="Nhập SĐT khách"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên danh mục dịch vụ',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập số điện thoại của khách hàng" />
      </Form.Item>
      <Form.Item<FieldType>
        label="Lựa chọn Salon"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn Salon"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listSalon}
        />
      </Form.Item>
      <Form.Item<FieldType>
        label="Lựa chọn dịch vụ"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Lựa chọn dịch vụ"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listSalon}
        />
      </Form.Item>
      <Form.Item<FieldType> label="Lựa chọn ưu đãi" name="address">
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Lựa chọn ưu đãi"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listSalon}
        />
      </Form.Item>
      <Form.Item<FieldType> label="Lựa chọn nhân viên phục vụ" name="address">
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn ưu đãi"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listSalon}
        />
      </Form.Item>
      <Form.Item<FieldType>
        label="Lựa chọn ngày"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <DatePicker className="w-full" placeholder="Lựa chọn ngày" />
      </Form.Item>
      <Form.Item<FieldType>
        label="Lựa chọn khung giờ"
        name="address"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <div className="flex w-full pb-3 overflow-x-scroll">
          <div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
          <div className="ml-2">
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF]">
              13h00
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h15
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h30
            </div>
            <div className="border-[1px] w-[80px] text-center py-[5px] cursor-pointer rounded-[5px] hover:border-[#1677FF] hover:text-[#1677FF] mt-2">
              13h45
            </div>
          </div>
        </div>
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.id ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
