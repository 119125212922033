import { instance } from '@configs/instance';

const getListStaff = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs`, {
    params: param,
  });
};

const createStaff = (body: any): Promise<any> => {
  return instance.post(process.env.REACT_APP_API_STAFF + `/staffs`, body);
};
const updateStaff = (body: any): Promise<any> => {
  return instance.put(process.env.REACT_APP_API_STAFF + `/staffs`, body);
};

const getDetailStaff = (id: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/${id}`);
};
const getDropListStaff = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/dropdown`, {
    params: param,
  });
};

const getListStaffChange = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/turnover`, {
    params: param,
  });
};

const changeSalon = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_STAFF + `/staffs/turnover`,
    body,
  );
};

const getDropList = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/dropdown`, {
    params: param,
  });
};

const getDropListLevel = (positionId: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF +
      `/staffs/available-levels?positionId=${positionId}`,
  );
};

const getDropListPosition = (departmentId: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF +
      `/staffs/available-positions?departmentId=${departmentId}`,
  );
};
const getDropListPositionFull = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF + `/staffs/available-positions`,
  );
};

const getCurrent = (): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/current`);
};

export default {
  getListStaff,
  createStaff,
  updateStaff,
  getDetailStaff,
  getDropListStaff,
  getListStaffChange,
  changeSalon,
  getDropList,
  getDropListLevel,
  getDropListPosition,
  getDropListPositionFull,
  getCurrent,
};
