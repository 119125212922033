import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Spin,
  Table,
  DatePicker,
  Input,
  Select,
  InputNumber,
  notification,
} from 'antd';
import {
  handleSalon,
  handleAdress,
  handleCustomer,
  handleCheckout,
  handleService,
} from '@services';
import TabelComponent from '@components/Table';
import { breadcrumbListDebtCustomer as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListDebtCustomer as columns } from '@components/Table/Columns';
import { ColumnsListDebtCustomerModal as columns2 } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import dayjs from 'dayjs';
import '../index.scss';

export const ListDebtCustomer = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(3);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [money, setMoney] = useState<any>(0);
  const [menthod, setMenthod] = useState('CASH');
  const [modalData, setModalData] = useState<any>();
  const [listServices, setListServices] = useState<any>([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [title, setTitle] = useState('Nợ cần thu từ khách');
  const [dataDetail, setDataDetail] = useState<any>();
  const titleButton = '';
  const routerAdd = '';
  const [dataCustomer, setDataCutomer] = useState<any>([]);
  const [totalPay, setTotalPay] = useState(0);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    // setIsModalOpen(false);
    console.log(data, 'data');
    try {
      const billsDetail = data.map((item) => {
        return {
          id: item.id,
          receivedAmount: item.price,
        };
      });
      const body = {
        receivedTotal: totalPay,
        paymentMethod: menthod,
        phoneNumber: dataDetail.phone,
        bills: billsDetail,
      };
      handleCheckout
        .payAllBill(body)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            notification.success({
              message: 'Thành công',
              description: 'Thành công thanh toán công nợ cho khách',
            });
            handleDetailCustomer();
            handleListBillCustomer({
              pageIndex: 1,
              pageSize: 100,
              statuses: 'PENDING_PAYMENT_WITH_DEBT,PENDING_PAYMENT,PENDING_OTP',
            });
            setIsModalOpen(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
      console.log(body, 'body');
    } catch (error) {
      console.log(error);
      setIsModalOpen(false);
      notification.error({
        message: 'Lỗi',
        description: 'Chưa thể thanh toán nợ cho khách',
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [data, setData] = useState<any>([]);
  const handleListServicesSQL = async () => {
    handleService
      .dropdownServiceMarketing()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListServices(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleListBillCustomer = async (urlParams) => {
    handleCheckout
      .listBillCutomer(id, urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const handleDetailCustomer = async () => {
    handleCustomer
      .GetDetailInforCutomers(id)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataDetail(data);
          const dataCutomerDetail = [
            {
              id: data.id,
              code: data.code,
              phone: data.phone,
              name: data.name,
              totalSpent: data.totalSpent,
              debt: data.debt,
            },
          ];
          setDataCutomer(dataCutomerDetail);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleListServicesSQL();
  }, []);
  useEffect(() => {
    if (location) {
      const urlParams = getParamsFromUrl(location);
      if (urlParams.pageIndex) {
        urlParams.pageIndex = 1;
      }
      if (urlParams.pageSize) {
        urlParams.pageSize = 100;
      }
      urlParams.statuses =
        'PENDING_PAYMENT_WITH_DEBT,PENDING_PAYMENT,PENDING_OTP';
      setTableLoading(true);
      handleListBillCustomer(urlParams);
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEditDebt = (e, record: any) => {
    console.log(record, 'record');
    const dataTest: any = [...data];

    // Tìm index của phần tử có id tương ứng trong dataTest
    const indexToUpdate = dataTest.findIndex((item) => item.id === record.id);

    // Nếu tìm thấy phần tử, thì cập nhật giá trị price
    if (indexToUpdate !== -1) {
      dataTest[indexToUpdate].price = e;
      setData(dataTest);
    } else {
      console.error('Không tìm thấy phần tử với id tương ứng trong dataTest');
    }
  };

  const handleChangeMoney = (e) => {
    setTotalPay(e);
    if (data.length === 0) {
      return; // Nếu không có phần tử nào, return
    }

    const dataTest = [...data];
    const dividedValue = Math.round(Number(e) / data.length);

    if (data.length === 1) {
      // Nếu chỉ có một phần tử, set giá trị cho phần tử đầu tiên
      dataTest[0].price = Number(e);
    } else {
      // Nếu có 2 phần tử trở lên
      for (let i = 0; i < data.length - 1; i++) {
        dataTest[i].price = dividedValue;
      }
      // Phần tử cuối cùng nhận giá trị thừa từ tổng
      dataTest[data.length - 1].price =
        Number(e) - (data.length - 1) * dividedValue;
    }

    setData(dataTest);
  };

  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} hoá đơn</span>
    );
  };
  useEffect(() => {
    if (id) {
      handleDetailCustomer();
    }
  }, [id]);

  const columnsx = [
    {
      title: 'Mã khách hàng',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tổng tiền chi tiêu',
      key: 'totalSpent',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.totalSpent &&
            record?.totalSpent?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Tổng nợ hiện tai',
      key: 'deblt',
      render: (_, record: any) => (
        <div>
          {record && record?.debt && record?.debt?.toLocaleString('vi-VN')}
        </div>
      ),
    },
  ];
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <Table
        columns={columnsx}
        dataSource={dataCustomer}
        loading={tableLoading}
        pagination={false}
      />
      <div className="flex justify-end mt-5">
        <Button type="primary" onClick={showModal}>
          Thanh toán công nợ
        </Button>
      </div>
      <TabelComponent
        columns={columns({ handleEditDebt, listServices })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
      <Modal
        title="THANH TOÁN CÔNG NỢ"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        destroyOnClose
        okText="Xác nhận thanh toán"
        cancelText="Huỷ bỏ"
      >
        <div className="flex items-center mt-5 ml-5">
          <div className="flex">
            <div className="w-[120px]">Tổng nợ hiện tại:</div>
            <div className="w-[200px] text-right">
              {dataDetail?.debt?.toLocaleString('vi-VN')} đ
            </div>
          </div>
          <div className="flex ml-[120px] items-center">
            <div className="w-[120px]">Thời gian:</div>
            <DatePicker
              className="w-[200px]"
              value={dayjs()}
              disabled
              format="DD/MM/YYYY"
            />
          </div>
        </div>
        <div className="flex items-center ml-5">
          <div className="flex items-center">
            <div className="w-[186px]">Tiền thu từ khách:</div>
            <InputNumber
              placeholder="Nhập số tiền thu từ khách"
              onChange={(e) => handleChangeMoney(e)}
              className="w-[190px]"
              min={0}
              value={totalPay}
              formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
            />
          </div>
          <div className="flex items-center mt-3 ml-16">
            <div className="mr-[35px]">Phương thức:</div>
            <Select
              style={{ width: 200 }}
              value={menthod}
              onChange={(e) => setMenthod(e)}
              options={[
                { label: 'Tiền mặt', value: 'CASH' },
                { label: 'Topup', value: 'TOP_UP' },
                { label: 'Chuyển khoản', value: 'QR' },
              ]}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 ml-5">
          <div className="flex">
            <div className="w-[120px]">Tổng nợ còn lại:</div>
            <div className="w-[200px] text-right">
              {(Number(dataDetail?.debt) - Number(money))?.toLocaleString(
                'vi-VN',
              )}
              đ
            </div>
          </div>
          <div className="flex ml-[120px] items-center">
            <div className="w-[120px]">Ghi chú:</div>
            <Input placeholder="Nhập ghi chú" className="w-[200px]" />
          </div>
        </div>
        <TabelComponent
          columns={columns2({ handleEditDebt, listServices })}
          data={data}
          tableLoading={tableLoading}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          totalItems={totalItems}
          customTotal={customTotal}
        />
      </Modal>
    </Spin>
  );
};
