export function getDaysInMonth(monthYear) {
  // Phân tách tháng và năm từ chuỗi 'YYYY-MM'
  const [year, month] = monthYear.split('-').map(Number);

  // Kiểm tra tính hợp lệ của tháng và năm
  if (isNaN(year) || isNaN(month) || month < 1 || month > 12) {
    throw new Error('Năm hoặc tháng không hợp lệ.');
  }

  // Tính số ngày trong tháng
  const daysInMonth = new Date(year, month, 0).getDate();

  const days = [];
  // Lặp qua từng ngày trong tháng
  for (let date = 1; date <= daysInMonth; date++) {
    // Tạo đối tượng Date từ ngày, tháng và năm
    const currentDate = new Date(year, month - 1, date);
    // Lấy tên ngày trong tuần (0 - Chủ nhật, 1 - Thứ Hai, ..., 6 - Thứ Bảy)
    const dayOfWeek = currentDate.getDay();
    // Mã hóa tên ngày trong tuần từ số sang chuỗi
    const dayOfWeekString = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'][
      dayOfWeek
    ];
    // Thêm vào mảng
    days.push({ title: dayOfWeekString, date: date });
  }

  return days;
}
