import CustomerEndpoint from '@api/customer';
import { notification } from 'antd';
export const GetListCutomers = async (param: any): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getListCutomers(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDetailInforCutomers = async (
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getDetailInforCutomers(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateInforCutomers = async (param: any): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.updateInforCutomers(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const getListBillCutomers = async (
  customerId: any,
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getListBillCutomers(
      customerId,
      param,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetListPromotionCutomers = async (
  customerId: any,
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getListPromotionCutomers(
      customerId,
      param,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetListTopupCutomers = async (
  customerId: any,
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getListTopupCutomers(
      customerId,
      param,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetListServicesCutomers = async (
  customerId: any,
  param: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getListServicesCutomers(
      customerId,
      param,
    );
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDetailCutomerByPhone = async (
  phone: any,
): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.getDetailCutomerByPhone(phone);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const CreateCustomer = async (body: any): Promise<any | Error> => {
  try {
    const response = await CustomerEndpoint.createCustomer(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
