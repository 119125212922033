import React, { useState, useEffect } from 'react';
import { Button, Input, Form, DatePicker, Select } from 'antd';
import {
  FilterOutlined,
  DownloadOutlined,
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {
  handleClinic,
  handleSalon,
  handleStaff,
  handleTimekeeping,
} from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import DynamicForm from './DynamicFilter';
import {
  saveDataToStorage,
  getDataFromStorage,
} from '@utils/Functions/dataStorage';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

export const TimeKeepingFilter = ({
  dataFilter,
  setDataFilter,
  keyName,
  tableLoading,
}) => {
  const [form] = Form.useForm();
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
  const mypageSize = queryParams.get('pageSize') || OPageSize;
  const [listSalon, setListSalon] = useState([]);

  const [initialValues, setInitialValues] = useState({});
  const handleGetListSalon = async () => {
    handleSalon
      .GetDropListSalon()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          const old_Data = [
            {
              value: 0,
              label: 'Kỹ thuật viên part time',
            },
          ];
          const new_Data = [...old_Data, ...data];
          setListSalon(new_Data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function queryStringToObject(queryString) {
    const urlSearchParams = new URLSearchParams(queryString);
    const paramsObject = {};

    urlSearchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    return paramsObject;
  }

  useEffect(() => {
    if (queryParams) {
      const paramsObject: any = queryStringToObject(location.search);
      console.log(paramsObject, 'paramsObject');
      setInitialValues({
        salonId: paramsObject.salonId
          ? Number(paramsObject.salonId)
          : undefined,
        date: paramsObject.date ? dayjs(paramsObject.date) : dayjs(),
      });
    }
  }, [location.search]);

  const onFinish = (value) => {
    console.log(value, 'value');
    const params: any = {
      date: dayjs(value.date).format('YYYY-MM-DD'),
    };
    if (value.salonId || value.salonId === 0) {
      params.salonId = value.salonId;
    }
    const queryString = new URLSearchParams(params).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);
  };
  const onFinishFailed = () => {
    console.log('False');
  };

  useEffect(() => {
    handleGetListSalon();
  }, []);

  return (
    <div className="pt-5 mt-5 ">
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        key={JSON.stringify(initialValues)}
      >
        <div className="items-start mb-5 lg:flex lg:justify-between">
          {Role !== 'SM' && Role !== 'SHIFT_LEADER' && Role !== 'KTV' && (
            <Form.Item
              label="Lựa chọn Salon"
              name="salonId"
              className="w-[360px] mr-5"
              // key={JSON.stringify(listSalon)}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn Salon',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Lựa chọn Salon"
                options={listSalon}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(
                    removeVietnamese(input),
                  )
                }
              ></Select>
            </Form.Item>
          )}

          <Form.Item
            label="Chọn ngày"
            name="date"
            className="w-[420px] mr-5"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày',
              },
            ]}
          >
            <DatePicker
              placeholder="Lựa chọn ngày"
              className="w-[280px]"
              format="DD/MM/YYYY"
            />
            {/* <div className="flex justify-between mt-2">
                <Button className="mb-[20px]">Hôm qua</Button>
                <Button className="mb-[20px]">Hôm nay</Button>
              </div> */}
          </Form.Item>
          <Button className="" type="primary" htmlType="submit">
            <SearchOutlined className="relative top-[-3px]" />
            Hiển thị nhân sự
          </Button>
        </div>
      </Form>
    </div>
  );
};
