import BookingEndpoint from '@api/booking';
import { notification } from 'antd';

export const GetListBooking = async (param: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getListBooking(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const UpdateStatusBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.updateStatusBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const UpdateBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.updateBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetListStatusBed = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getListStatusBed(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const SearchServicesByName = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.searchServicesByName(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const ListBedInTime = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.listBedInTime(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetListStaffReady = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getListStaffReady(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const UpdateStaffReadyStatus = async (
  staffId: any,
): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.updateStaffReadyStatus(staffId);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const CreateBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.createBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetDetailBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getDetailBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const UpdateBookingCheckin = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.updateBookingCheckin(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetListServicesSQL = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getListServicesSQL(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const CheckoutBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.checkoutBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetListVoucher = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.GetListVoucher(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const CheckPriceByVoucher = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.CheckPriceByVoucher(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const CheckVoucherByIds = async (body: any): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.CheckVoucherByIds(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const StatiscialBookingAdmin = async (
  params: any,
): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.StatiscialBookingAdmin(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const StatiscialBookingSM = async (
  params: any,
): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.StatiscialBookingSM(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const UpdateStatiscialBookingSM = async (
  body: any,
): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.UpdateStatiscialBookingSM(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetListStaffReadyParams = async (
  body: any,
): Promise<any | Error> => {
  try {
    const response = await BookingEndpoint.getListStaffReadyParams(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};
