export const CheckFromTimeToLength = (fromTime: string) => {
  if (!fromTime) return '0px';
  // console.log(fromTime, 'fromTime');
  switch (fromTime) {
    case '08:30:00':
      return '0px';
    case '09:30:00':
      return '130px';
    case '10:30:00':
      return '260px';
    case '11:30:00':
      return '390px';
    case '12:30:00':
      return '520px';
    case '13:30:00':
      return '650px';
    case '14:30:00':
      return '780px';
    case '15:30:00':
      return '910px';
    case '16:30:00':
      return '1040px';
    case '17:30:00':
      return '1170px';
    case '18:30:00':
      return '1300px';
    case '19:30:00':
      return '1430px';
    case '20:30:00':
      return '1560px';
  }
};

export const CheckColorForStatus = (status: string) => {
  if (!status) return 'linear-gradient(to right, #FFF, #FFF)';
  switch (status) {
    case 'BOOKED':
      return 'linear-gradient(to right, #1677FF90, #1677FF30)';
    case 'IN_SALON':
      return 'linear-gradient(to right, #e34f3c90, #e34f3c30)';
    case 'WAIT_FOR_KTV_CONFIRM':
      return 'linear-gradient(to right, #e6ab3c90, #e6ab3c30)';
    case 'IN_PROCESS':
      return 'linear-gradient(to right, #54ba3290, #54ba3230)';
    case 'DONE':
      return 'linear-gradient(to right, #7732d190, #7732d130)';
    case 'CANCEL':
      return 'linear-gradient(to right, #FFF, #FFF)';
  }
};

export const CheckStatusToTitle = (status: string) => {
  if (!status) return '';
  switch (status) {
    case 'BOOKED':
      return 'Chưa đến';
    case 'IN_SALON':
      return 'Đang chờ tư vấn';
    case 'WAIT_FOR_KTV_CONFIRM':
      return 'Chờ phục vụ';
    case 'IN_PROCESS':
      return 'Đang phục vụ';
    case 'DONE':
      return 'Đã phục vụ xong';
    case 'CANCEL':
      return 'Khách huỷ lịch';
  }
};
