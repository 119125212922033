import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification, Button, Input, InputNumber } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { handleSalary } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailSalaryConfig as breadcrumbItems } from '@components/Breadcrumb';
import { SalaryForm } from '@components/Form/Salary';
import '../index.scss';

export const ConfigSalaryDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({
    config: false,
  });

  const [title, setTitle] = useState('Chỉnh sừa cấu hình lương cơ bản');
  const titleButton = '';
  const routerAdd = '';
  const handleGetDetailConfigSalary = async (salaryId) => {
    handleSalary
      .DetailConfigSalary(salaryId)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setTitle(data.name);
          setInitialValues({
            position_id: data.position_id,
            name: data.name,
            base_salary: data.base_salary,
            total_hours: data.total_hours,
            config: false,
          });
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDetailConfigSalary(id);
    }
  }, [id]);
  const onFinish = async (values: any) => {
    const body = {
      id: id,
      name: values.name,
      department_id: 1,
      position_id: values.position_id,
      base_salary: values.base_salary,
      total_hours: values.total_hours,
    };
    console.log(body, 'body');
    handleSalary
      .UpdateConfigSalary(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công cập nhật cấu hình lương cơ bản mới',
          });
          // navigate('/salary/config?pageSize=10&pageIndex=1');
          handleGetDetailConfigSalary(id);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: 'Lỗi',
          description: 'Đã có lỗi xảy ra trong quá trình xử lý dữ liệu',
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="py-10 mt-5 bg-white">
        <SalaryForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
