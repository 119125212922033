import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleCategory } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddCategory as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../index.scss';

export const AddCategory = () => {
  const navigate = useNavigate();
  const initialValues = {
    active: true,
    activeBooking: true,
    config: false,
  };

  const [imageUrl, setImageUrl] = useState<string>('');

  const title = 'Thêm mới danh mục dịch vụ';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'category';

  const onFinish = (values: any) => {
    const body = {
      name: values.name,
      activeBooking: values.activeBooking,
      active: values.active,
      description: values.description,
      imageUrl: imageUrl,
    };
    console.log(body, 'body');
    handleCategory
      .CreateCategory(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã tạo thành công danh mục dịch vụ mới',
          });
          navigate('/service/category?pageSize=10&pageIndex=1');
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <CategoryForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">
              Ảnh Icon danh mục dịch vụ
            </h4>
            <UploadCustomV2
              setImageUrl={setImageUrl}
              urlImage={imageUrl}
              imageClass={ImageCalssEnum.CardPhoto}
              forlderName={forlderName}
            ></UploadCustomV2>
          </div>
        </div>
      </div>
    </>
  );
};
