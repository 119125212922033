import 'leaflet/dist/leaflet.css';
import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import L from 'leaflet';

const MapComponent = ({ form }) => {
  const [latitude, setLatitude] = useState(21.031885338435483);
  const [longitude, setLongitude] = useState(105.78201669411709);
  const formValues = form.getFieldsValue();
  console.log(formValues, 'formValues');
  useEffect(() => {
    if (formValues['latitude']) {
      setLatitude(formValues['latitude']);
    }
    if (formValues['longitude']) {
      setLongitude(formValues['longitude']);
    }
  }, [formValues]);
  const handleMapClick = (e: any) => {
    const { lat, lng } = e.latlng;
    setLatitude(lat);
    setLongitude(lng);
    const newInitiData = { ...formValues };
    newInitiData.latitude = lat;
    newInitiData.longitude = lng;
    form.setFieldsValue({ latitude: lat });
    form.setFieldsValue({ longitude: lng });
  };

  const MapEvents = () => {
    useMapEvents({
      click: handleMapClick,
    });
    return null;
  };

  // Đường dẫn đến hình ảnh PNG
  const imageUrl = '/icon/marker-icon.png';

  // Tạo một icon sử dụng hình ảnh từ đường dẫn URL
  const customIcon = new L.Icon({
    iconUrl: imageUrl,
    iconSize: [24, 38], // Kích thước của hình ảnh
    iconAnchor: [16, 32], // Vị trí neo điểm trên hình ảnh
    popupAnchor: [0, -32], // Vị trí hiển thị popup
  });

  // Sử dụng key để render lại MapContainer khi latitude hoặc longitude thay đổi
  const mapKey = `${latitude}-${longitude}`;

  return (
    <MapContainer
      key={mapKey}
      center={[latitude, longitude]}
      zoom={15}
      className="w-full h-[240px]"
    >
      <MapEvents />
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <Marker position={[latitude, longitude]} icon={customIcon}>
        {/* Bạn có thể thêm các thành phần khác cho Marker tại đây */}
      </Marker>
    </MapContainer>
  );
};

export default MapComponent;
