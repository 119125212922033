import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleService, handleCategory } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterSalon } from '@components/Filter/Salon';
import TabelComponent from '@components/Table';
import { breadcrumbListServices as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListServices as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { FilterConfig } from '@components/Filter';
import '../index.scss';

export const ListServices = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCategory, setListCategory] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const keyName = 'SERVICESCOLUMN';
  const title = 'Danh sách dịch vụ toàn hệ thống';
  const titleButton = 'Thêm mới dịch vụ';
  const routerAdd = '/service/list/add';
  const handleGetListCategory = async () => {
    handleCategory
      .GetDropListCategory()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListCategory(data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListServices = async () => {
    const urlParams = getParamsFromUrl(location);
    setTableLoading(true);
    handleService
      .GetListService(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          throw new Error('Invalid');
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListServices();
    }
  }, [location]);
  useEffect(() => {
    handleGetListCategory();
  }, []);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/service/list/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const hanldeChangeActive = async (body) => {
    handleService
      .UpdateActive(body)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi trạng thái dịch vụ thành công !',
          });
          handleGetListServices();
        } else {
          throw new Error('Invalid');
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const hanldeChangeBooking = async (body) => {
    handleService
      .UpdateBooking(body)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi trạng thái booking của dịch vụ thành công !',
          });
          handleGetListServices();
        } else {
          throw new Error('Invalid');
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  const handleDeleteStudent = (permissionId: string) => {
    handleService
      .DeleteServices(permissionId)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Xoá thành công dữ liệu dịch vụ',
          });
          handleGetListServices();
          hideModal();
        } else {
          throw new Error('Error delete Services');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listCategory) {
      setDataFilter([
        {
          value: '1',
          label: 'Mã dịch vụ',
          type: 'input',
          name: 'code',
          placeholder: 'Nhập mã dịch vụ',
        },
        {
          value: '2',
          label: 'Tên dịch vụ',
          type: 'input',
          name: 'name',
          placeholder: 'Nhập tên dịch vụ',
        },
        {
          value: '3',
          label: 'Giá nhỏ nhất',
          type: 'inputNumber',
          name: 'costFrom',
          placeholder: 'Nhập giá nhỏ nhất',
        },
        {
          value: '4',
          label: 'Giá cao nhấp',
          type: 'inputNumber',
          name: 'costTo',
          placeholder: 'Nhập giá cao nhất',
        },
        {
          value: '5',
          label: 'Thời gian',
          type: 'input',
          name: 'serviceTime',
          placeholder: 'Nhập thời gian phục vụ (phút)',
        },
        {
          value: '6',
          label: 'Trạng thái hoạt động',
          type: 'select',
          name: 'active',
          placeholder: 'Lựa chọn trạng thái hoạt động',
          list: [
            {
              value: true,
              label: 'Hoạt động',
            },
            {
              value: false,
              label: 'Không hoạt động',
            },
          ],
        },
        {
          value: '7',
          label: 'Trạng thái booking',
          type: 'select',
          name: 'activeBooking',
          placeholder: 'Lựa chọn trạng thái booking',
          list: [
            {
              value: true,
              label: 'Hiện booking',
            },
            {
              value: false,
              label: 'ẩn booking',
            },
          ],
        },
      ]);
    }
  }, [listCategory]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} dịch vụ</span>
    );
  };
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa dịch vụ"
          open={open}
          onOk={() => handleDeleteStudent(modalData.id)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          // okButtonProps={{
          //   disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          // }}
        >
          <p>
            Bạn đồng ý xóa dịch vụ <strong>{modalData.name}</strong> khỏi hệ
            thống ?
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({
          handleEdit,
          hanldeChangeActive,
          hanldeChangeBooking,
          handleDelete,
        })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
