import React, { useState } from 'react';
import { Button, Input, Form, Dropdown, Select } from 'antd';
import {
  FilterOutlined,
  DownloadOutlined,
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
} from '@ant-design/icons';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';

export const FilterSalon = ({}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
  const mypageSize = queryParams.get('pageSize') || OPageSize;
  const initialValues = {
    name: undefined,
    clinicCode: undefined,
  };
  const onFinish = (value) => {
    console.log(value, 'gia tri');

    // Filter out undefined values from the params object
    const params = {
      ...value,
      pageSize: mypageSize,
      pageIndex: mypageIndex,
    };

    const filteredParams: any = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined),
    );

    const queryString = new URLSearchParams(filteredParams).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);

    console.log(filteredParams, 'filteredParams');
  };
  const onFinishFailed = () => {
    console.log('False');
  };
  const onResetFilter = () => {
    form.resetFields();
    const params = {};
    const filteredParams: any = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined),
    );

    const queryString = new URLSearchParams(filteredParams).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);
  };

  const exportExcel = () => {
    form.resetFields();
    const params = {};
    const filteredParams: any = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined),
    );

    const queryString = new URLSearchParams(filteredParams).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);
  };
  const [listCity, setListCity] = useState([]);
  const CustomMenu = () => {
    return (
      <div className="w-[300px] h-[165px] bg-white shadow px-5 py-2">
        <div className="pt-2 pb-1 border-b-[1px] mx-2 mb-5">
          Thêm điều kiện lọc
        </div>
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn điều kiện lọc"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listCity}
        />
        <div className="flex justify-between mt-5">
          <Button type="primary">Thêm điều kiện</Button>
          <Button type="default">Huỷ bỏ</Button>
        </div>
      </div>
    );
  };
  return (
    <div className="px-10 pt-5 pb-3 mt-5 bg-white">
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="flex flex-wrap items-center">
          <Dropdown
            overlay={<CustomMenu />}
            trigger={['click']}
            className="mb-3 mr-5"
          >
            <Button
              type="primary"
              onClick={(e) => e.preventDefault()}
              icon={<FilterOutlined />}
            >
              Thêm điều kiện lọc
            </Button>
          </Dropdown>
          <Form.Item name="name" className="w-[300px] mr-5">
            <Input placeholder="Nhập tên phòng khám" />
          </Form.Item>
        </div>
        <Form.Item className="mt-3">
          <Button type="primary" htmlType="submit" className="mr-5 w-[177px]">
            <SearchOutlined className="relative top-[-3px]" />
            Xem dữ liệu
          </Button>
          <Button onClick={onResetFilter} className="mr-5">
            Reset Lọc
          </Button>
          <Button onClick={exportExcel} className="button-green">
            <FileExcelOutlined className="relative top-[-3px]" />
            Xuất file Excel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
