import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin, Select, Input } from 'antd';
import { handleClinic } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { SendVoucherFilter } from '@components/Filter/SendVoucher';
import TabelComponent from '@components/Table';
import { breadcrumbSendVoucher as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListSendVoucher as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import '../index.scss';
const { TextArea } = Input;

export const SendVoucher = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(20);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(true);
  const [text, setText] = useState('');
  const [select1Value, setSelect1Value] = useState();
  const [select2Value, setSelect2Value] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách khách hàng nhận ưu đãi';
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    if (location) {
      const urlParams = getParamsFromUrl(location);
      handleClinic
        .GetListClinics(urlParams)
        .then((response: any) => {
          const { statusCode, data, optional } = response;
          console.log(data, 'data');
          if (statusCode === 200) {
            setData(data);
            setCurrentPage(Number(optional?.pageIndex));
            setTotalItems(Number(optional?.getTotalElements));
            setTableLoading(false);
          } else {
            setTableLoading(false);
          }
        })
        .catch((error) => {
          setTableLoading(false);
          console.error(error);
        });
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/clinic/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleDeleteStudent = (permissionId: string) => {
    const body = {
      isActive: false,
      isDelete: true,
    };
    // handlePermission
    //   .UpdatePermission(permissionId, body)
    //   .then((response: any) => {
    //     const { statusCode, data } = response;
    //     if (statusCode === 201) {
    //       notification.success({
    //         message: 'Thành công',
    //         description: 'Xoá thành công dữ liệu phân quyền',
    //       });
    //       getDataTable();
    //       hideModal();
    //     } else {
    //       notification.error({
    //         message: 'Lỗi',
    //         description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const [listCity, setListCity] = useState([
    {
      value: '1',
      label: 'City',
    },
  ]);
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Số điện thoại Salon',
          type: 'input',
          name: 'phone',
          placeholder: 'Nhập số điện thoại Salon',
        },
        {
          value: '2',
          label: 'Địa chỉ Tỉnh/ Thành phố',
          type: 'select',
          name: 'city',
          placeholder: 'Lựa chọn Tỉnh/ Thành phố',
          list: listCity,
        },
        {
          value: '3',
          label: 'Trạng thái hoạt động',
          type: 'input',
          name: 'active',
          placeholder: 'Lựa chọn trạng thái của Salon',
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'SALONCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} Salon</span>
    );
  };
  const openModalSendVoucher = () => {
    setOpen(true);
  };
  const handleSendVoucher = () => {};
  const listTypeSend = [
    {
      value: 'app',
      label: 'Qua thông báo app',
    },
    {
      value: 'sms',
      label: 'Qua thông SMS',
    },
  ];
  return (
    <Spin spinning={loading}>
      <Modal
        open={open}
        onOk={() => handleSendVoucher()}
        onCancel={() => hideModal()}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
        // okButtonProps={{
        //   disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
        // }}
      >
        <p className="mt-5">
          Xác nhận gửi ưu đãi: <strong>Massage hoàng đế giảm mạnh 50%</strong>{' '}
          tới 220 khách hàng ?
          <Select
            showSearch
            className="w-full my-4"
            placeholder="Lựa chọn hình thức gửi"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              removeVietnamese(option?.label ?? '').includes(
                removeVietnamese(input),
              )
            }
            options={listTypeSend}
          />
          <TextArea placeholder="Nhập nội dung gửi ưu đãi" />
        </p>
      </Modal>

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <SendVoucherFilter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        openModalSendVoucher={openModalSendVoucher}
      />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
