import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CategoryType } from './type';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';

export const CategoryForm = ({ initialValues, onFinish, onFinishFailed }) => {
  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item<CategoryType>
        label="Tên danh mục dịch vụ"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên danh mục dịch vụ',
          },
        ]}
        className="mb-0"
      >
        <Input placeholder="Nhập tên danh mục dịch vụ" />
      </Form.Item>
      <Form.Item<CategoryType>
        label="Hiển thị trên booking"
        name="activeBooking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item<CategoryType>
        label="Trạng thái hoạt động"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item<CategoryType> label="Ghi chú" name="description">
        <TextArea placeholder="Nhập vào ghi chú cho danh mục dịch vụ" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
