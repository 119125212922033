import { instance } from '@configs/instance';

const getListService = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/service-manager/get-services`,
    {
      params: param,
    },
  );
};

const createService = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/service-manager/create-service`,
    body,
  );
};
const updateService = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/service-manager/update-service`,
    body,
  );
};
const updateBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/service-manager/update-active-booking`,
    body,
  );
};
const updateActive = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_ERP + `/service-manager/update-active`,
    body,
  );
};
const getDetailService = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/service-manager/get-services/${id}`,
  );
};
const generateCodeService = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/service-manager/generate-service-code`,
  );
};

const getListDropService = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/service-manager/drop-list-service`,
    {
      params: param,
    },
  );
};

const deleteService = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_ERP + `/service-manager/remove/${id}`,
  );
};

const dropdownServiceMarketing = (): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING + `/service/drop-down-service`,
  );
};
const deleteServices = (id: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_ERP + `/service-manager/remove/${id}`,
  );
};
export default {
  getListService,
  updateBooking,
  updateActive,
  updateService,
  createService,
  getDetailService,
  generateCodeService,
  getListDropService,
  deleteService,
  dropdownServiceMarketing,
  deleteServices,
};
