import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleVoucher } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbListVoucher as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListVoucher as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import '../index.scss';

export const ListVoucher = () => {
  const [loading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách Ưu đãi';
  const titleButton = 'Thêm mới ưu đãi';
  const routerAdd = '/voucher/list/add';

  const handleGetListVoucher = async () => {
    const urlParams = getParamsFromUrl(location);
    handleVoucher
      .GetListVoucher({ ...urlParams, showAllActiveBooking: true })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListVoucher();
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/voucher/list/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleDeleteVoucher = (voucherId: string) => {
    handleVoucher
      .DeleteVoucher(voucherId)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Xoá thành công ưu đãi này khỏi hệ thống',
          });
          handleGetListVoucher();
          hideModal();
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} ưu đãi</span>
    );
  };
  const [listCity, setListCity] = useState([
    {
      value: '1',
      label: 'City',
    },
  ]);
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Mã ưu đãi',
          type: 'input',
          name: 'code',
          placeholder: 'Nhập mã ưu đãi',
        },
        {
          value: '2',
          label: 'Tên ưu đãi',
          type: 'input',
          name: 'name',
          placeholder: 'Nhập tên ưu đãi',
        },
        {
          value: '8',
          label: 'Ngày bắt đầu',
          type: 'datepicker',
          name: 'startDate',
          placeholder: 'Nhập ngày bắt đầu ưu đãi',
        },
        {
          value: '4',
          label: 'Ngày kết thúc',
          type: 'datepicker',
          name: 'endDate',
          placeholder: 'Nhập ngày kết thúc ưu đãi',
        },
        {
          value: '5',
          label: 'Giảm giá nhỏ nhất',
          type: 'inputNumber',
          name: 'discountMaxFrom',
          placeholder: 'Nhập giảm giá nhỏ nhất',
        },
        {
          value: '6',
          label: 'Giảm giá lớn nhất',
          type: 'inputNumber',
          name: 'discountMaxTo',
          placeholder: 'Nhập giảm giá lớn nhất',
        },
        {
          value: '7',
          label: 'Hiện Booking',
          type: 'select',
          name: 'activeBooking',
          placeholder: 'Lựa chọn trạng thái booking',
          list: [
            {
              value: true,
              label: 'Hiện booking',
            },
            {
              value: false,
              label: 'ẩn booking',
            },
          ],
        },
      ]);
    }
  }, [listCity]);
  const hanldeChangeBooking = async (body) => {
    handleVoucher
      .UpdateBooking(body)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi trạng thái booking của ưu đãi thành công !',
          });
          handleGetListVoucher();
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  const keyName = 'VOUCHERCOLUMN';
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa phân quyền"
          open={open}
          onOk={() => handleDeleteVoucher(modalData.id)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          okButtonProps={{
            disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          }}
        >
          <p>
            Bạn đồng ý xóa ưu đãi <strong>{modalData.name}</strong> khỏi hệ
            thống ?
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete, hanldeChangeBooking })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
