import React, { useState, useEffect } from 'react';
import { DownCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Checkbox, Select, notification } from 'antd';

const VoucherCustomer = ({ dataVoucher }: any) => {
  const [collap, setCollap] = useState(true);
  return (
    <div className="w-full px-5 py-3 mt-5 bg-white">
      <div className="flex justify-between">
        <h3 className="font-semibold">ƯU ĐÃI ĐÃ ÁP DỤNG</h3>
        {collap ? (
          <DownCircleOutlined onClick={() => setCollap(false)} />
        ) : (
          <LeftCircleOutlined onClick={() => setCollap(true)} />
        )}
      </div>
      {collap && (
        <>
          {/* <div className="flex mt-3">
            <Input placeholder="Nhập mã giảm giá" className="rounded-none" />
            <Button type="primary" className="rounded-none">
              ÁP DỤNG
            </Button>
          </div>
          <div className="mt-2 mb-2">ƯU ĐÃI CHO KHÁCH</div> */}
          <div className="mt-3">
            {dataVoucher?.length > 0 ? (
              <>
                {dataVoucher.map((data, index) => (
                  <div
                    className="bg-[#1677ff25] rounded py-[5px] px-[5px] mb-1"
                    key={index}
                  >
                    <div className="flex items-start">
                      <Checkbox
                        className="w-[60px] mt-[5px]"
                        checked
                        disabled
                      />
                      <div>
                        <div>{data?.name}</div>
                        {data?.discountType === 1 ? (
                          <div>
                            Giảm giá: {data?.discountValue} %. Tối đa{' '}
                            {data?.discountMax?.toLocaleString('vi-VN')} đ
                          </div>
                        ) : (
                          <div>
                            Giảm giá:{' '}
                            {data?.discountValue?.toLocaleString('vi-VN')} đ
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                      <div className="text-green-600 text-[12px]">
                        Ưu đãi hợp lệ
                      </div>
                      {/* <div className="text-[#1677FF] italic text-[12px]">
                  Xem đk áp dụng
                </div> */}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>
            )}
            {/* <div className="bg-[#8888881b] rounded py-[5px] px-[5px] mt-2">
              <div className="flex items-start">
                <Checkbox className="w-[60px] mt-[5px]" disabled />
                <div className="text-[#888]">
                  (2766) All khách hàng đến salon - Bill 200k - Tặng khách áo
                  mưa hoặc mũ bảo hiểm [SC]
                </div>
              </div>
              <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                <div className="text-red-600 text-[12px]">
                  Ưu đãi không hợp lệ
                </div>
                <div className="text-[#1677FF] italic text-[12px]">
                  Xem đk áp dụng
                </div>
              </div>
            </div> */}
          </div>
        </>
      )}
    </div>
  );
};

export default VoucherCustomer;
