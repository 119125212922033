import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, DatePicker, Select, notification } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { handleAdress, handleCustomer } from '@services';
import dayjs from 'dayjs';
const { TextArea } = Input;

const CustomerInformation = ({ data, handleDetailCustomerByPhone }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [listDistric, setListDistric] = useState([]);
  const [listStress, setListStress] = useState([]);
  const [city, setCity] = useState();
  const [distric, setDistric] = useState();
  const [stress, setStress] = useState();
  const [name, setName] = useState<any>();
  const [addressMore, setAddressMore] = useState<any>();
  const [birthday, setBirthday] = useState<any>();
  const [note, setNote] = useState<any>();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const body = {
      id: data?.id,
      provinceCode: city,
      districtCode: distric,
      wardCode: stress,
      name: name,
      addressMore: addressMore,
      birthday: birthday,
      note: note,
    };
    handleCustomer
      .UpdateInforCutomers(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          setIsModalOpen(false);
          handleDetailCustomerByPhone(data?.phone);
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi thông tin khách hàng thành công',
          });
        } else {
          throw new Error('');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDistric = async (provinceCode) => {
    handleAdress
      .GetListDistricts(provinceCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListDistric(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetStress = async (districtCode) => {
    handleAdress
      .GetListWards(districtCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListStress(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  const handleChangeCity = (value) => {
    handleGetDistric(value);
    setCity(value);
    setDistric(null);
    setStress(null);
  };
  const handleChangeDistrict = (value) => {
    handleGetStress(value);
    setDistric(value);
    setStress(null);
  };
  useEffect(() => {
    if (data) {
      setName(data?.name);
      setBirthday(dayjs(data?.birthday));
      if (data?.provinceCode) {
        setCity(data?.provinceCode);
        handleGetDistric(data?.provinceCode);
      }
      if (data?.districtCode) {
        setDistric(data?.districtCode);
        handleGetStress(data?.districtCode);
      }

      setStress(data?.wardCode);
      setAddressMore(data?.addressMore);
      setNote(data?.note);
    }
  }, [data]);
  return (
    <>
      <Modal
        title="Sửa thông tin khách hàng"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <div className="flex items-center mt-5">
          <span className="w-[150px]">Tên khách hàng:</span>
          <Input
            placeholder="Nhập tên khách hàng"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex items-center mt-3">
          <span className="w-[150px]">Ngày sinh:</span>
          <DatePicker
            placeholder="Nhập ngày sinh"
            className="w-full"
            value={birthday}
            onChange={(e) => setBirthday(e)}
            format={'DD/MM/YYYY'}
          />
        </div>
        <div className="flex items-center w-full mt-3">
          <span className="w-[150px]">Địa chỉ:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn Tỉnh/ Thành phố"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={city}
              onChange={(e) => handleChangeCity(e)}
              options={listCity}
            />
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn quận/ huyện"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={distric}
              options={listDistric}
              onChange={(e) => handleChangeDistrict(e)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Địa chỉ chi tiết:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn xã/ phường"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={stress}
              onChange={(e) => setStress(e)}
              options={listStress}
            />
            <Input
              placeholder="Nhập số nhà"
              className="w-[48%]"
              value={addressMore}
              onChange={(e) => setAddressMore(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Ghi chú:</span>
          <div className="flex justify-between w-full">
            <TextArea
              className=""
              placeholder="Ghi chú khách hàng"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
      </Modal>
      <div className="flex items-start justify-between px-10 py-5 mt-5 mb-5 bg-white">
        <div className="">
          <h3 className="text-lg font-semibold">THÔNG TIN KHÁCH HÀNG</h3>
          <div className="flex flex-wrap w-full mt-3">
            <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">Tên khách hàng:</span>
              <span className="ml-2 font-semibold">{data.name}</span>
            </div>
            <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">SĐT:</span>
              <span className="ml-2 font-semibold">{data.phone}</span>
            </div>
            {/* <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">Ngày tham gia:</span>
              <span className="ml-2 font-semibold">30/04/2023</span>
            </div> */}
            <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">Ngày sinh:</span>
              <span className="ml-2 font-semibold">
                {data.birthday
                  ? dayjs(data.birthday).format('DD/MM/YYYY')
                  : null}
              </span>
            </div>
            <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">Địa chỉ:</span>
              <span className="ml-2 font-semibold">{data?.address}</span>
            </div>
            <div className="mb-2 ml-3">
              <span className="text-[#777] mr-10">Số dư Topup:</span>
              <span className="ml-2 font-semibold">
                {data?.topUpAmount?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="mb-2 ml-3">
              <span className="text-[#777] mr-10">Tổng chi tiêu:</span>
              <span className="ml-2 font-semibold">
                {data?.totalSpent?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="mb-2 ml-3 mr-10">
              <span className="text-[#777]">Chi tiêu trung bình:</span>
              <span className="ml-2 font-semibold">
                {data?.averageSpending?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="mb-2 mr-10">
              <span className="text-[#777]">Tổng nợ:</span>
              <span className="ml-2 font-semibold">
                {data?.debt?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="mb-2 mr-10">
              <span className="text-[#777]">Ghi chú:</span>
              <span className="ml-2 font-semibold">{data?.note}</span>
            </div>
          </div>
        </div>
        <Button type="primary" onClick={showModal}>
          <EditOutlined className="relative top-[-3px]" />
          Sửa thông tin
        </Button>
      </div>
    </>
  );
};

export default CustomerInformation;
