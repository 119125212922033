import React, { useState, useRef, useEffect } from 'react';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import { Button, notification, Image } from 'antd';
import { resizeImage } from '@utils/Functions/ResizeImage';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { handleUploadImage } from '@services/upload';
import { MAX_FILE_SIZE, ALLOWED_TYPE_PHOTO } from '@configs/image';
import './index.scss';

interface UploadCustomProps {
  setImageUrl: (url: string) => void;
  urlImage: string;
  imageClass: ImageCalssEnum;
  forlderName: string;
}

interface ImageSize {
  width: number;
  widthpx: string;
  height: number;
  heightpx: string;
  urlInitivalue: string;
}

const UploadMono: React.FC<UploadCustomProps> = ({
  setImageUrl,
  urlImage,
  imageClass,
  forlderName,
}) => {
  const [loading, setLoading] = useState(false);
  const inputUpload = useRef<HTMLInputElement>(null);
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    widthpx: '0px',
    height: 0,
    heightpx: '0px',
    urlInitivalue: '',
  });
  useEffect(() => {
    if (imageClass) {
      switch (imageClass) {
        case ImageCalssEnum.IDPhoto:
          setImageSize({
            width: 220,
            widthpx: '220px',
            height: 140,
            heightpx: '140px',
            urlInitivalue: '/icon/CardPhoto.png',
          });
          break;
        case ImageCalssEnum.CardPhoto:
          setImageSize({
            width: 210,
            widthpx: '210px',
            height: 280,
            heightpx: '280px',
            urlInitivalue: '/icon/IDPhoto.png',
          });
          break;
        case ImageCalssEnum.StudyRoute:
          setImageSize({
            width: 200,
            widthpx: '220px',
            height: 300,
            heightpx: '300px',
            urlInitivalue: '/icon/StudyRoute.png',
          });
          break;
        case ImageCalssEnum.Certificate:
          setImageSize({
            width: 240,
            widthpx: '240px',
            height: 160,
            heightpx: '160px',
            urlInitivalue: '/icon/Certificate.png',
          });
          break;
        case ImageCalssEnum.Avatar:
          setImageSize({
            width: 160,
            widthpx: '160px',
            height: 160,
            heightpx: '160px',
            urlInitivalue: '/icon/Avatar.png',
          });
          break;
        case ImageCalssEnum.Practice:
          setImageSize({
            width: 300,
            widthpx: '320px',
            height: 200,
            heightpx: '200px',
            urlInitivalue: '/icon/Practice.png',
          });
          break;
        case ImageCalssEnum.TuitionReceipt:
          setImageSize({
            width: 600,
            widthpx: '620px',
            height: 300,
            heightpx: '300px',
            urlInitivalue: '/icon/TuitionReceipt.png',
          });
          break;
        default:
          setImageSize({
            width: 200,
            widthpx: '220px',
            height: 300,
            heightpx: '300px',
            urlInitivalue: '/icon/Certificate.png',
          });
      }
    }
  }, [imageClass]);

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const file = event.target.files[0];
    console.log(file, 'file');
    if (file) {
      try {
        setLoading(true);
        if (file.size > MAX_FILE_SIZE) {
          return notification.error({
            message: 'Lỗi',
            description:
              'Kích thước tệp quá lớn. Vui lòng chọn tệp nhỏ hơn 3MB.',
          });
        }
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (!ALLOWED_TYPE_PHOTO.includes(fileExtension)) {
          return notification.error({
            message: 'Loại tệp không hợp lệ',
            description: 'Vui lòng chọn tệp ảnh (jpg, jpeg, png, webp)',
          });
        }
        const newFileName = `${Date.now()}.${fileExtension}`;
        const newFile = new File([file], newFileName, { type: file.type });
        // const resizedImage = await resizeImage(file, 1024, 1024);
        const { statusCode, message, data } = await handleUploadImage(
          newFile,
          forlderName,
        );
        if (statusCode === 200) {
          console.log(data, 'url');
          setImageUrl(data);
        }
      } catch (error) {
        console.error('Error resizing image:', error);
        setLoading(false);
      }
    } else {
      console.log('ko có ảnh');
      notification.error({
        description: 'Vui lòng chọn ảnh',
        message: 'Thông báo',
      });
    }
  };
  return (
    <div className="">
      {urlImage ? (
        <div
          className="relative cursor-pointer"
          style={{ width: imageSize.widthpx, height: imageSize.heightpx }}
        >
          <Image
            src={urlImage}
            alt="30shineAcademy"
            className="upload-image-show"
            width={imageSize.width}
            height={imageSize.height}
          />

          <Button
            className="upload-button upload-button-delete bg-[#FFF]"
            onClick={() => setImageUrl('')}
          >
            <DeleteFilled className="upload-button-icon relative top-[-1px]" />
          </Button>
          <Button
            className="upload-button upload-button-edit bg-[#FFF]"
            onClick={() => inputUpload.current.click()}
          >
            <EditFilled className="upload-button-icon relative top-[-1px]" />
          </Button>
          <input
            ref={inputUpload}
            className="custom-file-input"
            type="file"
            onChange={(e) => handleFileInputChange(e)}
            accept=".jpg,.jpeg,.png,.webp"
          />
        </div>
      ) : (
        <div
          className="relative cursor-pointer"
          style={{ width: imageSize.widthpx, height: imageSize.heightpx }}
        >
          <img
            className=""
            src={imageSize.urlInitivalue}
            alt="upload"
            onClick={() => inputUpload.current.click()}
            width={imageSize.width}
            height={imageSize.height}
          />
          <input
            ref={inputUpload}
            style={{ display: 'none' }}
            className="custom-file-input"
            type="file"
            onChange={(e) => handleFileInputChange(e)}
            accept=".jpg,.jpeg,.png,.webp"
          />
        </div>
      )}
    </div>
  );
};

export default UploadMono;
