import { Link } from 'react-router-dom';

export const breadcrumbListSalon = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Salon' },
];

export const breadcrumbAddSalon = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/salon/list'}>Danh sách salon</Link> },
  { title: 'Thêm mới salon' },
];

export const breadcrumbAddCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/category'}>Danh sách danh mục dịch vụ</Link> },
  { title: 'Thêm mới danh mục dịch vụ' },
];
export const breadcrumbAddTopupCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: <Link to={'/customer/list-topup'}>Danh sách topup khách hàng</Link>,
  },
  { title: 'Tạo topup cho khách' },
];
export const breadcrumbDetailTopupCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: <Link to={'/customer/list-topup'}>Danh sách topup khách hàng</Link>,
  },
  { title: 'Nạp topup cho khách' },
];
export const breadcrumbDetailCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/category'}>Danh sách danh mục dịch vụ</Link> },
  { title: 'Chỉnh sửa danh mục dịch vụ' },
];

export const breadcrumbDetailSalon = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/salon/list'}>Danh sách salon</Link> },
  { title: 'Chi tiết salon' },
];

export const breadcrumbListCategory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách danh mục dịch vụ' },
];
export const breadcrumbListInventory = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách vật tư tồn kho' },
];

export const breadcrumbListServices = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách dịch vụ' },
];
export const breadcrumbAddServices = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/list'}>Danh sách dịch vụ</Link> },
  { title: 'Thêm mới dịch vụ' },
];
export const breadcrumbDetailServices = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/service/list'}>Danh sách dịch vụ</Link> },
  { title: 'Chỉnh sửa dịch vụ' },
];

export const breadcrumbListVoucher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Ưu đãi' },
];
export const breadcrumbAddVoucher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/voucher/list'}>Danh sách ưu đãi</Link> },
  { title: 'Thêm mới ưu đãi' },
];
export const breadcrumbDetailVoucher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/voucher/list'}>Danh sách ưu đãi</Link> },
  { title: 'Chỉnh sửa ưu đãi' },
];

export const breadcrumbSendVoucher = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khách hàng nhận ưu đãi' },
];

export const breadcrumbListStaff = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách nhân viên' },
];

export const breadcrumbAddStaff = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/staff/list'}>Danh sách nhân viên</Link> },
  { title: 'Thêm mới nhân viên' },
];

export const breadcrumbDetailStaff = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/staff/list'}>Danh sách nhân viên</Link> },
  { title: 'Chỉnh sửa nhân viên' },
];

export const breadcrumbListChangeStaff = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách biến động nhân sự' },
];

export const breadcrumbListCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khách hàng' },
];
export const breadcrumbListTopupCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Topup của khách hàng' },
];

export const breadcrumbListDebtCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/customer/list-customer'}>Danh sách khách hàng</Link> },
  { title: 'Nợ cần thu từ khách' },
];

export const breadcrumbDetailCustomer = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/customer/list-customer'}>Danh sách khách hàng</Link> },
  { title: 'Chi tiết thông tin khách hàng' },
];

export const breadcrumbListBooking = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Booking' },
];
export const breadcrumbAddBooking = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/checkin/list-booking'}>Danh sách Booking</Link> },
  { title: 'Tạo mới Booking' },
];

export const breadcrumbAddCheckin = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: <Link to={'/checkin/list'}>Danh sách khách đến Salon</Link>,
  },
  { title: 'Tạo mới Booking & Tư vấn cho khách' },
];
export const breadcrumbCheckinTuVan = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: <Link to={'/checkin/list'}>Danh sách khách đến Salon</Link>,
  },
  { title: 'Tư vấn cho khách' },
];

export const breadcrumbListCheckin = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Checkin' },
];

export const breadcrumbListCheckinSalon = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khách đến Salon' },
];

export const breadcrumbListCheckinChoPhucVu = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khách đang chờ phục vụ' },
];

export const breadcrumbListCheckinDangPhucVu = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách khách đang phục vụ' },
];

export const breadcrumbListCheckout = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Hoá đơn' },
];
export const breadcrumbListBill = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách Hoá đơn' },
];

export const breadcrumbDetailBill = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/checkout/bill'}>Danh sách Hoá đơn</Link> },
  { title: 'Chi tiết hoá đơn' },
];

export const breadcrumbEditBill = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: <Link to={'/checkout/bill'}>Danh sách Hoá đơn</Link> },
  { title: 'Thanh toán hoá đơn cho khách hàng' },
];

export const breadcrumbAttendance = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Điểm danh' },
];

export const breadcrumbCreateTimekeeping = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Xếp lịch làm việc' },
];
export const breadcrumbTimekeepingAwaiting = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách lịch làm việc chờ duyệt' },
];
export const breadcrumbListTimeKeeping = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách nhân viên đã được Xếp lịch làm việc' },
];
export const breadcrumbStaticficalBill = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Thống kê hoá đơn Salon' },
];
export const breadcrumbStaticficalBooking = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Thống kê Booking' },
];
export const breadcrumbListSalary = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách lương nhân viên' },
];
export const breadcrumbConfigSalary = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách lương cơ bản từng vị trí' },
];
export const breadcrumbBonusSalary = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Cấu hình lương thưởng nhân viên' },
];
export const breadcrumbDetailSalaryConfig = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: (
      <Link to={'/salary/config'}>Danh sách lương cơ bản cho từng vị trí</Link>
    ),
  },
  { title: 'Chỉnh sửa cấu hình lương' },
];

export const breadcrumbAddSalaryConfig = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  {
    title: (
      <Link to={'/salary/config'}>Danh sách lương cơ bản cho từng vị trí</Link>
    ),
  },
  { title: 'Thêm cấu hình lương' },
];

export const breadcrumbListUsers = [
  { title: <Link to={'/'}>Trang chủ</Link> },
  { title: 'Danh sách người dùng' },
];

export const breadcrumbTuition = (studentInfor) => {
  return [
    { title: <Link to={'/'}>Trang chủ</Link> },
    { title: <Link to={'/list-class'}>Danh sách lớp học</Link> },
    {
      title: (
        <Link
          to={
            studentInfor
              ? `/list-class/detail?code=${studentInfor.classCode}`
              : `/list-class`
          }
        >
          Danh sách học viên trong lớp
        </Link>
      ),
    },
    { title: 'Nộp thêm học phí' },
  ];
};
