import StaffEndpoint from '@api/staff';
import { notification } from 'antd';

export const GetListStaff = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getListStaff(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const CreateStaff = async (body: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.createStaff(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response?.data);

    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateStaff = async (body: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.updateStaff(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    console.log(error?.response?.data);

    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const GetDetailStaff = async (id: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDetailStaff(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDropListStaff = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDropListStaff(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const getListStaffChange = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getListStaffChange(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const ChangeSalon = async (body: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.changeSalon(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDropList = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDropList(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDropListLevel = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDropListLevel(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDropListPosition = async (param: any): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDropListPosition(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDropListPositionFull = async (): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getDropListPositionFull();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetCurrent = async (): Promise<any | Error> => {
  try {
    const response = await StaffEndpoint.getCurrent();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
