export const removeNullAndUndefined = (arr: Array<any>): Array<any> => {
  return arr.filter((item: any) => item !== null && item !== undefined);
};

export const convertStringToList = (inputString: any) => {
  if (!inputString) return [];
  // Loại bỏ các ký tự không mong muốn từ chuỗi đầu vào
  const cleanedString = inputString
    .replace('[', '')
    .replace(']', '')
    .replace(/ /g, '');

  // Tách chuỗi thành danh sách bằng cách sử dụng dấu phẩy làm dấu phân cách
  const imageList = cleanedString.split(',');

  return imageList;
};

export const convertTypeBill = (type: string): string => {
  if (!type) return '';
  switch (type) {
    case 'PENDING_PAYMENT':
      return 'Đợi thanh toán';
    case 'PENDING_PAYMENT_WITH_DEBT':
      return 'Đợi thanh toán hết nợ';
    case 'PENDING_OTP':
      return 'Đợi xác thực OTP';
    case 'PENDING_PAYMENT':
      return 'Đợi thanh toán';
    case 'PAID':
      return 'Đã thanh toán đủ';
    case 'ERROR':
      return 'Lỗi';
  }
};
