import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleCustomer, handleAdress, handleCheckout } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbListTopupCustomer as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListTopupCustomer as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import '../index.scss';

export const ListTopupCustomer = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const title = 'Danh sách Topup của Khách hàng';
  const titleButton = 'Tạo topup cho khách';
  const routerAdd = '/customer/list-topup/add';

  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      const urlParams = getParamsFromUrl(location);
      setTableLoading(true);
      handleCheckout
        .listTopup(urlParams)
        .then((response: any) => {
          const { statusCode, data, optional } = response;
          if (statusCode === 200) {
            setData(data);
            setCurrentPage(Number(optional?.pageIndex));
            setTotalItems(Number(optional?.getTotalElements));
            setTableLoading(false);
          } else {
            setTableLoading(false);
          }
        })
        .catch((error) => {
          setTableLoading(false);
          console.error(error);
        });
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/customer/list-topup/${record.id}`);
  };

  const handleDebt = (record: any) => {
    console.log(record, 'record');
    navigate(`/customer/debt/${record.id}`);
  };

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Tên khách hàng',
          type: 'input',
          name: 'name',
          placeholder: 'Nhập tên khách hàng',
        },
        {
          value: '2',
          label: 'Số điện thoại',
          type: 'input',
          name: 'phone',
          placeholder: 'Nhập số điện thoại khác hàng',
        },
        {
          value: '5',
          label: 'Số dư Topup thấp nhất',
          type: 'inputNumber',
          name: 'topUpFrom',
          placeholder: 'Số dư Topup thấp nhất',
        },
        {
          value: '6',
          label: 'Số dư Topup lớn nhất',
          type: 'inputNumber',
          name: 'topUpTo',
          placeholder: 'Số dư Topup lớn nhất',
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'CUSTOMERTOPUPCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} Khách hàng
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />
      <TabelComponent
        columns={columns({ handleEdit, handleDebt })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
