import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleStaff } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddStaff as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { StaffForm } from '@components/Form/Staff';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import '../index.scss';
import dayjs from 'dayjs';

export const AddStaff = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    gender: 2,
    config: true,
    status_id: 1,
    onboarding_date: dayjs(),
    promotion_date: dayjs(),
    dob: null,
    date_of_issue: null,
  };
  const [avatar_url, setAvatarUrl] = useState<string>('');
  const [front_id_card_url, setFrontIdCardUrl] = useState<string>('');
  const [back_id_card_url, setBackIdCardUrl] = useState<string>('');

  const title = 'Thêm mới nhân viên';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'staff';

  const onFinish = async (values: any) => {
    const body = {
      username: values.username,
      avatar_url: avatar_url,
      front_id_card_url: front_id_card_url,
      back_id_card_url: back_id_card_url,
      full_name: values.full_name,
      id_card_no: values.id_card_no,
      date_of_issue: values.date_of_issue
        ? dayjs(values.date_of_issue).format('YYYY-MM-DD')
        : null,
      place_of_issue: values.place_of_issue,
      dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : null,
      gender: values.gender,
      province: values.province,
      district: values.district,
      ward: values.ward,
      address: values.address,
      phone: values.phone,
      email: values.email,
      social_insurance_no: values.social_insurance_no,
      tax_id_no: values.tax_id_no,
      contract_type_id: values.contract_type_id,
      work_description: values.work_description,
      promotion_date: values.promotion_date
        ? dayjs(values.promotion_date).format('YYYY-MM-DD')
        : null,
      onboarding_date: values.onboarding_date
        ? dayjs(values.onboarding_date).format('YYYY-MM-DD')
        : null,
      labour_source_id: values.labour_source_id,
      salon_id: values.salon_id,
      role_id: values.role_id,
      department_id: values.department_id,
      position_id: values.position_id,
      level_id: values.level_id,
      status_id: values.status_id,
      notes: values.notes,
      region_id: values.region_id,
      sub_region_id: values.sub_region_id,
    };
    console.log(body, 'body');
    setLoading(true);
    handleStaff
      .CreateStaff(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          setLoading(false);
          notification.success({
            message: 'Thành công',
            description: 'Bạn đã thành công tạo dữ liệu nhân viên mới',
          });
          navigate('/staff/list?pageSize=10&pageIndex=1');
        } else {
          throw new Error('Invalid create staff');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(JSON.stringify(error), 'oke');
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <StaffForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            loading={loading}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh Avatar</h4>
            <div className="pb-4 mt-4 ml-5">
              <UploadCustomV2
                setImageUrl={setAvatarUrl}
                urlImage={avatar_url}
                imageClass={ImageCalssEnum.Avatar}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh 2 mặt CCCD</h4>
            <div className="my-2 ml-5">
              <UploadCustomV2
                setImageUrl={setFrontIdCardUrl}
                urlImage={front_id_card_url}
                imageClass={ImageCalssEnum.Certificate}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
            <div className="pb-4 mt-5 ml-5">
              <UploadCustomV2
                setImageUrl={setBackIdCardUrl}
                urlImage={back_id_card_url}
                imageClass={ImageCalssEnum.Certificate}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
