import React, { useState, useEffect } from 'react';
import { DownCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Input, DatePicker, Select, notification } from 'antd';
import { Link } from 'react-router-dom';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { handleAdress, handleCustomer } from '@services';
import dayjs from 'dayjs';
const { TextArea } = Input;

const InformationCustomer = ({
  dataCutomer,
  phone,
  handleDetailCustomerByPhone,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [collap, setCollap] = useState(true);
  const [listCity, setListCity] = useState([]);
  const [listDistric, setListDistric] = useState([]);
  const [listStress, setListStress] = useState([]);
  const [city, setCity] = useState();
  const [distric, setDistric] = useState();
  const [stress, setStress] = useState();
  const [name, setName] = useState<any>();
  const [addressMore, setAddressMore] = useState<any>();
  const [birthday, setBirthday] = useState<any>();
  const [note, setNote] = useState<any>();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const body = {
      id: dataCutomer?.id,
      provinceCode: city,
      districtCode: distric,
      wardCode: stress,
      name: name,
      addressMore: addressMore,
      birthday: birthday,
      note: note,
    };
    handleCustomer
      .UpdateInforCutomers(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          setIsModalOpen(false);
          handleDetailCustomerByPhone(dataCutomer?.phone);
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi thông tin khách hàng thành công',
          });
        } else {
          throw new Error('');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDistric = async (provinceCode) => {
    handleAdress
      .GetListDistricts(provinceCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListDistric(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetStress = async (districtCode) => {
    handleAdress
      .GetListWards(districtCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListStress(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  const handleChangeCity = (value) => {
    setCity(value);
    handleGetDistric(value);
    setDistric(null);
    setStress(null);
  };
  const handleChangeDistrict = (value) => {
    setDistric(value);
    setStress(null);
    handleGetStress(value);
  };
  useEffect(() => {
    if (dataCutomer) {
      setName(dataCutomer?.name);
      if (dayjs(dataCutomer?.birthday)) {
        setBirthday(dayjs(dataCutomer?.birthday));
      }

      if (dataCutomer?.provinceCode) {
        setCity(dataCutomer?.provinceCode);
        handleGetDistric(dataCutomer?.provinceCode);
      }
      if (dataCutomer?.districtCode) {
        setDistric(dataCutomer?.districtCode);
        handleGetStress(dataCutomer?.districtCode);
      }

      setStress(dataCutomer?.wardCode);
      setAddressMore(dataCutomer?.addressMore);
      setNote(dataCutomer?.note);
    }
  }, [dataCutomer]);

  return (
    <>
      <Modal
        title="Sửa thông tin khách hàng"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={700}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <div className="flex items-center mt-5">
          <span className="w-[150px]">Tên khách hàng:</span>
          <Input
            placeholder="Nhập tên khách hàng"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex items-center mt-3">
          <span className="w-[150px]">Ngày sinh:</span>
          <DatePicker
            placeholder="Nhập ngày sinh"
            className="w-full"
            value={birthday}
            onChange={(e) => setBirthday(e)}
            format={'DD/MM/YYYY'}
          />
        </div>
        <div className="flex items-center w-full mt-3">
          <span className="w-[150px]">Địa chỉ:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn Tỉnh/ Thành phố"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={city}
              onChange={(e) => handleChangeCity(e)}
              options={listCity}
            />
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn quận/ huyện"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={distric}
              options={listDistric}
              onChange={(e) => handleChangeDistrict(e)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Địa chỉ chi tiết:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn xã/ phường"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={stress}
              onChange={(e) => setStress(e)}
              options={listStress}
            />
            <Input
              placeholder="Nhập số nhà"
              className="w-[48%]"
              value={addressMore}
              onChange={(e) => setAddressMore(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Ghi chú:</span>
          <div className="flex justify-between w-full">
            <TextArea
              className=""
              placeholder="Ghi chú khách hàng"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
      </Modal>
      <div className="w-full px-5 py-3 bg-white">
        <div className="flex justify-between">
          <h3 className="font-semibold">THÔNG TIN KHÁCH HÀNG</h3>
          {collap ? (
            <DownCircleOutlined onClick={() => setCollap(false)} />
          ) : (
            <LeftCircleOutlined onClick={() => setCollap(true)} />
          )}
        </div>
        {collap && (
          <>
            {dataCutomer ? (
              <>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">Họ và tên:</div>
                  <div>{dataCutomer?.name}</div>
                </div>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">SĐT:</div>
                  <div>{dataCutomer?.phone}</div>
                </div>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">Tổng chi tiêu:</div>
                  <div>
                    {dataCutomer?.totalSpent?.toLocaleString('vi-VN')} đ
                  </div>
                </div>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">Số dư Topup:</div>
                  <div>
                    {dataCutomer?.topUpAmount?.toLocaleString('vi-VN')} đ
                  </div>
                </div>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">Tổng nợ:</div>
                  <div>{dataCutomer?.debt?.toLocaleString('vi-VN')} đ</div>
                </div>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">Ghi chú:</div>
                  <div>{dataCutomer?.note}</div>
                </div>
              </>
            ) : (
              <>
                <div className="flex mt-3 border-b-[1px] pb-[5px]">
                  <div className="w-[110px] text-[#777]">SĐT:</div>
                  <div>{phone}</div>
                </div>
              </>
            )}

            <Button type="primary" className="w-full mt-3" onClick={showModal}>
              Sửa thông tin khách hàng
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default InformationCustomer;
