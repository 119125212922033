import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleCustomer, handleBooking, handleCheckout } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbListCheckout as breadcrumbItems } from '@components/Breadcrumb';
import BillComponent from './components/Bill';
import InformationCustomer from './components/Information';
import VoucherCustomer from './components/Voucher';
import dayjs from 'dayjs';
import '../index.scss';

export const ViewBillCheckout = () => {
  const navigate = useNavigate();
  const pageSize = 5;
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [initialValues, setInitialValues] = useState<any>();
  const { id } = useParams();
  const [services, setServices] = useState<any>([]);
  const [listBed, setListBed] = useState([]);
  const [bedId, setBedId] = useState<any>();
  const [listStringServices, setListStringServices] = useState('');
  const [listServices, setListServices] = useState([]);
  const [salonId, setSalonId] = useState<any>(null);
  const [listTimeBed, setListTimeBed] = useState([]);
  const [serviceTime, setServiceTime] = useState();
  const [serviceTimeS, setServiceTimeS] = useState<any>();
  const [totalMoney, setTotalMoney] = useState();
  const [dataDetail, setDatadetail] = useState<any>();
  const [phone, setPhone] = useState<any>(null);
  const [dataVoucher, setDataVoucher] = useState<any>([]);
  const title = 'Xem thông tin hoá đơn của khách hàng';
  const titleButton = '';
  const routerAdd = '';
  const [listServicesIdBooking, setListServicesIdBooking] = useState<any>([]);
  const handleGetListservicesDefault = async () => {
    handleBooking
      .GetListServicesSQL(listStringServices)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listStringServices) {
      handleGetListservicesDefault();
    }
  }, [listStringServices]);
  const convertTimeFormat = (fromTime) => {
    return fromTime.slice(0, 5);
  };
  const [dataCutomer, setDataCustomer] = useState<any>();
  const handleDetailCustomer = async (phone) => {
    handleCustomer
      .GetDetailCutomerByPhone(phone)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          if (data.phone) {
            setDataCustomer(data);
          }
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDetailBill = async () => {
    handleCheckout
      .detailBill(id)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDatadetail(data);
          setPhone(data.phoneNumber);
          setSalonId(data.salonId);
          setListServicesIdBooking(data.services);
          if (data.services && data.services.length > 0) {
            const listId = data.services.map((service) => {
              return service.id;
            });
            setListStringServices(listId.join(','));
          }
          if (data?.promotions) {
            setDataVoucher(data?.promotions);
          }
          setServices(data.services);
          const newbed = {
            ID: data.bedId,
            NAME: data.bedName,
          };
          setBedId(newbed);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDetailBill();
    }
  }, [id]);
  useEffect(() => {
    if (dataDetail && dataDetail?.phoneNumber) {
      handleDetailCustomer(dataDetail?.phoneNumber);
    }
  }, [dataDetail]);
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="flex justify-between mt-5">
        <div className="w-[330px]">
          <InformationCustomer
            dataCutomer={dataCutomer}
            phone={dataDetail?.phoneNumber}
            handleDetailCustomerByPhone={handleDetailCustomer}
          />
          <VoucherCustomer dataVoucher={dataVoucher} />
        </div>
        <BillComponent
          dataDetail={dataDetail}
          listStringServices={listStringServices}
          view={true}
          dataCutomer={dataCutomer}
        />
      </div>
    </>
  );
};
