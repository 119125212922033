import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleSalary, handleStaff } from '@services';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbConfigSalary as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListConfigSalary as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';

export const ConfigSalary = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách lương lương cơ bản từng vị trí';
  const titleButton = 'Thêm cấu hình lương';
  const routerAdd = '/salary/config/add';

  const handleGetListSalary = async () => {
    const urlParams = getParamsFromUrl(location);
    handleSalary
      .ListConfigSalary({ ...urlParams, userId: UserInfor?.accountId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      UserInfor &&
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListSalary();
    }
  }, [location, UserInfor]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/salary/config/${record.id}`);
  };
  const handleDeleteStudent = (permissionId: string) => {
    const body = {
      isActive: false,
      isDelete: true,
    };
    // handlePermission
    //   .UpdatePermission(permissionId, body)
    //   .then((response: any) => {
    //     const { statusCode, data } = response;
    //     if (statusCode === 201) {
    //       notification.success({
    //         message: 'Thành công',
    //         description: 'Xoá thành công dữ liệu phân quyền',
    //       });
    //       getDataTable();
    //       hideModal();
    //     } else {
    //       notification.error({
    //         message: 'Lỗi',
    //         description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  const [listCity, setListCity] = useState([
    {
      value: '1',
      label: 'City',
    },
  ]);
  const [dataFilter, setDataFilter] = useState([]);
  const [positionId, setPositionId] = useState([]);
  const GetDropListPosition = async () => {
    handleStaff
      .GetDropListPositionFull()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setPositionId(data);
        } else {
          throw new Error('ERROR');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    GetDropListPosition();
  }, []);
  useEffect(() => {
    if (listCity && positionId) {
      setDataFilter([
        {
          value: '2',
          label: 'Vị trí',
          type: 'select',
          name: 'positionId',
          placeholder: 'Lựa chọn vị trí',
          list: positionId,
        },
        {
          value: '3',
          label: 'Thời gian làm việc',
          type: 'inputNumber',
          name: 'hours',
          placeholder: 'Thời gian làm việc trong tháng (giờ)',
        },
      ]);
    }
  }, [listCity, positionId]);
  const keyName = 'SALARYCONFIGCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} cấu hình lương
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa phân quyền"
          open={open}
          onOk={() => handleDeleteStudent(modalData._id)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          okButtonProps={{
            disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          }}
        >
          <p>
            {['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name) ? (
              <span style={{ color: 'red' }}>
                Thuộc nhóm quyền cố định của hệ thống! Không thể xoá
              </span>
            ) : (
              <>
                Bạn đồng ý xóa phân quyền <strong>{modalData.name}</strong> khỏi
                hệ thống ?
              </>
            )}
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({ handleEdit })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
