import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Radio,
  notification,
  Switch,
  InputNumber,
} from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { FieldType } from './type';
import {
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateAdress,
  validateFullName,
} from '@utils/Functions/validate';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
const { Option } = Select;
import { handleAdress, handleStaff, handleSalon } from '@services';
import 'dayjs/locale/en';
import dayjs from 'dayjs';

export const StaffForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  loading,
}) => {
  const [form] = Form.useForm();
  const [listCity, setListCity] = useState([]);
  const [listDistric, setListDistric] = useState([]);
  const [listStress, setListStress] = useState([]);
  const [listRegionRSM, setListRegionRSM] = useState([]);
  const [listRegionASM, setListRegionASM] = useState([]);
  const [listContract, setListContract] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [listWorkPosition, setListWorkPosition] = useState([]);
  const [listWorkPositionORG, setListWorkPositionORG] = useState([]);
  const [workPosition, setWorkPosition] = useState();
  const [listLaborSource, setListLaborSource] = useState([]);
  const [listRole, setListRole] = useState([]);
  const [listLevel, setListLevel] = useState([]);
  const [listSalon, setListSalon] = useState([]);
  const [salonId, setSalonId] = useState([]);
  const [city, setCity] = useState();
  const [distric, setDistric] = useState();
  const [rsmId, setRSMId] = useState();
  const [asmId, setASMId] = useState();
  const listGender = [
    { value: 1, label: 'Nam' },
    { value: 2, label: 'Nữ' },
    { value: 3, label: 'Khác' },
  ];
  const [group, setGroup] = useState();

  const GetDropListPosition = async (departmentId: any) => {
    handleStaff
      .GetDropListPosition(departmentId)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListWorkPositionORG(data);
          if (
            initialValues.region_id &&
            initialValues.sub_region_id &&
            initialValues.salon_id
          ) {
            const updatePosition = data.filter(
              (item) =>
                item.value !== 1 && item.value !== 2 && item.value !== 3,
            );
            setListWorkPosition(updatePosition);
          } else if (
            initialValues.region_id &&
            initialValues.sub_region_id &&
            !initialValues.salon_id
          ) {
            const updatePosition = data.filter(
              (item) => item.value === 2 || item.value === 3,
            );
            setListWorkPosition(updatePosition);
          } else if (
            initialValues.region_id &&
            !initialValues.sub_region_id &&
            !initialValues.salon_id
          ) {
            const updatePosition = data.filter((item) => item.value === 1);
            setListWorkPosition(updatePosition);
          }
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListContract = async () => {
    handleStaff
      .GetDropList({ name: 'CONTRACT_TYPE' })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListContract(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListDepament = async () => {
    handleStaff
      .GetDropList({ name: 'DEPARTMENT' })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListDepartment(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListLaborSource = async () => {
    handleStaff
      .GetDropList({ name: 'LABOR_SOURCE' })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListLaborSource(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListRole = async () => {
    handleStaff
      .GetDropList({ name: 'ROLE' })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListRole(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListLevel = async (positionId: any) => {
    handleStaff
      .GetDropListLevel(positionId)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListLevel(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListRegionRSM = async () => {
    handleAdress
      .GetListRegionRSM()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListRegionRSM(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListRegionASM = async (value) => {
    handleAdress
      .GetListRegionASM(value)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListRegionASM(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDistric = async (provinceCode) => {
    handleAdress
      .GetListDistricts(provinceCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListDistric(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetStress = async (districtCode) => {
    handleAdress
      .GetListWards(districtCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListStress(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListSalonRegion = async (value) => {
    handleStaff
      .GetDropList({ name: 'SALON', regionId: value })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListSalon(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
    handleGetListRegionRSM();
    handleGetListContract();
    handleGetListDepament();
    handleGetListLaborSource();
    handleGetListRole();
  }, []);

  useEffect(() => {
    if (initialValues.province) {
      handleGetDistric(initialValues.province);
      setCity(initialValues.province);
    }
    if (initialValues.district) {
      handleGetStress(initialValues.district);
      setDistric(initialValues.district);
    }
    if (initialValues.region_id) {
      setRSMId(initialValues.region_id);
      handleGetListRegionASM(initialValues.region_id);
    }
    if (initialValues.sub_region_id) {
      setASMId(initialValues.sub_region_id);
      handleGetListSalonRegion(initialValues.sub_region_id);
    }
    if (initialValues.department_id) {
      setGroup(initialValues.department_id);
      GetDropListPosition(initialValues.department_id);
    }
    if (initialValues.position_id) {
      handleGetListLevel(initialValues.position_id);
    }
  }, [initialValues]);

  const handleChangeCity = (value) => {
    setCity(value);
    handleGetDistric(value);
    form.setFieldsValue({ ward: null });
    form.setFieldsValue({ district: null });
  };
  const handleChangeRegion = (value) => {
    setRSMId(value);
    form.setFieldsValue({ sub_region_id: null });
    form.setFieldsValue({ salon_id: null });
    form.setFieldsValue({ position_id: null });
    form.setFieldsValue({ level_id: null });
    if (!value) return;
    const updatePosition = listWorkPositionORG.filter(
      (item) => item.value === 1,
    );
    setListWorkPosition(updatePosition);
    handleGetListRegionASM(value);
  };
  const handleChangeRegionASM = (value) => {
    setASMId(value);
    form.setFieldsValue({ salon_id: null });
    form.setFieldsValue({ position_id: null });
    form.setFieldsValue({ level_id: null });
    if (!value) return;
    const updatePosition = listWorkPositionORG.filter(
      (item) => item.value === 2 || item.value === 3,
    );
    setListWorkPosition(updatePosition);
    handleGetListSalonRegion(value);
  };
  const handleClearASM = () => {
    // đưa danh sách vị trí về dạng trưởng miền
    const dataList = [...listWorkPositionORG];
    const updatePosition = dataList.filter((item) => item.value === 1);
    setListWorkPosition(updatePosition);
  };
  const handleChangeDistrict = (value) => {
    form.setFieldsValue({ ward: null });
    setDistric(value);
    handleGetStress(value);
  };
  const handleChangeSalon = (value) => {
    setSalonId(value);
    form.setFieldsValue({ position_id: null });
    form.setFieldsValue({ level_id: null });
    const updatePosition = listWorkPositionORG.filter(
      (item) => item.value !== 1 && item.value !== 2 && item.value !== 3,
    );
    if (!value) return;
    setListWorkPosition(updatePosition);
  };
  const handleClearSalon = () => {
    // đưa danh sách vị trí về dạng trưởng vùng + nhân viên không cố định
    const dataList = [...listWorkPositionORG];
    const updatePosition = dataList.filter(
      (item) => item.value === 2 || item.value === 3,
    );
    setListWorkPosition(updatePosition);
  };
  const handleChangeDepament = (value) => {
    setGroup(value);
    if (!value) return;
    GetDropListPosition(value);
  };
  const handleChangeposition = (value) => {
    setWorkPosition(value);
    form.setFieldsValue({ level_id: null });
    if (!value) return;
    handleGetListLevel(value);
  };
  return (
    <Form
      // key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      form={form}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <div className="mb-3 ml-8 text-lg font-bold">THÔNG TIN CÁ NHÂN</div>
      <Form.Item
        label="Tên đăng nhập"
        name="username"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tên đăng nhập',
          },
        ]}
      >
        <Input
          placeholder="Nhập tên đăng nhập"
          disabled={!initialValues.config}
        />
      </Form.Item>
      <Form.Item
        label="Họ và tên"
        name="full_name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập họ và tên',
          },
        ]}
      >
        <Input placeholder="Nhập họ và tên" />
      </Form.Item>
      <Form.Item
        label="Số CCCD"
        name="id_card_no"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập CCCD',
          },
        ]}
      >
        <Input placeholder="Nhập số CCCD" className="w-full" maxLength={12} />
      </Form.Item>
      <Form.Item
        label="Ngày cấp/ Nơi cấp"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between">
          <Form.Item
            name="date_of_issue"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày cấp CCCD',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Chọn ngày cấp"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            name="place_of_issue"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn nơi cấp CCCD',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn nơi cấp CCCD"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listCity}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Ngày sinh/ Giới tính"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between">
          <Form.Item
            name="dob"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập ngày sinh',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Chọn ngày sinh"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn giới tính',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn giới tính"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listGender}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Địa chỉ"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item
            name="province"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Tỉnh/ Thành Phố',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn Tỉnh/ Thành phố"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeCity(e)}
              options={listCity}
            />
          </Form.Item>
          <Form.Item
            name="district"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập quận/ huyện',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn quận/ huyện"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listDistric}
              onChange={(e) => handleChangeDistrict(e)}
              disabled={!city}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Địa chỉ cụ thể"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item
            name="ward"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập phường/ xã',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn xã/ phường"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listStress}
              disabled={!distric}
            />
          </Form.Item>
          <Form.Item
            name="address"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập địa chỉ cụ thể',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên đường, số nhà" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item
        label="Số điện thoại/ Email"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item
            name="phone"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập SĐT',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item
            name="email"
            className="w-[48%] ml-[4%] mg-bttom-0"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập email',
              },
            ]}
          >
            <Input placeholder="Nhập địa chỉ email" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item label="Số BHXH/ Thuế">
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item name="social_insurance_no" className="w-[48%] mg-bttom-0">
            <Input placeholder="Nhập số BHXH" />
          </Form.Item>
          <Form.Item name="tax_id_no" className="w-[48%] ml-[4%] mg-bttom-0">
            <Input placeholder="Nhập mã số thuế" />
          </Form.Item>
        </div>
      </Form.Item>
      <div className="mt-3 mb-3 ml-8 text-lg font-bold">THÔNG TIN HỒ SƠ</div>

      <Form.Item
        label="Hồ sơ lý lịch"
        name="contract_type_id"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <Select
          allowClear={true}
          showSearch
          className="w-full"
          placeholder="Lựa chọn loại hợp đồng"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listContract}
        />
      </Form.Item>
      <Form.Item label="Mô tả công việc" name="work_description">
        <TextArea placeholder="Nhập mô tả công việc" />
      </Form.Item>
      <div className="mt-3 mb-3 ml-8 text-lg font-bold">THÔNG TIN LÀM VIỆC</div>
      <Form.Item
        label="Văn phòng/ Khối Salon"
        name="department_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn Khối/ Salon',
          },
        ]}
      >
        <Select
          allowClear={true}
          showSearch
          className="w-full"
          placeholder="Lựa chọn Khối/ salon"
          optionFilterProp="children"
          value={group ? group : undefined}
          onChange={(value) => handleChangeDepament(value)}
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listDepartment}
        />
      </Form.Item>
      {group === 2 && (
        <Form.Item
          label="Vị trí"
          name="position_id"
          rules={[
            {
              required: true,
              message: 'Vui lòng chọn vị trí làm việc',
            },
          ]}
        >
          <Select
            allowClear={true}
            showSearch
            className="w-full"
            placeholder="Lựa chọn vị trí"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              removeVietnamese(option?.label ?? '').includes(
                removeVietnamese(input),
              )
            }
            value={workPosition ? workPosition : undefined}
            onChange={(value) => setWorkPosition(value)}
            options={listWorkPositionORG}
          />
        </Form.Item>
      )}
      {group === 1 && (
        <>
          <Form.Item
            label="Miền/ Vùng"
            name="config"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
            className="mt-4"
          >
            <div className="flex items-center justify-between w-full h-full mb-0">
              <Form.Item
                name="region_id"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn miền',
                  },
                ]}
                className="w-[48%] mg-bttom-0"
              >
                <Select
                  allowClear={true}
                  showSearch
                  className="w-full"
                  placeholder="Lựa chọn miền"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  onChange={(e) => handleChangeRegion(e)}
                  options={listRegionRSM}
                />
              </Form.Item>
              <Form.Item name="sub_region_id" className="w-[48%] mg-bttom-0">
                <Select
                  allowClear={true}
                  onClear={handleClearASM}
                  showSearch
                  className="w-full"
                  placeholder="Lựa chọn vùng"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  onChange={(e) => handleChangeRegionASM(e)}
                  options={listRegionASM}
                  disabled={!rsmId}
                />
              </Form.Item>
            </div>
          </Form.Item>
          <Form.Item label="Lựa chọn Salon" name="salon_id">
            <Select
              allowClear={true}
              onClear={handleClearSalon}
              showSearch
              className="w-full"
              placeholder="Lựa chọn Salon"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeSalon(e)}
              options={listSalon}
              disabled={!rsmId || !asmId}
            />
          </Form.Item>
          <Form.Item
            label="Vị trí"
            name="position_id"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn vị trí làm việc',
              },
            ]}
          >
            <Select
              allowClear={true}
              showSearch
              className="w-full"
              placeholder="Lựa chọn vị trí"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={workPosition ? workPosition : undefined}
              onChange={(value) => handleChangeposition(value)}
              options={listWorkPosition}
              disabled={!rsmId}
            />
          </Form.Item>
          <Form.Item
            label="Bậc kỹ năng/ Ngày lên bậc"
            name="config"
            rules={[
              {
                required: true,
                message: '',
              },
            ]}
          >
            <div className="flex items-center justify-between">
              <Form.Item
                name="level_id"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn bậc kỹ năng',
                  },
                ]}
                className="w-[48%] mg-bttom-0"
              >
                <Select
                  allowClear={true}
                  showSearch
                  className="w-full"
                  placeholder="Lựa chọn bậc kỹ năng"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    removeVietnamese(option?.label ?? '').includes(
                      removeVietnamese(input),
                    )
                  }
                  options={listLevel}
                />
              </Form.Item>
              <Form.Item
                name="promotion_date"
                rules={[
                  {
                    required: true,
                    message: 'Vui lòng chọn ngày lên bậc',
                  },
                ]}
                className="w-[48%] mg-bttom-0"
              >
                <DatePicker
                  format="DD/MM/YYYY"
                  placeholder="Chọn ngày lên bậc"
                  className="w-full"
                />
              </Form.Item>
            </div>
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Ngày vào làm việc"
        name="onboarding_date"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn ngày bắt đầu làm việc',
          },
        ]}
      >
        <DatePicker
          format="DD/MM/YYYY"
          placeholder="Chọn ngày bắt đầu làm việc"
          className="w-full"
        />
      </Form.Item>
      <Form.Item label="Nguồn lao động" name="labour_source_id">
        <Select
          allowClear={true}
          showSearch
          className="w-full"
          placeholder="Lựa chọn nguồn tuyển dụng"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listLaborSource}
        />
      </Form.Item>
      <Form.Item
        label="Trạng thái làm việc"
        name="status_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={1}>Hoạt động</Radio>
          <Radio value={2}>Nghỉ phép</Radio>
          <Radio value={3}>Thôi việc</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item label="Ghi chú" name="notes">
        <TextArea placeholder="Nhập vào ghi chú cho thông tin làm việc của nhân viên" />
      </Form.Item>
      <div className="mt-3 mb-3 ml-8 text-lg font-bold">PHÂN QUYỀN ERP</div>
      <Form.Item
        label="Phân quyền"
        name="role_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn phân quyền cho tài khoản',
          },
        ]}
      >
        <Select
          allowClear={true}
          showSearch
          className="w-full"
          placeholder="Lựa chọn phân quyền ERP"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listRole}
        />
      </Form.Item>
      <div className="flex justify-end">
        <Button
          type={loading ? 'default' : 'primary'}
          className="button-submit"
          htmlType="submit"
          disabled={loading}
        >
          {loading && <LoadingOutlined className="relative top-[-2px] mr-2" />}
          {initialValues.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
