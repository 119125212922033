import TimekeepingEndpoint from '@api/timekeeping';
import { notification } from 'antd';
export const GenerateQRCode = async (): Promise<any | Error> => {
  try {
    const response = await TimekeepingEndpoint.generateQRCode();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const ListStaffAvaiable = async (params: any): Promise<any | Error> => {
  try {
    const response = await TimekeepingEndpoint.listStaffAvaiable(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const ListTimeKeeping = async (params: any): Promise<any | Error> => {
  try {
    const response = await TimekeepingEndpoint.listTimeKeeping(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const RemoveTimeKeeping = async (
  params: any,
  shift: any,
): Promise<any | Error> => {
  try {
    const response = await TimekeepingEndpoint.removeTimeKeeping(params, shift);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const CreateTimeKeeping = async (params: any): Promise<any | Error> => {
  try {
    const response = await TimekeepingEndpoint.createTimeKeeping(params);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
