import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleCheckout, handleAdress } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { CheckinFilterConfig } from '@components/Filter/CheckinFilterNormal';
import TabelComponent from '@components/Table';
import { breadcrumbListBill as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListBill as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';

export const ListBillCheckout = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([
    { value: 'PENDING_PAYMENT', label: 'Đợi thanh toán' },
    { value: 'PENDING_PAYMENT_WITH_DEBT', label: 'Đợi thanh toán hết nợ' },
    { value: 'PENDING_OTP', label: 'Đợi xác thực OTP' },
    { value: 'PAID', label: 'Đã thanh toán đủ' },
    { value: 'ERROR', label: 'Lỗi' },
  ]);
  const [dataFilter, setDataFilter] = useState([]);
  const title = 'Danh sách Hoá đơn';
  const titleButton = '';
  const routerAdd = '';

  useEffect(() => {
    if (
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      const urlParams = getParamsFromUrl(location);
      if (Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV') {
        urlParams.salonId = UserInfor?.salonIds[0];
        console.log('đã push');
      }
      setTableLoading(true);
      handleCheckout
        .listBill(urlParams)
        .then((response: any) => {
          const { statusCode, data, optional } = response;
          if (statusCode === 200) {
            setData(data);
            setCurrentPage(Number(optional?.pageIndex));
            setTotalItems(Number(optional?.getTotalElements));
            setTableLoading(false);
          } else {
            setTableLoading(false);
          }
        })
        .catch((error) => {
          setTableLoading(false);
          console.error(error);
        });
    }
  }, [location]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/checkout/bill/edit/${record.id}`);
  };

  const handleView = (record: any) => {
    console.log(record, 'record');
    navigate(`/checkout/bill/view/${record.id}`);
  };

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Số điện thoại khách hàng',
          type: 'input',
          name: 'phone',
          placeholder: 'Số điện thoại khách hàng',
        },
        {
          value: '2',
          label: 'Ngày tạo bill',
          type: 'datepicker',
          name: 'date',
          placeholder: 'Ngày tạo bill',
        },
        {
          value: '3',
          label: 'Tổng Bill thấp nhất',
          type: 'inputNumber',
          name: 'totalBillFrom',
          placeholder: 'Tổng Bill thấp nhất',
        },
        {
          value: '4',
          label: 'Tổng Bill cao nhất',
          type: 'inputNumber',
          name: 'totalBillTo',
          placeholder: 'Tổng Bill cao nhất',
        },
        {
          value: '5',
          label: 'Tổng thanh toán thấp nhất',
          type: 'inputNumber',
          name: 'paidFrom',
          placeholder: 'Tổng thanh toán thấp nhất',
        },
        {
          value: '6',
          label: 'Tổng thanh toán cao nhất',
          type: 'inputNumber',
          name: 'paidTo',
          placeholder: 'Tổng thanh toán cao nhất',
        },
        {
          value: '7',
          label: 'Trạng thái thanh toán',
          type: 'select',
          name: 'status',
          placeholder: 'Lựa chọn trạng thái thanh toán',
          list: listCity,
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'BILLCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} hoá đơn</span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <CheckinFilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />
      <TabelComponent
        columns={columns({ handleEdit, handleView })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
