import dayjs from 'dayjs';
export const formatDate = (inputDate: string): string => {
  if (!inputDate) return inputDate;
  // Tách chuỗi nhập vào dựa trên dấu "-"
  const parts = inputDate.split('-');

  // Lấy các phần của ngày, tháng và năm
  const year = parts[0];
  const month = parts[1];
  const day = parts[2];

  // Kết hợp lại theo định dạng mới 'DD/MM/YYYY'
  return `${day}/${month}/${year}`;
};

export const generateDateWeekArray = (inputString) => {
  if (!inputString) return null;
  const [year, weekString] = inputString.split('-');
  const weekNumber = parseInt(weekString.replace('th', '')); // Lấy số của tuần bỏ qua "th"

  // Tính toán ngày bắt đầu của tuần thứ n
  const startDate = dayjs()
    .year(year)
    .startOf('year')
    .add((weekNumber - 1) * 7, 'day');

  // Lấy mảng chứa 7 ngày (một tuần) từ ngày bắt đầu của tuần thứ n
  const weekArray = Array.from({ length: 7 }, (_, index) =>
    startDate.add(index, 'day').format('DD/MM/YYYY'),
  );

  return weekArray;
};

export const generateDateMonthArray = (inputString) => {
  if (!inputString) return null;
  const [year, monthString] = inputString.split('-');
  const month = parseInt(monthString) - 1; // Trừ 1 vì tháng trong JavaScript bắt đầu từ 0

  const firstDay = dayjs(`${year}-${month + 1}-01`);
  const daysInMonth = firstDay.daysInMonth();

  const dateArray = Array.from({ length: daysInMonth }, (_, index) =>
    firstDay.add(index, 'day').format('DD/MM/YYYY'),
  );

  return dateArray;
};

export function compareTimes(inputTime: string) {
  if (!inputTime) return false; // Trả về false nếu inputTime không tồn tại

  const currentDateTime = new Date();
  currentDateTime.setHours(currentDateTime.getHours() - 1); // Giảm 1 giờ từ thời gian hiện tại
  const currentHour = currentDateTime.getHours();
  const currentMinute = currentDateTime.getMinutes();

  const [inputHour, inputMinute] = inputTime.split(':');
  const inputTimeInMinutes = parseInt(inputHour) * 60 + parseInt(inputMinute);
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  return currentTimeInMinutes >= inputTimeInMinutes;
}

export const convertTimeFormat = (inputTime) => {
  // Chuyển đổi chuỗi thời gian thành đối tượng Date
  const time = new Date(`2000-01-01 ${inputTime}`);

  // Lấy giờ và phút từ đối tượng Date
  const hours = time.getHours();
  const minutes = time.getMinutes();

  // Định dạng lại thời gian dưới dạng "hh:mm"
  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${
    minutes < 10 ? '0' : ''
  }${minutes}`;

  return formattedTime;
};

export const convertTimeFormatV2 = (inputTime) => {
  if (!inputTime) return null;
  switch (inputTime) {
    case '08:30:00':
      return '08:30';
    case '09:30:00':
      return '09:30';
    case '10:30:00':
      return '10:30';
    case '11:30:00':
      return '11:30';
    case '12:30:00':
      return '12:30';
    case '13:30:00':
      return '13:30';
    case '14:30:00':
      return '14:30';
    case '15:30:00':
      return '15:30';
    case '16:30:00':
      return '16:30';
    case '17:30:00':
      return '17:30';
    case '18:30:00':
      return '18:30';
    case '19:30:00':
      return '19:30';
    case '20:30:00':
      return '20:30';
  }
};
