import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleCheckout } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbAddTopupCustomer as breadcrumbItems } from '@components/Breadcrumb';
import { TopupForm } from '@components/Form/Topup';
import '../index.scss';

export const AddTopupCustomer = () => {
  const navigate = useNavigate();
  const initialValues = {
    active: true,
    activeBooking: true,
    config: true,
  };

  const title = 'Tạo mới Topup cho khách';
  const titleButton = '';
  const routerAdd = '';

  const onFinish = (values: any) => {
    const body = {
      phoneNumber: values.phoneNumber,
      amount: values.amount,
    };
    console.log(body, 'body');
    handleCheckout
      .createTopup(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Tạo thành công topup mới cho khách hàng',
          });
          navigate('/customer/list-topup?pageSize=10&pageIndex=1');
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />

      <div className="py-10 mt-5 bg-white">
        <TopupForm
          initialValues={initialValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      </div>
    </>
  );
};
