import { instance } from '@configs/instance';

const getListBooking = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/get-booking`,
    {
      params: param,
    },
  );
};

const updateStatusBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/update-booking-status`,
    body,
  );
};

const updateBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/check-in-booking`,
    body,
  );
};

const listBedInTime = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/get-beds-status`,
    body,
  );
};

const getListStatusBed = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/get-quantity-beds`,
    {
      params: param,
    },
  );
};

const searchServicesByName = (name: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING +
      `/web-booking/filter-services-name?name=${name}`,
  );
};

const getListStaffReady = (salonId: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF + `/staffs/ready?salonId=${salonId}`,
  );
};
const getListStaffReadyParams = (params: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/staffs/ready`, {
    params: params,
  });
};
const updateStaffReadyStatus = (staffId: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF + `/staffs/toggle-ready/${staffId}`,
  );
};
const createBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/create-booking`,
    body,
  );
};
const getDetailBooking = (bookingId: string): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/get-booking/${bookingId}`,
  );
};

const updateBookingCheckin = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/check-in-booking`,
    body,
  );
};

const getListServicesSQL = (serviceIds: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_MARKETING +
      `/web-booking/list-service-sql?serviceIds=${serviceIds}`,
  );
};
const checkoutBooking = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/check-out-booking`,
    body,
  );
};

const GetListVoucher = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/promotions-by-services`,
    { params: params },
  );
};

const CheckPriceByVoucher = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/get-discount-by-promotion`,
    body,
  );
};

const CheckVoucherByIds = (promotionIds: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING +
      `/booking/uu-dai-vo-van?promotionIds=${promotionIds}`,
  );
};

const StatiscialBookingAdmin = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/over-view-slot-by-salon`,
    { params: params },
  );
};

const StatiscialBookingSM = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/booking/bed-schedule`,
    { params: params },
  );
};

const UpdateStatiscialBookingSM = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/booking/update-booking-trash`,
    body,
  );
};

export default {
  getListBooking,
  updateBooking,
  updateStatusBooking,
  getListStatusBed,
  searchServicesByName,
  listBedInTime,
  getListStaffReady,
  updateStaffReadyStatus,
  createBooking,
  getDetailBooking,
  updateBookingCheckin,
  getListServicesSQL,
  checkoutBooking,
  GetListVoucher,
  CheckPriceByVoucher,
  CheckVoucherByIds,
  StatiscialBookingAdmin,
  StatiscialBookingSM,
  UpdateStatiscialBookingSM,
  getListStaffReadyParams,
};
