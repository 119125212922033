import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleTimekeeping } from '@services';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterSalon } from '@components/Filter/Salon';
import TabelComponent from '@components/Table';
import { breadcrumbListTimeKeeping as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListTimeKeeping as columns } from '@components/Table/Columns';
import { getParamsFromUrlConfigUnLimited } from '@utils/Functions/getParams';
import { TimeKeepingFilter } from '@components/Filter/TimeKeeping';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import '../index.scss';

export const ListTimeKeeping = () => {
  const [loading] = useState(false);
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const date = queryParams.get('date');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [dataShift, setDataShift] = useState([]);
  const [dataShiftA, setDataShiftA] = useState([]);
  const [dataShiftB, setDataShiftB] = useState([]);
  const [dataShiftC, setDataShiftC] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const salonId = queryParams.get('salonId');
  const title = 'Danh sách nhân viên đã được xếp lịch làm việc';
  const titleButton = 'Xếp lịch làm việc';
  const routerAdd = '/timekeeping/create';
  const keyName = 'TIMEKEEPINGCOLUMNS';
  const handleGetListTimeKeeping = async () => {
    setTableLoading(true);
    const urlParams = getParamsFromUrlConfigUnLimited(location);
    if (Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV') {
      urlParams.salonId = UserInfor?.salonIds[0];
    }
    if (!urlParams?.date) {
      urlParams.date = dayjs().format('YYYY-MM-DD');
    }
    handleTimekeeping
      .ListTimeKeeping({ ...urlParams, userId: UserInfor?.accountId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setDataShift(data);
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      UserInfor &&
      (salonId ||
        salonId === 0 ||
        Role === 'SM' ||
        Role === 'SHIFT_LEADER' ||
        Role === 'KTV') &&
      UserInfor?.accountId
    ) {
      handleGetListTimeKeeping();
    }
  }, [salonId, UserInfor, date]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
  };

  const handleDelete = (record: any, shift: any) => {
    console.log(record, 'record');
    showModal({ ...record, shift: shift });
  };

  const handleDeleteTimeKeeping = (timekeepingId: string, shift: any) => {
    handleTimekeeping
      .RemoveTimeKeeping(timekeepingId, shift)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Xoá thành công dữ liệu lịch làm việc của nhân viên',
          });
          hideModal();
          handleGetListTimeKeeping();
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [dataFilter, setDataFilter] = useState([]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} nhân viên
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Xóa lịch làm"
          open={open}
          onOk={() => handleDeleteTimeKeeping(modalData?.id, modalData?.shift)}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          destroyOnClose
          // okButtonProps={{
          //   disabled: ['ADMIN', 'TEACHER', 'WEBSITE'].includes(modalData.name),
          // }}
        >
          <p>
            Xác nhận xóa lịch làm ca làm việc này của nhân viên{' '}
            <strong>
              {modalData.code} - {modalData.full_name}
            </strong>{' '}
            ?
          </p>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <TimeKeepingFilter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
      />

      <TabelComponent
        columns={columns({ handleDelete })}
        data={dataShift}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={null}
        customTotal={customTotal}
      />
    </Spin>
  );
};
