import React, { useState, useEffect } from 'react';
import { ColumnsListVoucherCustomer as columns } from '@components/Table/Columns';
import TabelComponent from '@components/Table';
import { useLocation, useNavigate } from 'react-router-dom';

const ListVoucher = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };
  const customTotal = () => {
    return (
      <span className="pagination-table-title">Tổng {totalItems} ưu đãi</span>
    );
  };
  return (
    <div>
      <TabelComponent
        columns={columns()}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </div>
  );
};

export default ListVoucher;
