import { instance } from '@configs/instance';

const generateQRCode = (): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/qr-codes`, {
    headers: {
      responseType: 'blob',
    },
  });
};

const listStaffAvaiable = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF + `/work-schedules/available-staffs`,
    {
      params: params,
    },
  );
};

const listTimeKeeping = (params: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/work-schedules`, {
    params: params,
  });
};

const removeTimeKeeping = (id: any, shift: any): Promise<any> => {
  return instance.delete(
    process.env.REACT_APP_API_STAFF + `/work-schedules/${id}?shift=${shift}`,
  );
};

const createTimeKeeping = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_STAFF + `/work-schedules`,
    body,
  );
};

export default {
  generateQRCode,
  listStaffAvaiable,
  listTimeKeeping,
  removeTimeKeeping,
  createTimeKeeping,
};
