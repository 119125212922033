import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleVoucher } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailVoucher as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { VoucherForm } from '@components/Form/Voucher';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import dayjs from 'dayjs';
import '../index.scss';

export const DetailVoucher = () => {
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>();
  const { id } = useParams();
  const [condition, setCondition] = useState([]);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [title, setTitle] = useState<string>('Chi tiết ưu đãi');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'voucher';
  const [category, setCategory] = useState([]);
  const onFinish = (values: any) => {
    if (values?.endDate?.isBefore(values.startDate)) {
      return notification.error({
        message: 'Lỗi',
        description: 'Ngày kết thúc ưu đãi không thể trước ngày tạo ưu đãi!',
      });
    }
    const body = {
      id: id,
      name: values.name,
      code: values.code,
      categoryId: values.categoryId,
      spaServiceId: values.spaServiceId,
      discountType: values.discountType,
      discountValue: values.discountValue,
      description: values.description,
      active: values.active,
      activeBooking: values.activeBooking,
      imageUrl: imageUrl,
      startDate: dayjs(values.startDate).format('YYYY-MM-DD'),
      endDate: dayjs(values.endDate).format('YYYY-MM-DD'),
      conditionFreshers: condition,
      giftItems: values.giftItems,
      giftServiceIds: values.giftServiceIds,
      discountMax:
        values.discountType === 1 ? values.discountMax : values.discountValue,
    };
    console.log(body, 'body');
    handleVoucher
      .UpdateVoucher(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Thành công cập nhật thông tin ưu đãi',
          });
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    if (id) {
      handleVoucher
        .GetDetailVoucher(id)
        .then((response: any) => {
          const { statusCode, data } = response;
          if (statusCode === 200) {
            setInitialValues({
              name: data.name,
              code: data.code,
              categoryId: data.categoryId,
              spaServiceId: data.spaServiceId,
              discountType: data.discountType,
              discountValue: data.discountValue,
              description: data.description,
              active: data.active,
              activeBooking: data.activeBooking,
              imageUrl: imageUrl,
              startDate: dayjs(data.startDate),
              endDate: dayjs(data.endDate),
              promotionMapApplyConditions: [],
              giftItems: data.giftItems,
              giftServiceIds: data.giftServiceIds,
              discountMax: data.discountMax,
              config: true,
            });
            setCategory(data.categoryId);
            setTitle(data.name);
            setImageUrl(data.imageUrl);
            setCondition(data.conditionFreshers);
          } else {
            notification.error({
              message: 'Lỗi',
              description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [id]);
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          <VoucherForm
            initialValues={initialValues}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            condition={condition}
            setCondition={setCondition}
            category={category}
            setCategory={setCategory}
          />
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh Ưu đãi (một ảnh)</h4>
            <UploadCustomV2
              setImageUrl={setImageUrl}
              urlImage={imageUrl}
              imageClass={ImageCalssEnum.CardPhoto}
              forlderName={forlderName}
            ></UploadCustomV2>
          </div>
        </div>
      </div>
    </>
  );
};
