import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Select,
  Input,
  Modal,
  Checkbox,
  Pagination,
  InputNumber,
  notification,
  Image,
} from 'antd';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import {
  handleStaff,
  handleBooking,
  handleCheckout,
  handleSalon,
  handleCustomer,
} from '@services';
import { convertStringToList } from '@utils/Functions/configArray';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import OtpInput from 'react-otp-input';
const { TextArea } = Input;

const BillComponent = ({
  dataDetail,
  listStringServices,
  view,
  dataCutomer,
}) => {
  console.log(dataDetail, 'dataDetail');
  const navigate = useNavigate();
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [paymoney, setPaymoney] = useState<any>(0);
  console.log(paymoney, 'paymoney');
  const [search, setSearch] = useState<any>('');
  const [services, setServices] = useState<any>([]);
  const [listServices, setListServices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [listStaff, setListStaff] = useState([]);
  const [staffCheckinId, setStaffCheckinId] = useState();
  const [staffKTVId, setStaffKTVId] = useState();
  const [staffTNId, setStaffTNId] = useState();
  const pageSize = 2;
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const [error, setError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [isModalServices, setIsModalServices] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [QRPayment, setQRPayment] = useState([]);
  const [totalMoney, setTotalMoney] = useState<any>(0);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageErrorUrl, setImageErrorUrl] = useState<string>('');
  const [otp, setOtp] = useState('');
  const [errorMessage, setErrorMessage] = useState<any>();
  const [type, setType] = useState<any>();
  const forlderName = '/bill';

  const handleGetListservicesDefault = async () => {
    handleBooking
      .GetListServicesSQL(listStringServices)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (dataDetail && dataDetail?.salonId && UserInfor?.accountId) {
      handleGetListStaff();
      setStaffCheckinId(dataDetail.staffCheckInId);
      setStaffKTVId(dataDetail.staffServiceId);
      setStaffTNId(UserInfor?.accountId);
    }
    if (dataDetail?.salonId) {
      handleGetDetailSalon();
    }

    if (dataDetail?.paid) {
      setPaymoney(dataDetail?.paid);
    }
    if (dataDetail?.pttt) {
      setType(dataDetail?.pttt);
    }
    if (dataDetail?.status === 'ERROR') {
      setError(true);
    }
    if (dataDetail?.errorImageUrl) {
      setImageErrorUrl(dataDetail?.errorImageUrl);
    }
    if (dataDetail?.error) {
      setErrorMessage(dataDetail?.error);
    }
    if (dataDetail?.imageUrl) {
      setImageUrl(dataDetail?.imageUrl);
    }
    if (dataDetail?.totalBill) {
      setPaymoney(dataDetail?.totalBill);
    }
  }, [dataDetail, UserInfor?.accountId]);
  const handleGetListStaff = async () => {
    const params = {
      pageIndex: 1,
      pageSize: 100,
      salonId: dataDetail.salonId,
    };
    handleStaff
      .GetListStaff(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          if (data) {
            const newDataFilter = data.map((dataItem) => {
              return {
                label: dataItem.full_name,
                value: Number(dataItem.id),
              };
            });
            // bổ sung thêm admin hệ thống
            const newDatas = [
              ...newDataFilter,
              {
                label: 'ADMIN hệ thống',
                value: 1,
              },
            ];
            setListStaff(newDatas);
          }
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDetailSalon = async () => {
    handleSalon
      .GetDetailSalon(dataDetail?.salonId)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200 && data?.qrImagesUrl) {
          setQRPayment(data?.qrImagesUrl);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listStringServices) {
      handleGetListservicesDefault();
    }
  }, [listStringServices]);
  const columnsService = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return <span>{i + 1}</span>;
      },
    },
    {
      title: 'Mã DV',
      dataIndex: 'code',
      key: 'code',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Tên dịch vụ',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Đơn giá',
      dataIndex: 'cost',
      render: (text) => <span>{text?.toLocaleString('vi-VN')}</span>,
      width: 120,
    },
  ];
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk1 = async () => {
    setIsModalOpen1(false);
    try {
      if (otp) {
        // xác nhận OTP nợ
        const body = {
          otp: otp,
          phoneNumber: dataDetail?.phoneNumber,
          billId: dataDetail?.id,
        };
        handleCheckout
          .verifyOTPDebt(body)
          .then((response: any) => {
            const { data, statusCode } = response;
            if (statusCode === 200) {
              setIsModalOpen1(false);
              notification.success({
                message: 'Thành công',
                description: 'Ghi nợ cho hoá đơn khách hàng thanh công',
              });
              navigate(`/checkout/bill`);
            } else {
              throw new Error('Error');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } catch (error) {
      console.log(error);
      notification.success({
        message: 'Lỗi',
        description: 'Mã OTP không đúng',
      });
    }
  };

  const handleCancel1 = () => {
    setIsModalOpen1(false);
    setOtp('');
  };
  useEffect(() => {
    if (listServices && listServices.length > 0) {
      const newData = listServices.slice(startIndex, endIndex);
      setCurrentData(newData);
    }
  }, [listServices, currentPage]);
  useEffect(() => {
    if (services && services.length > 0) {
      const totalMinius = services.reduce(
        (sum, item) => sum + parseInt(item.time, 10),
        0,
      );
      setTotalMoney(
        services.reduce((sum, item) => sum + parseInt(item.cost, 10), 0),
      );
    }
  }, [services]);
  console.log(dataCutomer, 'dataCutomer');
  const handleFinish = async () => {
    // notification.success({
    //   message: 'Thành công',
    //   description: 'Thanh toán háo đơn thành công',
    // });
    // navigate(`/checkout/bill`);
    try {
      if (error) {
        // xác nhận lỗi
        const body = {
          id: dataDetail?.id,
          error: errorMessage,
          imageUrl: imageErrorUrl,
          amount: paymoney,
          pttt: type,
        };
        handleCheckout
          .confirmBillError(body)
          .then((response: any) => {
            const { data, statusCode } = response;
            if (statusCode === 200) {
              notification.success({
                message: 'Báo lỗi thành công',
                description:
                  'Đã chuyển hoá đơn này thành lỗi, hệ thống sẽ kiểm tra và cập nhật',
              });
              navigate(
                `/checkout/bill?salonId=${dataDetail?.salonId}&pageSize=10&pageIndex=1`,
              );
            } else {
              throw new Error('Error');
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (
          (!paymoney || Number(paymoney) < Number(dataDetail?.totalBill)) &&
          Number(dataDetail?.totalBill) !== 0
        ) {
          // xác nhận OTP nợ
          if (!dataCutomer) {
            return notification.error({
              message: 'Lỗi',
              description:
                'Chỉ chấp nhận ghi nợ khi khách hàng có tài khoản tại Facial Bar',
            });
          }
          const body = {
            loanAmount: Number(dataDetail?.totalBill) - Number(paymoney),
            phoneNumber: dataDetail?.phoneNumber,
            billId: dataDetail?.id,
          };
          handleCheckout
            .sendOTPDebt(body)
            .then((response: any) => {
              const { data, statusCode } = response;
              if (statusCode === 200) {
                setIsModalOpen1(true);
              } else {
                throw new Error('Error');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          // thanh toán 1 bill
          const body = {
            id: dataDetail?.id,
            paymentMethod: type,
            amount: Number(dataDetail?.totalBill),
            phoneNumber: dataDetail?.phoneNumber,
            imageUrl: imageUrl,
          };
          handleCheckout
            .paymentBill(body)
            .then((response: any) => {
              const { data, statusCode } = response;
              if (statusCode === 200) {
                notification.success({
                  message: 'Thành công',
                  description:
                    'Xác nhận thanh toán hoá đơn cho khách hàng thành công',
                });
                navigate(
                  `/checkout/bill?salonId=${dataDetail?.salonId}&pageSize=10&pageIndex=1`,
                );
              } else {
                throw new Error('Error');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  console.log(dataDetail?.totalBill, 'tổng bill');
  console.log(dataCutomer?.topUpAmount, 'số dư topup');
  console.log(dataCutomer, 'thông tin khách hàng');
  useEffect(() => {
    if (type === 'TOP_UP') {
      setPaymoney(dataDetail?.totalBill);
    }
  }, [type]);
  return (
    <>
      <div className="lg:flex-1 lg:ml-5">
        <div className="px-3 py-4 bg-white">
          <div className="flex items-center justify-between">
            <h3 className="font-bold">DỊCH VỤ</h3>
          </div>
          <Table
            columns={columnsService}
            dataSource={services}
            pagination={null}
            className="mt-4"
          />
        </div>
        <div className="px-3 py-4 mt-3 bg-white">
          <div className="flex items-center justify-between">
            <h3 className="font-bold">NHÂN VIÊN PHỤC VỤ</h3>
          </div>
          <div className="flex justify-between mt-3">
            <div>
              <div className="flex items-center">
                <div className="w-[150px] text-[#888]">Nhân viên Checkin:</div>
                <div className="font-bold">{dataDetail?.staffCheckin}</div>
              </div>
              <div className="flex items-center mt-[5px]">
                <div className="w-[150px] text-[#888]">Nhân viên phục vụ:</div>
                <div className="font-bold">{dataDetail?.staffServiceName}</div>
              </div>
              <div className="flex items-center mt-[5px]">
                <div className="w-[150px] text-[#888]">Nhân viên thu ngân:</div>
                <div className="font-bold">{dataDetail?.staffCheckin}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 py-4 mt-5 mb-10 bg-white">
          <div className="items-center justify-between lg:flex">
            <h3 className="font-bold">TIẾN HÀNH THANH TOÁN BILL</h3>
          </div>
          <div className="flex justify-between mt-3 max-lg:flex-col-reverse">
            <div className="flex-none max-lg:mx-[10%]">
              <div className="flex items-center justify-between w-full">
                <div className="w-[200px] text-[#888]">Tổng tiền:</div>
                <div>{dataDetail?.totalPrice?.toLocaleString('vi-VN')} đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">Tổng giảm:</div>
                <div>{dataDetail?.discount?.toLocaleString('vi-VN')}đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">TỔNG BILL</div>
                <div>{dataDetail?.totalBill?.toLocaleString('vi-VN')} đ</div>
              </div>
              <div className="flex items-center mt-[5px] w-full justify-between">
                <div className="w-[200px] text-[#888]">
                  Số tiền KH thanh toán:
                </div>
                {view && (
                  <div>
                    {(
                      Number(dataDetail?.totalBill) - Number(dataDetail?.debt)
                    )?.toLocaleString('vi-VN')}{' '}
                    đ
                  </div>
                )}
              </div>
              {!view && (
                <InputNumber
                  placeholder="Nhập vào số tiền khách trả"
                  className="mt-2 w-[260px]"
                  value={paymoney}
                  onChange={(value) => setPaymoney(value)}
                  min={0}
                  formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                  disabled={view || type === 'TOP_UP'}
                />
              )}

              {paymoney >= Number(dataDetail?.totalBill) &&
                dataDetail?.debt === 0 && (
                  <div className="text-[#1677FF] italic mt-2 mb-2">
                    Trả lại tiền thừa:{' '}
                    {(
                      Number(paymoney) - Number(dataDetail?.totalBill)
                    )?.toLocaleString('vi-VN')}
                    đ
                  </div>
                )}
              {paymoney < dataDetail?.totalBill && (
                <div className="flex items-center mt-[5px] w-full justify-between bg-[#8888881d] py-1 ">
                  <div className="w-[200px] text-[#888]">GHI NỢ</div>
                  <div>
                    {(
                      Number(paymoney) - Number(dataDetail?.totalBill)
                    )?.toLocaleString('vi-VN')}
                    đ
                  </div>
                </div>
              )}
              {dataDetail?.debt > 0 && view && (
                <div className="flex items-center mt-[5px] w-full justify-between bg-[#8888881d] py-1 ">
                  <div className="w-[200px] text-[#888]">GHI NỢ</div>
                  <div>{dataDetail?.debt?.toLocaleString('vi-VN')}đ</div>
                </div>
              )}
              <div className="mt-5 mb-2 font-bold">
                {view ? 'Ảnh thanh toán' : 'Tải ảnh thanh toán'}
              </div>
              {view ? (
                <>
                  {imageUrl ? (
                    <Image src={imageUrl} width={200} alt="ảnh thanh toán" />
                  ) : (
                    <span>Không chụp ảnh thanh toán</span>
                  )}
                </>
              ) : (
                <UploadCustomV2
                  setImageUrl={setImageUrl}
                  urlImage={imageUrl}
                  imageClass={ImageCalssEnum.CardPhoto}
                  forlderName={forlderName}
                ></UploadCustomV2>
              )}
            </div>
            <div className="lg:w-[350px] ml-5 max-lg:w-[400px] max-lg:mx-[auto] max-lg:mb-5">
              <div className="flex justify-between mb-5">
                <Button
                  className="w-[100px] h-[100px] text-center flex flex-col items-center"
                  onClick={() => setType('CASH')}
                  type={type === 'CASH' ? 'primary' : 'default'}
                  disabled={view}
                >
                  <img
                    src="/icon/cash.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">TIỀN MẶT</div>
                </Button>
                <Button
                  className="w-[100px] h-[100px] text-center flex flex-col items-center"
                  onClick={() => setType('QR')}
                  type={type === 'QR' ? 'primary' : 'default'}
                  disabled={view}
                >
                  <img
                    src="/icon/card.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">BANKING</div>
                </Button>
                <Button
                  className="w-[100px] h-[100px] text-center flex flex-col items-center"
                  onClick={() => setType('TOP_UP')}
                  type={type === 'TOP_UP' ? 'primary' : 'default'}
                  disabled={
                    view ||
                    dataCutomer?.topUpAmount < dataDetail?.totalBill ||
                    !dataCutomer?.name
                  }
                >
                  <img
                    src="/icon/topup.png"
                    alt="tiền mặt"
                    className="w-[50px] h-[40px] mt-3"
                  />
                  <div className="mt-2 font-bold">TOPUP</div>
                </Button>
              </div>
              {type === 'QR' && (
                <div className="flex items-center justify-center mb-2">
                  <Button className="mx-auto" onClick={showModal}>
                    Hiển thị mã thanh toán
                  </Button>
                </div>
              )}
              {!view && (
                <Button
                  className="w-full"
                  onClick={handleFinish}
                  disabled={!type}
                  type={type ? 'primary' : 'default'}
                >
                  HOÀN TẤT BILL
                </Button>
              )}
              <Checkbox
                onChange={() => setError(!error)}
                value={error}
                className="mt-3 mr-2"
                disabled={view}
              />
              Báo lỗi
              {error && (
                <div>
                  <TextArea
                    className="w-full mt-3"
                    placeholder="Nhập thông tin lỗi"
                    value={errorMessage}
                    onChange={(e) => setErrorMessage(e.target.value)}
                    disabled={view}
                  />
                  <div className="mt-5 mb-2 font-bold">
                    {view ? 'Ảnh Lỗi' : 'Tải ảnh lỗi'}
                  </div>
                  {view ? (
                    <>
                      {imageErrorUrl && (
                        <Image
                          src={imageErrorUrl}
                          width={200}
                          alt="ảnh lỗi thanh toán"
                        />
                      )}
                    </>
                  ) : (
                    <UploadCustomV2
                      setImageUrl={setImageErrorUrl}
                      urlImage={imageErrorUrl}
                      imageClass={ImageCalssEnum.CardPhoto}
                      forlderName={forlderName}
                    ></UploadCustomV2>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={500}
        destroyOnClose
      >
        {QRPayment.map((item, index) => (
          <div key={index}>
            <Image src={item} className="" />
            {/* <div className="flex items-center justify-center">
              <Button>Mã thanh toán {index + 1}</Button>
            </div> */}
          </div>
        ))}
      </Modal>
      <Modal
        title="Xác nhận OTP"
        open={isModalOpen1}
        onOk={handleOk1}
        onCancel={handleCancel1}
        width={500}
        destroyOnClose
        okText="Xác nhận"
        cancelText="Hủy bỏ"
      >
        <div className="mb-5">
          Xác nhận ghi nợ{' '}
          {(Number(paymoney) - Number(dataDetail?.totalBill))?.toLocaleString(
            'vi-VN',
          )}{' '}
          đ.
          <br></br>Để xác nhận ghi nợ, chúng tôi sẽ gửi một mã 6 số tới số điện
          thoại {dataDetail?.phoneNumber}. Vui lòng xác nhận mã OTP phía dưới để
          hoàn tất hoá đơn:
        </div>
        <div className="flex justify-center">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                className="border-[1px] ml-3 mr-3 rounded-[8px]"
                style={{
                  width: '35px',
                  height: '60px',
                  fontSize: '22px',
                  textAlign: 'center',
                  marginBottom: '20px',
                }}
              />
            )}
          />
        </div>
      </Modal>
    </>
  );
};

export default BillComponent;
