import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin, Select, Input } from 'antd';
import { handleStaff, handleAdress, handleSalon } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbListStaff as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListStaff as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';
const { TextArea } = Input;

export const ListStaff = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [text, setText] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const title = 'Danh sách nhân viên';
  const [titleButton, settitleButton] = useState('');
  const routerAdd = '/staff/list/add';

  const handleGetDataStaff = async () => {
    setTableLoading(true);
    const urlParams = getParamsFromUrl(location);
    if (Role === 'SM' || Role === 'ASM' || Role === 'RSM') {
      urlParams.salonIds = UserInfor?.salonIds?.join(',');
    }
    handleStaff
      .GetListStaff(urlParams)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      UserInfor &&
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetDataStaff();
    }
  }, [location, UserInfor]);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    if (Role) {
      if (Role !== 'SM' && Role !== 'ASM' && Role !== 'RSM') {
        settitleButton('Thêm mới nhân viên');
        setEdit(true);
      }
    }
  }, [Role]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const showModal = (data) => {
    setModalData(data);
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
    setModalData(null);
    setRSMId(null);
    setASMId(null);
    setSalonId(null);
    setNotes('');
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/staff/list/${record.id}`);
  };

  const handleDelete = (record: any) => {
    showModal(record);
  };

  const handleChangeStaffSalon = async () => {
    if (!salonId)
      return notification.error({
        message: 'Lỗi',
        description: 'Vui lòng chọn Salon mới',
      });
    const body = {
      staff_id: modalData.id,
      new_salon_id: salonId,
      region_id: rsmId,
      sub_region_id: asmId,
      notes: notes,
    };
    handleStaff
      .ChangeSalon(body)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          notification.success({
            message: 'Thành công',
            description: 'Chuyển Salon thành công cho nhân viên',
          });
          handleGetDataStaff();
          hideModal();
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [listCity, setListCity] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);

  const GetDropListPosition = async () => {
    handleStaff
      .GetDropListPositionFull()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
          throw new Error('ERROR');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const [listSaloz, setListSaloz] = useState([]);
  console.log(listSaloz, 'listSaloz');
  const [listSubRegion, setListSubRegion] = useState([]);
  const handleGetListSalon = async () => {
    handleSalon
      .GetDropListSalon()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListSaloz(data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (listCity && listDepartment) {
      setDataFilter([
        {
          value: '1',
          label: 'Tên / mã nhân viên',
          type: 'input',
          name: 'codeOrName',
          placeholder: 'Nhập tên/ mã nhân viên',
        },
        {
          value: '2',
          label: 'Số điện thoại',
          type: 'input',
          name: 'phone',
          placeholder: 'Nhập số điện thoại nhân viên',
        },
        {
          value: '3',
          label: 'Vị trí',
          type: 'select',
          name: 'positionId',
          placeholder: 'Lựa chọn vị trí',
          list: listCity,
        },
        {
          value: '4',
          label: 'Trạng thái nhân viên',
          type: 'select',
          name: 'statusId',
          placeholder: 'Lựa chọn trạng thái của nhân viên',
          list: [
            {
              value: '1',
              label: 'Hoạt động',
            },
            {
              value: '2',
              label: 'Nghỉ phép',
            },
            {
              value: '3',
              label: 'Thôi việc',
            },
          ],
        },
        {
          value: '5',
          label: 'Lựa chọn Salon',
          type: 'mutiselect',
          name: 'salonIds',
          placeholder: 'Lựa chọn Salon',
          list: listSaloz,
        },
        {
          value: '6',
          label: 'Lựa chọn Vùng',
          type: 'select',
          name: 'regionId',
          placeholder: 'Lựa chọn Vùng',
          list: listSubRegion,
        },
      ]);
    }
  }, [listCity, listDepartment, listSaloz]);
  const keyName = 'STAFFCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} nhân viên
      </span>
    );
  };
  const handleChangeSalon = (record: any) => {
    console.log(record, 'record');
    setModalData(record);
    setOpen(true);
  };
  const [rsmId, setRSMId] = useState();
  const [asmId, setASMId] = useState();
  const [salonId, setSalonId] = useState([]);
  const [notes, setNotes] = useState('');
  const [listRegionRSM, setListRegionRSM] = useState([]);
  const [listRegionASM, setListRegionASM] = useState([]);
  const [listSalon, setListSalon] = useState([]);
  const handleGetListRegionRSM = async () => {
    handleAdress
      .GetListRegionRSM()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListRegionRSM(data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListRegionASM = async (value) => {
    handleAdress
      .GetListRegionASM(value)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListRegionASM(data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListSalonRegion = async (value) => {
    handleStaff
      .GetDropList({ name: 'SALON', regionId: value })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListSalon(data);
        } else {
          throw new Error('Invalid status code: ' + statusCode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListSubRegion = async () => {
    handleStaff
      .GetDropList({ name: 'SUB_REGION' })
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListSubRegion(data);
        } else {
          throw new Error('Invalid status code: ' + statusCode);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleChangeRegion = (value) => {
    setRSMId(value);
    handleGetListRegionASM(value);
  };
  const handleChangeRegionASM = (value) => {
    setASMId(value);
    handleGetListSalonRegion(value);
  };
  useEffect(() => {
    if (UserInfor) {
      GetDropListPosition();
      handleGetListRegionRSM();
      handleGetListSalon();
      handleGetListSubRegion();
    }
  }, [UserInfor]);
  console.log(salonId, 'salonId');
  return (
    <Spin spinning={loading}>
      {modalData && (
        <Modal
          title="Chuyển Salon"
          open={open}
          onOk={handleChangeStaffSalon}
          onCancel={() => hideModal()}
          okText="Xác nhận"
          cancelText="Hủy bỏ"
          destroyOnClose
        >
          <p>
            Xác nhận chuyển nhân viên{' '}
            <strong>
              {modalData.code} - {modalData.full_name}
            </strong>{' '}
            tới salon mới:
          </p>
          <div className="flex justify-between mt-4 mb-3">
            <Select
              showSearch
              className="w-[49%]"
              placeholder="Lựa chọn miền"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeRegion(e)}
              options={listRegionRSM}
            />
            <Select
              showSearch
              className="w-[49%]"
              placeholder="Lựa chọn vùng"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeRegionASM(e)}
              options={listRegionASM}
              disabled={!rsmId}
            />
          </div>
          <Select
            showSearch
            className="w-full mb-3"
            placeholder="Lựa chọn Salon"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              removeVietnamese(option?.label ?? '').includes(
                removeVietnamese(input),
              )
            }
            value={salonId}
            onChange={(e) => setSalonId(e)}
            options={listSalon}
            disabled={!rsmId || !asmId}
          />
          <TextArea
            placeholder="Nhập thông tin, lý do"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
          ></TextArea>
        </Modal>
      )}

      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <FilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />

      <TabelComponent
        columns={columns({ handleEdit, handleDelete, handleChangeSalon, edit })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
