import { axiosInstanceClient } from '@configs/instance';

const getTonKhoVatTu = (param: any, token: string): Promise<any> => {
  return axiosInstanceClient.get(
    process.env.REACT_APP_API_FAST + `/InventoryItem`,
    {
      params: param,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
};

const loginFast = (body: any): Promise<any> => {
  return axiosInstanceClient.post(
    process.env.REACT_APP_API_FAST + `/Login`,
    body,
  );
};

export default {
  getTonKhoVatTu,
  loginFast,
};
