import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import {
  notification,
  Select,
  Input,
  DatePicker,
  Button,
  Table,
  Dropdown,
} from 'antd';
import { handleSalon, handleStaff, handleTimekeeping } from '@services';
import { useNavigate } from 'react-router-dom';
import { breadcrumbCreateTimekeeping as breadcrumbItems } from '@components/Breadcrumb';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import {
  SearchOutlined,
  PlusCircleOutlined,
  FileExcelOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  generateDateWeekArray,
  generateDateMonthArray,
} from '@utils/Functions/convertDate';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';
import { getDaysInMonth } from '@utils/Functions/timekeeping';

const { RangePicker } = DatePicker;
export const AddTimeKeeping = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const title = 'Đăng ký lịch làm việc cho nhân viên';
  const titleButton = '';
  const routerAdd = '';
  const [listSalon, setListSalon] = useState([]);
  const [dataStaff, setDataStaff] = useState([]);
  const [staff, setStaff] = useState([]);
  const [picker, setPicker] = useState<any>('week');
  const handleChangeTypeDate = (type: string) => {
    setPicker(type);
  };
  const items = [
    {
      label: 'Chi tiết ca làm việc',
      key: '1',
      icon: <FileExcelOutlined />,
    },
    {
      label: 'Chi tiết giờ làm việc',
      key: '2',
      icon: <FileExcelOutlined />,
    },
    {
      label: 'Tổng số phạt vi phạm',
      key: '3',
      icon: <FileExcelOutlined />,
    },
  ];
  const handleMenuClick = (e) => {
    console.log('click', e);
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };
  const days = getDaysInMonth('2024-01');
  console.log('Các ngày trong tháng:', days);
  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="">
        <div className="flex justify-between my-5">
          <div className="flex">
            <Select
              showSearch
              value={staff}
              onChange={(e) => setStaff(e)}
              className="w-[300px]"
              placeholder="Lựa chọn nhân viên"
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={dataStaff}
            />
            <DatePicker
              placeholder={'Lựa chọn thời gian'}
              className="w-[200px] h-[32px] mx-5"
              onChange={onChangeDate}
              picker={picker}
            />
            <div className="flex">
              <Button
                onClick={() => handleChangeTypeDate('date')}
                type={picker === 'date' ? 'primary' : 'default'}
              >
                Ngày
              </Button>
              <Button
                onClick={() => handleChangeTypeDate('week')}
                type={picker === 'week' ? 'primary' : 'default'}
                className="mx-2"
              >
                Tuần
              </Button>
              <Button
                onClick={() => handleChangeTypeDate('month')}
                type={picker === 'month' ? 'primary' : 'default'}
              >
                Tháng
              </Button>
            </div>
          </div>
          <div className="flex">
            <Button className="flex items-center mr-2" type="primary">
              <PlusCircleOutlined className="relative" />
              <span>Đặt lịch</span>
            </Button>
            <Dropdown menu={menuProps}>
              <Button className="flex items-center bg-[#4BAC4D] text-white">
                <FileExcelOutlined />
                <span>Xuất file Excel</span>
                <CaretDownOutlined />
              </Button>
            </Dropdown>
          </div>
        </div>
        <div
          className="bg-white rounded-[3px]"
          style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
        >
          <div className="flex items-center border-b-[1px] border-[#D9D9D9]">
            <div className="flex items-center border-r-[1px] border-[#D9D9D9] py-2 w-[120px] justify-center">
              <div className="font-bold text-[12px]">Ca làm việc</div>
              <PlusCircleOutlined className="relative ml-1 top-[1px] text-[18px] text-[#1677FF] cursor-pointer" />
            </div>
            <div className="border-r-[1px] border-[#D9D9D9] py-2 w-[140px] text-center font-bold text-[12px]">
              Nhân viên
            </div>
            <div className="flex items-center justify-between flex-1">
              {days.map((dataDate, index) => (
                <div
                  className="flex-1 border-r-[1px] border-[#D9D9D9] py-2 flex flex-col items-center justify-center px-auto parent-div"
                  key={index}
                >
                  <div className="w-[18px] mx-auto text-[12px] font-bold">
                    {dataDate?.title}
                  </div>
                  <div className="bg-[#d9d9d9] w-[18px] mx-auto text-[12px] text-center">
                    {dataDate?.date}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center h-[50px]"></div>
        </div>
      </div>
    </>
  );
};
