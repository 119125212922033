import { instance } from '@configs/instance';

const getListStaff = (param: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_BOOKING + `/staffs`, {
    params: param,
  });
};
const sendOTPDebt = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/loan/send-otp`,
    body,
  );
};
const verifyOTPDebt = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/loan/verify-otp`,
    body,
  );
};
const paymentBill = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bill/pay/one`,
    body,
  );
};
const createTopup = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/topups`,
    body,
  );
};
const updateTopup = (body: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/topups`,
    body,
  );
};
const listTopup = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/topups`,
    { params: params },
  );
};
const listBill = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bills`,
    { params: params },
  );
};
const detailTopup = (topupId: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/topups/${topupId}`,
  );
};
const payAllBill = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bill/pay/all`,
    body,
  );
};
const detailBill = (billId: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bills/${billId}`,
  );
};
const listBillCutomer = (cutomerId: any, params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/${cutomerId}/bills`,
    { params: params },
  );
};
const confirmBillError = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bill/report-error`,
    body,
  );
};
const statiscialBill = (params: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_BOOKING + `/api/v1/customer/bills/report`,
    { params: params },
  );
};
export default {
  getListStaff,
  sendOTPDebt,
  verifyOTPDebt,
  paymentBill,
  createTopup,
  updateTopup,
  listTopup,
  listBill,
  detailTopup,
  payAllBill,
  detailBill,
  listBillCutomer,
  confirmBillError,
  statiscialBill,
};
