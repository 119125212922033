import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin, Button, Upload, message } from 'antd';
import { UploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { handleSalary } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterConfig } from '@components/Filter';
import TabelComponent from '@components/Table';
import { breadcrumbBonusSalary as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListBonusSalary as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import axios from 'axios';
import { UploadProps } from 'antd/lib/upload';
import '../index.scss';

export const BonusSalary = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [openExcel, setOpenExcel] = useState(false);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const title = 'Cấu hình lương thưởng nhân viên';
  const titleButton = '';
  const routerAdd = '';
  const handleGetListSalaryBonus = () => {
    const urlParams = getParamsFromUrl(location);
    handleSalary
      .GetListSalaryBonus({ ...urlParams, userId: UserInfor?.accountId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        console.log(data, 'data');
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    if (
      UserInfor?.accountId &&
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListSalaryBonus();
    }
  }, [location, UserInfor]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleDownloadExcel = async () => {
    setLoading(true);
    handleSalary
      .DownloadExcelSalary(UserInfor?.accountId, setLoading)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} nhân viên
      </span>
    );
  };
  const accessToken = Cookies.get('accessToken');
  const props: any = {
    action: process.env.REACT_APP_API_STAFF + `/salary/extra-bonus/import`,
    method: 'post', // hoặc 'POST'
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    customRequest: ({ file, onSuccess, onError }) => {
      const formData = new FormData();
      formData.append('file', file);

      axios({
        url: props.action,
        method: 'POST',
        data: formData,
        headers: props.headers,
      })
        .then((response) => {
          onSuccess(response.data, file);
          message.success(`${file.name} file uploaded successfully`);
        })
        .catch((error) => {
          onError(error);
          message.error(`${file.name} file upload failed.`);
        });
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
    },
  };
  const handleDelete = () => {};
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="flex items-start pb-4 mt-5">
        <Button type="primary">
          <Upload {...props} className="" accept={'xlsx'}>
            <UploadOutlined className="relative top-[-1px] text-white mr-2" />
            <span className="text-white">Upload file excel</span>
          </Upload>
        </Button>
        <Button className="ml-5 button-green" onClick={handleDownloadExcel}>
          <CloudDownloadOutlined className="relative top-[-3px]" />
          Tải file mẫu về
        </Button>
        <Button className="ml-5" onClick={handleGetListSalaryBonus}>
          Xem danh sách lương thưởng
        </Button>
      </div>

      <TabelComponent
        columns={columns({ handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
