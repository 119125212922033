import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Select, notification, InputNumber } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { CategoryType } from './type';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import { handleAdress, handleStaff, handleSalon } from '@services';

export const SalaryForm = ({ initialValues, onFinish, onFinishFailed }) => {
  const [listWorkPosition, setListWorkPosition] = useState([]);
  const [listDepartment, setListDepartment] = useState([]);
  const [group, setGroup] = useState();
  const GetDropListPosition = async () => {
    handleStaff
      .GetDropListPosition(1)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListWorkPosition(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetDropListPosition();
  }, []);
  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item
        label="Vị trí"
        name="position_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn vị trí làm việc',
          },
        ]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn vị trí"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listWorkPosition}
          disabled={!initialValues.config}
        />
      </Form.Item>
      <Form.Item
        label="Tiêu đề cấp bậc lương"
        name="name"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tiêu đề',
          },
        ]}
      >
        <Input placeholder="Nhập tiêu đề" className="w-full" />
      </Form.Item>
      {/* <Form.Item
        label="Văn phòng/ Khối Salon"
        name="department_id"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn Khối/ Salon',
          },
        ]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn Khối/ salon"
          optionFilterProp="children"
          value={group ? group : undefined}
          onChange={(value) => handleChangeDepament(value)}
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listDepartment}
        />
      </Form.Item> */}
      <Form.Item
        label="Nhập mức lương"
        name="base_salary"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập mức lương',
          },
        ]}
      >
        <InputNumber
          placeholder="Nhập mức lương cơ bản"
          className="w-full"
          min={0}
          formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
        />
      </Form.Item>
      <Form.Item
        label="Thời gian làm việc/ tháng (giờ)"
        name="total_hours"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập tổng giờ làm việc trong tháng',
          },
        ]}
      >
        <InputNumber
          placeholder="Tổng giờ làm việc trong tháng"
          className="w-full"
          min={0}
          formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
        />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
