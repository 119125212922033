import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Radio,
  notification,
  Switch,
  Checkbox,
  InputNumber,
} from 'antd';
import { SearchOutlined, DeleteOutlined } from '@ant-design/icons';
import {
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateAdress,
  validateFullName,
} from '@utils/Functions/validate';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import { handleVoucher, handleService, handleCategory } from '@services';
import dayjs from 'dayjs';

export const VoucherForm = ({
  initialValues,
  onFinish,
  onFinishFailed,
  condition,
  setCondition,
  category,
  setCategory,
}) => {
  const [form] = Form.useForm();
  const [listCategory, setListCategory] = useState([]);
  console.log(listCategory, 'listCategory');
  const [listApplicableSubjects, setListApplicableSubjects] = useState([]);
  const [conditionSelect, setConditionSelect] = useState();
  const [listServices, setListServices] = useState([]);
  const [listServicesGift, setListServicesGift] = useState([]);
  const [listType, setListType] = useState([
    { value: 1, label: 'Giảm giá theo phần trăm' },
    { value: 2, label: 'Giảm giá theo tiền mặt' },
  ]);
  const handleGetListCategory = async () => {
    handleCategory
      .GetDropListCategory()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListCategory(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListServices = async (categoryId) => {
    handleService
      .GetListDropService({ categoryId: categoryId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListServices(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListServicesGift = async (categoryId) => {
    handleService
      .GetListDropService({ categoryId: categoryId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListServicesGift(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (category && category.length > 0) {
      handleGetListServices(category.join(','));
    }
  }, [category]);
  const hanldeGenerateCode = async () => {
    handleVoucher
      .GetDropListApplicableSubjects()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListApplicableSubjects(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Có lỗi xảy ra trong quá trình xử lý dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    hanldeGenerateCode();
    handleGetListCategory();
  }, []);
  useEffect(() => {
    if (listCategory.length > 0) {
      const valuesArray = listCategory.map((item) => item.value);
      handleGetListServicesGift(valuesArray.join(','));
    }
  }, [listCategory.length]);
  const handleAddCondition = () => {
    // Loại bỏ phần tử chọn khỏi list danh sách cũ
    const newlistApplicable = listApplicableSubjects.filter(
      (item) => item.value !== conditionSelect,
    );
    const newItem = listApplicableSubjects.find(
      (item) => item.value === conditionSelect,
    );
    const updateItem = {
      format: newItem.format,
      id: newItem.value,
      label: newItem.label,
      value: null,
    };
    const newCondition = [...condition];
    newCondition.push(updateItem);
    // Cập nhật state với mảng mới
    setConditionSelect(null);
    setListApplicableSubjects(newlistApplicable);
    setCondition(newCondition);
  };
  const handleRemoveCondition = (item) => {
    console.log(item, 'item');
    const updateCondition = condition.filter((data) => data.id !== item.id);
    const newItem = {
      format: item.format,
      value: item.id,
      label: item.label,
    };
    const newApplicable = [...listApplicableSubjects];
    newApplicable.push(newItem);
    setCondition(updateCondition);
    setListApplicableSubjects(newApplicable);
  };
  const handleChangeCondition = (value, item) => {
    console.log(value, item, 'value');
    const newCondition = condition.map((data) => {
      if (data.id !== item.id) {
        return data; // Trả về phần tử không thay đổi nếu ID không khớp
      }
      // Nếu ID khớp, cập nhật dựa trên format
      switch (item.format) {
        case 'number':
          return { ...data, value: value }; // Cập nhật giá trị cho format 'number'
        case 'date':
          return { ...data, value: dayjs(value).format('YYYY/MM/DD') }; // Cập nhật giá trị cho format 'date'
        default:
          return data; // Trong trường hợp format không được hỗ trợ, trả về phần tử không thay đổi
      }
    });

    setCondition(newCondition); // Cập nhật state
  };
  const filterArray = (array1, array2) => {
    const idSet = new Set(array2.map((item) => item.id));
    return array1.filter((item) => !idSet.has(item.value));
  };
  useEffect(() => {
    if (condition.length > 0) {
      const newListApp = [...listApplicableSubjects];
      setListApplicableSubjects(filterArray(newListApp, condition));
    }
  }, [condition]);
  const [type, setType] = useState(true);
  const handleChangeDiscountType = (value) => {
    if (value === 1) {
      setType(true);
    } else {
      setType(false);
    }
  };
  useEffect(() => {
    if (initialValues?.discountType === 2) {
      setType(false);
    }
  }, [initialValues]);
  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
      // onValuesChange={(changedValues, allValues) => {
      //   handleChangeValue(changedValues, allValues);
      // }}
    >
      <Form.Item
        label="Tên ưu đãi/ Mã ưu đãi"
        name="name"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên ưu đãi',
              },
            ]}
            className="w-[90%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên ưu đãi" />
          </Form.Item>
          <Form.Item name="code" className="h-[33px] w-[120px] ml-4 mg-bttom-0">
            <Input placeholder="Mã ưu đãi" disabled />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item label="Danh mục dịch vụ" name="categoryId">
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Lựa chọn danh mục dịch vụ"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          onChange={(value) => setCategory(value)}
          options={listCategory}
        />
      </Form.Item>

      <Form.Item label="Lựa chọn dịch vụ" name="spaServiceId">
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Lựa chọn dịch vụ"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listServices}
          disabled={!category}
        />
      </Form.Item>

      <Form.Item
        label="Giảm giá"
        name="discountValue"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="w-full"
      >
        <div className="flex items-center">
          <Form.Item
            name="discountType"
            className="w-full mg-bttom-0"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn kiểu giảm giá',
              },
            ]}
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn kiểu giảm giá"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(value) => handleChangeDiscountType(value)}
              options={listType}
            />
          </Form.Item>
          <Form.Item
            name="discountValue"
            className="w-full ml-4 mg-bttom-0"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập giá trị giảm giá',
              },
            ]}
          >
            <InputNumber
              placeholder="Nhập giá trị giảm giá"
              className="w-full"
              formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
              min={0}
            />
          </Form.Item>
        </div>
      </Form.Item>
      {type && (
        <Form.Item
          label="Giảm giá tối đa"
          name="discountMax"
          rules={[
            {
              required: true,
              message: 'Vui lòng nhập giá trị giảm giá tối đa',
            },
          ]}
          className="w-full"
        >
          <InputNumber
            placeholder="Nhập giá trị giảm giá tối đa"
            className="w-full"
            min={0}
            formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
          />
        </Form.Item>
      )}

      {/* <Form.Item label="Tặng dịch vụ" name="giftServiceIds" className="w-full">
        <Select
          mode="multiple"
          showSearch
          className="w-full"
          placeholder="Lựa chọn dịch vụ tặng kèm"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listServicesGift}
        />
      </Form.Item> */}
      <Form.Item label="Tặng quà" name="giftItems" className="w-full">
        <Input placeholder="Nhập quà tặng kèm" />
      </Form.Item>
      <Form.Item
        label="Hiển thị trên booking"
        name="activeBooking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item
        label="Trạng thái hoạt động"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item
        label="Ngày bắt đầu/ kết thúc"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="w-full"
      >
        <div className="flex items-center">
          <Form.Item
            name="startDate"
            className="w-full mg-bttom-0"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày bắt đầu',
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              placeholder="Chọn ngày bắt đầu"
              className="w-full"
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            className="w-full ml-4 mg-bttom-0"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn ngày kết thúc',
              },
            ]}
          >
            <DatePicker
              className="w-full"
              placeholder="Chọn ngày kết thúc"
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </div>
      </Form.Item>
      {/* <Form.Item label="Điều kiện áp dụng">
        <div className="flex">
          <Select
            showSearch
            className="w-full"
            placeholder="Lựa chọn điều kiện áp dụng"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              removeVietnamese(option?.label ?? '').includes(
                removeVietnamese(input),
              )
            }
            value={conditionSelect}
            onChange={(value) => setConditionSelect(value)}
            options={listApplicableSubjects}
          />
          <Button className="ml-2" onClick={handleAddCondition}>
            Thêm
          </Button>
        </div>
      </Form.Item> */}
      {condition.map((item, index) => (
        <div key={`${item.id}_${index}`}>
          {/* Sử dụng kết hợp item.id và index để tạo key duy nhất */}
          {item.format === 'number' && (
            <Form.Item label={item.label}>
              <div className="flex">
                <InputNumber
                  className="w-full"
                  placeholder={item.label}
                  value={item.value}
                  onChange={(value) => handleChangeCondition(value, item)}
                  min={0}
                  formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
                />
                <Button
                  className="ml-2"
                  onClick={() => handleRemoveCondition(item)}
                >
                  <DeleteOutlined className="relative top-[-2px]" />
                </Button>
              </div>
            </Form.Item>
          )}
          {item.format === 'date' && (
            <Form.Item label={item.label}>
              <div className="flex">
                <DatePicker
                  className="w-full"
                  placeholder={item.label}
                  value={item.value ? dayjs(item.value) : null}
                  onChange={(value) => handleChangeCondition(value, item)}
                />
                <Button
                  className="ml-2"
                  onClick={() => handleRemoveCondition(item)}
                >
                  <DeleteOutlined className="relative top-[-2px]" />
                </Button>
              </div>
            </Form.Item>
          )}
        </div>
      ))}

      <Form.Item label="Ghi chú" name="description">
        <TextArea placeholder="Nhập vào ghi chú cho dịch vụ" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
