import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification, Segmented, Spin } from 'antd';
import { handleClinic, handleCustomer } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailCustomer as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { CategoryForm } from '@components/Form/Category';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import CustomerInformation from '@components/TitleBox/CustomerInformation';
import ListBill from './components/Bill';
import HistoryServices from './components/HistoryServices';
import TopupHistory from './components/TopupHistory';
import ListVoucher from './components/Voucher';
import '../index.scss';

export const DetailCustomer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>();
  const [dataDetail, setDataDetail] = useState<any>();
  const [dataDetail2, setDataDetail2] = useState<any>();
  const [segment, setSegment] = useState('Bill dịch vụ');
  const [title, setTitle] = useState('Chi tiết khách hàng');
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'customer';
  const [loading, setLoading] = useState(false);
  const handleDetailCustomer = async () => {
    handleCustomer
      .GetDetailInforCutomers(id)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataDetail(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDetailCustomerByPhone = async (phone) => {
    handleCustomer
      .GetDetailCutomerByPhone(phone)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataDetail2(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (id) {
      handleDetailCustomer();
    }
  }, [id]);
  useEffect(() => {
    if (dataDetail?.phone) {
      handleDetailCustomerByPhone(dataDetail?.phone);
    }
  }, [dataDetail]);
  const handleChangeSegment = (value) => {
    console.log(value, 'value');
    setSegment(value);
  };
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      {dataDetail2 ? (
        <CustomerInformation
          data={dataDetail2}
          handleDetailCustomerByPhone={handleDetailCustomerByPhone}
        />
      ) : (
        <div className="flex justify-center mt-5 mb-5 bg-white py-14">
          <Spin />
        </div>
      )}
      <div className="flex items-start justify-between">
        <div className="w-[49%]">
          <Segmented
            options={[
              'Bill dịch vụ',
              'Lịch sử mua Topup',
              // 'Lịch sử sử dụng dịch vụ',
            ]}
            value={segment}
            block
            className="w-full"
            onChange={(value) => handleChangeSegment(value)}
          />
          <>{segment === 'Bill dịch vụ' ? <ListBill /> : null}</>
          <>{segment === 'Lịch sử mua Topup' ? <TopupHistory /> : null}</>
          {/* <>
            {segment === 'Lịch sử sử dụng dịch vụ' ? <HistoryServices /> : null}
          </> */}
        </div>
        <div className="w-[50%]">
          <ListVoucher />
        </div>
      </div>
    </>
  );
};
