import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleBooking, handleAdress } from '@services';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { CheckinFilterConfig } from '@components/Filter/CheckinFilterBooking';
import TabelComponent from '@components/Table/TableResponsive';
import { breadcrumbListBooking as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListBooking as columns } from '@components/Table/Columns';
import { getParamsFromUrl } from '@utils/Functions/getParams';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';

export const ListBooking = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const [listCity, setListCity] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const title = 'Danh sách Booking';
  const titleButton = '';
  const routerAdd = '';

  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListBooking = async () => {
    const urlParams: any = getParamsFromUrl(location);
    setTableLoading(true);
    const params = { ...urlParams };
    if (Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV') {
      params.salonId = UserInfor?.salonIds[0];
      console.log('đã push');
    }
    handleBooking
      .GetListBooking(params)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setData(data);
          setCurrentPage(Number(optional?.pageIndex));
          setTotalItems(Number(optional?.getTotalElements));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  useEffect(() => {
    if (
      UserInfor?.accountId &&
      location &&
      queryParams.get('pageIndex') &&
      queryParams.get('pageSize')
    ) {
      handleGetListBooking();
    }
  }, [location, UserInfor]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
    const params = new URLSearchParams(window.location.search);
    if (params.has('pageIndex')) {
      params.set('pageIndex', page);
    } else {
      params.append('pageIndex', page);
    }
    const newUrl = `${window.location.pathname}?${params.toString()}`;
    navigate(newUrl);
  };

  const handleEdit = (record: any) => {
    console.log(record, 'record');
    navigate(`/customer/${record.id}`);
  };

  const handleDelete = (record: any) => {
    console.log(record, 'record');
    navigate(`/customer/debt/${record.id}`);
  };

  useEffect(() => {
    if (listCity) {
      setDataFilter([
        {
          value: '1',
          label: 'Tên/ Tên tắt Salon',
          type: 'input',
          name: 'name',
          placeholder: 'Nhập tên/ tên viết tắt Salon',
        },
        {
          value: '2',
          label: 'Số điện thoại Salon',
          type: 'input',
          name: 'phone',
          placeholder: 'Nhập số điện thoại Salon',
        },
        {
          value: '3',
          label: 'Địa chỉ Tỉnh/ Thành phố',
          type: 'select',
          name: 'city',
          placeholder: 'Lựa chọn Tỉnh/ Thành phố',
          list: listCity,
        },
        {
          value: '4',
          label: 'Trạng thái hoạt động',
          type: 'select',
          name: 'active',
          placeholder: 'Lựa chọn trạng thái của Salon',
          list: [
            {
              value: 'true',
              label: 'Hoạt động',
            },
            {
              value: 'false',
              label: 'Tạm đóng',
            },
          ],
        },
      ]);
    }
  }, [listCity]);
  const keyName = 'BOOKINGCOLUMN';
  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} lượt booking
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <CheckinFilterConfig
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
        setData={setData}
      />
      <TabelComponent
        columns={columns({ handleEdit, handleDelete })}
        data={data}
        tableLoading={tableLoading}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        totalItems={totalItems}
        customTotal={customTotal}
      />
    </Spin>
  );
};
