import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import {
  notification,
  Button,
  Input,
  Pagination,
  Select,
  InputNumber,
  Modal,
  DatePicker,
} from 'antd';
import { LeftOutlined, DownOutlined } from '@ant-design/icons';
import { handleCustomer, handleBooking, handleAdress } from '@services';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { breadcrumbCheckinTuVan as breadcrumbItems } from '@components/Breadcrumb';
import { BookingForm } from '@components/Form/Booking';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import { convertStringToList } from '@utils/Functions/configArray';
import dayjs from 'dayjs';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { compareTimes } from '@utils/Functions/convertDate';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';
const { TextArea } = Input;

export const WebCheckinAdd = () => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const phone = queryParams.get('phone');
  const salonId = queryParams.get('salonId');
  const title = 'Tạo mới Booking & Tư vấn cho khách';
  const titleButton = '';
  const routerAdd = '';
  const [timeId, setTimeId] = useState<any>(null);
  const [search, setSearch] = useState<any>('');
  const [listServicesIdBooking, setListServicesIdBooking] = useState<any>([]);
  const [services, setServices] = useState<any>([]);
  const [listTimeBed, setListTimeBed] = useState([]);
  const [listBed, setListBed] = useState([]);
  const [bedId, setBedId] = useState<any>();
  const [listServices, setListServices] = useState([]);
  const [listStaff, setListStaff] = useState([]);
  const [staffId, setStaffId] = useState();
  const [serviceTime, setServiceTime] = useState();
  const [serviceTimeS, setServiceTimeS] = useState<any>();
  const [totalMoney, setTotalMoney] = useState<any>();
  const [customerInformation, setCustomerInformation] = useState<any>();
  const [currentData, setCurrentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 2;
  const [startIndex, setStartIndex] = useState(1);
  const [endIndex, setEndIndex] = useState(0);
  const pageSize2 = 2;
  const [currentData2, setCurrentData2] = useState([]);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [startIndex2, setStartIndex2] = useState(0);
  const [endIndex2, setEndIndex2] = useState(2);
  const pageSize3 = 2;
  const [currentData3, setCurrentData3] = useState([]);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [startIndex3, setStartIndex3] = useState(0);
  const [endIndex3, setEndIndex3] = useState(2);
  const [listStringServices, setListStringServices] = useState('');
  const [step1, setStep1] = useState(false);
  const [step2, setStep2] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [voucher, setVocher] = useState<any>([]);
  const [listVoucher, setListVocher] = useState<any>([]);
  const [listVoucher2, setListVocher2] = useState<any>([]);
  const [dataPrice, setDataPrice] = useState<any>();
  // thông tin khách
  const [listCity, setListCity] = useState([]);
  const [listDistric, setListDistric] = useState([]);
  const [listStress, setListStress] = useState([]);
  const [city, setCity] = useState();
  const [distric, setDistric] = useState();
  const [stress, setStress] = useState();
  const [name, setName] = useState<any>();
  const [addressMore, setAddressMore] = useState<any>();
  const [birthday, setBirthday] = useState<any>();
  const [note, setNote] = useState<any>();
  const showModal = () => {
    setIsModalOpen(true);
  };
  console.log(city, 'city');
  const handleOk = () => {
    const body = {
      id: customerInformation?.id,
      provinceCode: city,
      districtCode: distric,
      wardCode: stress,
      name: name,
      addressMore: addressMore,
      birthday: birthday,
      note: note,
    };
    handleCustomer
      .UpdateInforCutomers(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          setIsModalOpen(false);
          handleGetInforCustomer(customerInformation?.phone);
          notification.success({
            message: 'Thành công',
            description: 'Thay đổi thông tin khách hàng thành công',
          });
        } else {
          throw new Error('');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handle2Ok = () => {
    const body = {
      phone: phone,
      provinceCode: city,
      districtCode: distric,
      wardCode: stress,
      name: name,
      addressMore: addressMore,
      birthday: birthday,
      note: note,
    };
    handleCustomer
      .CreateCustomer(body)
      .then((response: any) => {
        const { statusCode } = response;
        if (statusCode === 200) {
          setIsModalOpen(false);
          handleGetInforCustomer(phone);
          notification.success({
            message: 'Thành công',
            description: 'Tạo thông tin khách hàng thành công',
          });
        } else {
          throw new Error('');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDistric = async (provinceCode) => {
    handleAdress
      .GetListDistricts(provinceCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListDistric(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetStress = async (districtCode) => {
    handleAdress
      .GetListWards(districtCode)
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListStress(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
  }, []);
  const handleChangeCity = (value) => {
    setCity(value);
    handleGetDistric(value);
    setDistric(null);
    setStress(null);
  };
  const handleChangeDistrict = (value) => {
    setDistric(value);
    setStress(null);
    handleGetStress(value);
  };
  const convertTimeFormat = (fromTime) => {
    return fromTime.slice(0, 5);
  };
  const handleGetInforCustomer = async (phone: any) => {
    handleCustomer
      .GetDetailCutomerByPhone(phone)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setCustomerInformation(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (phone) {
      handleGetInforCustomer(phone);
    }
  }, [phone]);

  const handleGetListservicesDefault = async () => {
    handleBooking
      .GetListServicesSQL(listStringServices)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListVoucherSystems = async () => {
    const params = {
      pageSize: 1000,
      pageIndex: 1,
      serviceIds: -1,
      showAllActiveBooking: true,
    };
    handleBooking
      .GetListVoucher(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListVocher2(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListVoucher = async () => {
    const servicesIdsx = services.map((se) => {
      return se.idSQL;
    });
    const params = {
      pageSize: 1000,
      pageIndex: 1,
      serviceIds: servicesIdsx.join(','),
      showAllActiveBooking: true,
    };
    handleBooking
      .GetListVoucher(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListVocher(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (listStringServices) {
      handleGetListservicesDefault();
    }
  }, [listStringServices]);
  useEffect(() => {
    if (listServices) {
      const newData = listServices.slice(startIndex, endIndex);
      setCurrentData(newData);
    }
  }, [listServices, currentPage]);
  useEffect(() => {
    if (listVoucher2) {
      const newData3 = listVoucher2.slice(startIndex3, endIndex3);
      setCurrentData3(newData3);
    }
  }, [listVoucher2, currentPage3]);
  useEffect(() => {
    if (listVoucher) {
      const newData2 = listVoucher.slice(startIndex2, endIndex2);
      setCurrentData2(newData2);
    }
  }, [listVoucher, currentPage2]);
  const handlePageChange = (page: any) => {
    setCurrentPage(page);
    setEndIndex((page - 1) * pageSize + pageSize);
    setStartIndex((page - 1) * pageSize);
  };
  const handlePageChange2 = (page: any) => {
    setCurrentPage2(page);
    setEndIndex2((page - 1) * pageSize2 + pageSize2);
    setStartIndex2((page - 1) * pageSize2);
  };
  const handlePageChange3 = (page: any) => {
    setCurrentPage3(page);
    setEndIndex3((page - 1) * pageSize3 + pageSize3);
    setStartIndex3((page - 1) * pageSize3);
  };
  const handleGetListStatusBedTime = async () => {
    const params = {
      salon_id: salonId,
      date: dayjs().format('YYYY-MM-DD'),
    };
    handleBooking
      .GetListStatusBed(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListTimeBed(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListStaffReady = async () => {
    const params = {
      salonId: salonId,
      date: dayjs().format('YYYY-MM-DD'),
      time: timeId,
    };
    handleBooking
      .GetListStaffReadyParams(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          const newData = data
            .filter(
              (item) => item.ready === true && item.hasMadeRollCall === true,
            )
            .map((item) => ({
              value: item.id,
              label: item.name,
            }));
          setListStaff(newData);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListBed = async () => {
    const body = {
      salonId: salonId,
      bookingDate: dayjs().format('YYYY-MM-DD'),
      fromTime: timeId,
    };
    handleBooking
      .ListBedInTime(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListBed(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (salonId) {
      handleGetListStatusBedTime();
      handleGetListVoucherSystems();
    }
  }, [salonId]);
  useEffect(() => {
    if (salonId && timeId) {
      handleGetListBed();
      handleGetListStaffReady();
    }
  }, [timeId, salonId]);
  const onFinish = async (values: any) => {
    const serviceIds = services.map((serviceId) => {
      return serviceId.idSQL;
    });
    const promotionIds = voucher.map((voucherId) => {
      return voucherId.id;
    });
    const body = {
      bedId: bedId?.ID,
      bookingDate: dayjs().format('YYYY-MM-DD'),
      serviceIds: serviceIds,
      promotionIds: promotionIds,
      fromTime: timeId,
      salonId: salonId,
      phoneNumber: phone,
      staffServiceId: staffId,
      staffCheckInId: UserInfor?.accountId,
      serviceTime: serviceTimeS,
    };
    console.log(body, 'body');
    handleBooking
      .CreateBooking(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          // thực hiện update đã tư vấn xong
          const newBody = {
            id: data.id,
            status: 'WAIT_FOR_KTV_CONFIRM',
          };
          handleBooking
            .UpdateStatusBooking(newBody)
            .then((response: any) => {
              const { statusCode } = response;
              if (statusCode === 200) {
                notification.success({
                  message: 'Thành công',
                  description:
                    'Xác nhận tạo booking và chuyển tiếp khách cho nhân viên phục vụ',
                });
                navigate(
                  `/checkin/cho-phuc-vu?salonId=${salonId}&pageSize=10&pageIndex=1`,
                );
              } else {
                throw new Error('Error');
              }
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const handleSearchServices = async () => {
    if (!search) {
      setListServices([]);
      setCurrentData([]);
      return;
    }
    setStartIndex(0);
    setEndIndex(pageSize);
    handleBooking
      .SearchServicesByName(search)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListServices(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleAddServices = (data: any) => {
    setVocher([]);
    const indexToRemove = services.findIndex(
      (service: any) => service.idSQL === data.idSQL,
    );

    if (indexToRemove !== -1) {
      // Nếu có, xoá phần tử đó khỏi mảng services
      const updatedServices = [...services];
      updatedServices.splice(indexToRemove, 1);
      if (updatedServices.length === 0) {
        console.log('xoá hết dịch vụ');
        setTotalMoney(0);
      }
      setServices(updatedServices);
      // Sử dụng updatedServices để làm gì đó tiếp theo nếu cần
    } else {
      const newServices = [...services];
      newServices.push(data);
      console.log(newServices, 'new services');
      setServices(newServices);
    }
  };
  const handleAddVoucher = (data: any) => {
    const indexToRemove = voucher.findIndex(
      (vouch: any) => vouch.id === data.id,
    );

    if (indexToRemove !== -1) {
      // Nếu có, xoá phần tử đó khỏi mảng services
      const updatedServices = [...voucher];
      updatedServices.splice(indexToRemove, 1);
      setVocher(updatedServices);
      // Sử dụng updatedServices để làm gì đó tiếp theo nếu cần
    } else {
      const newServices = [...voucher];
      newServices.push(data);
      setVocher(newServices);
    }
  };
  useEffect(() => {
    if (services.length > 0) {
      const totalMinius = services.reduce(
        (sum, item) => sum + parseInt(item.time, 10),
        0,
      );
      setServiceTimeS(Math.ceil(Number(totalMinius) / 60));
      setServiceTime(totalMinius);
      setTotalMoney(
        services.reduce((sum, item) => sum + parseInt(item.cost, 10), 0),
      );
      handleGetListVoucher();
    }
  }, [services]);

  const handleCheckPrice = async () => {
    const serviceIds = services
      .map((sc) => sc.idSQL)
      .filter((id) => id !== null && id !== undefined);
    const promotionIds = voucher
      .map((sc) => sc.id)
      .filter((id) => id !== null && id !== undefined);
    const body = {
      serviceIds: serviceIds,
      promotionIds: promotionIds,
    };
    handleBooking
      .CheckPriceByVoucher(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataPrice(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (services && services?.length > 0) {
      handleCheckPrice();
    } else {
      setDataPrice(null);
    }
  }, [voucher, services]);
  useEffect(() => {
    if (customerInformation) {
      setName(customerInformation?.name);
      if (customerInformation?.birthday) {
        setBirthday(dayjs(customerInformation?.birthday));
      }

      if (customerInformation?.provinceCode) {
        setCity(customerInformation?.provinceCode);
        handleGetDistric(customerInformation?.provinceCode);
      }
      if (customerInformation?.districtCode) {
        setDistric(customerInformation?.districtCode);
        handleGetStress(customerInformation?.districtCode);
      }

      setStress(customerInformation?.wardCode);
      setAddressMore(customerInformation?.addressMore);
      setNote(customerInformation?.note);
    }
  }, [customerInformation]);
  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      // Gọi hàm của bạn ở đây khi người dùng nhấn phím "Enter"
      handleSearchServices();
    }
  };
  return (
    <>
      <Modal
        title={
          customerInformation
            ? 'Sửa thông tin khách hàng'
            : 'Tạo thông tin khách hàng'
        }
        open={isModalOpen}
        onOk={customerInformation ? handleOk : handle2Ok}
        onCancel={handleCancel}
        width={700}
        okText="Xác nhận"
        cancelText="Hủy bỏ"
        destroyOnClose
      >
        <div className="flex items-center mt-5">
          <span className="w-[150px]">Tên khách hàng:</span>
          <Input
            placeholder="Nhập tên khách hàng"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex items-center mt-3">
          <span className="w-[150px]">Ngày sinh:</span>
          <DatePicker
            placeholder="Nhập ngày sinh"
            className="w-full"
            value={birthday}
            onChange={(e) => setBirthday(e)}
            format={'DD/MM/YYYY'}
          />
        </div>
        <div className="flex items-center w-full mt-3">
          <span className="w-[150px]">Địa chỉ:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn Tỉnh/ Thành phố"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={city}
              onChange={(e) => handleChangeCity(e)}
              options={listCity}
            />
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn quận/ huyện"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={distric}
              options={listDistric}
              onChange={(e) => handleChangeDistrict(e)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Địa chỉ chi tiết:</span>
          <div className="flex justify-between w-full">
            <Select
              showSearch
              className="w-[48%]"
              placeholder="Lựa chọn xã/ phường"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              value={stress}
              onChange={(e) => setStress(e)}
              options={listStress}
            />
            <Input
              placeholder="Nhập số nhà"
              className="w-[48%]"
              value={addressMore}
              onChange={(e) => setAddressMore(e.target.value)}
            />
          </div>
        </div>
        <div className="flex items-center mt-3 mb-3">
          <span className="w-[150px]">Ghi chú:</span>
          <div className="flex justify-between w-full">
            <TextArea
              className=""
              placeholder="Ghi chú khách hàng"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
      </Modal>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="px-5 py-5 mt-5 bg-white">
        <div className="flex justify-between">
          <div>THÔNG TIN KHÁCH HÀNG</div>
          {customerInformation ? (
            <Button type="primary" size="small" onClick={showModal}>
              Sửa thông tin
            </Button>
          ) : (
            <Button type="primary" size="small" onClick={showModal}>
              Tạo thông tin
            </Button>
          )}
        </div>
        <div className="flex flex-wrap w-full mt-3">
          <div className="mb-2 mr-10">
            <span className="text-[#777]">Tên khách hàng:</span>
            <span className="ml-2 font-semibold">
              {customerInformation?.name}
            </span>
          </div>
          <div className="mb-2 mr-10">
            <span className="text-[#777]">SĐT:</span>
            <span className="ml-2 font-semibold">{phone}</span>
          </div>
          <div className="mb-2 mr-10">
            <span className="text-[#777]">Số dư Topup:</span>
            <span className="ml-2 font-semibold">
              {customerInformation?.topUpAmount?.toLocaleString('vi-VN')} đ
            </span>
          </div>
          <div className="mb-2 mr-10">
            <span className="text-[#777]">Tổng chi tiêu:</span>
            <span className="ml-2 font-semibold">
              {customerInformation?.totalSpent?.toLocaleString('vi-VN')} đ
            </span>
          </div>
          <div className="mb-2 mr-10">
            <span className="text-[#777]">Tổng nợ:</span>
            <span className="ml-2 font-semibold">
              {customerInformation?.debt?.toLocaleString('vi-VN')} đ
            </span>
          </div>
          <div className="mb-2 mr-10">
            <span className="text-[#777]">Ghi chú:</span>
            <span className="ml-2 font-semibold">
              {customerInformation?.note}
            </span>
          </div>
        </div>
      </div>
      <div className="px-5 py-5 mt-5 bg-white">
        <div className="flex justify-between mb-5">
          <div className="items-center">
            <span className="font-bold text-[18px]">1. LỰA CHỌN KHUNG GIỜ</span>{' '}
            {!timeId ? (
              <span className="ml-10 text-red-600">
                Vui lòng chọn khung giờ
              </span>
            ) : (
              <span className="ml-10 text-green-600">
                Đã chọn khung giờ <strong>{timeId}</strong>
              </span>
            )}
          </div>
          {!step1 ? (
            <LeftOutlined
              onClick={() => setStep1(true)}
              className="text-[20px]"
            />
          ) : (
            <DownOutlined
              onClick={() => setStep1(false)}
              className="text-[20px]"
            />
          )}
        </div>
        {step1 && (
          <>
            {listTimeBed.map((data, index) => (
              <div
                key={index}
                className={
                  compareTimes(data.title)
                    ? 'lg:mx-[20%] flex border-b-[1px] border-[#888] py-3 justify-between items-center bg-[#dedede]'
                    : 'lg:mx-[20%] flex border-b-[1px] border-[#888] py-3 justify-between items-center'
                }
              >
                <div className="border-[1px] border-[#999] rounded-[5px] px-8 py-1">
                  {data.title}
                </div>
                <div
                  className={
                    data.active
                      ? 'bg-green-600 py-1 px-3 rounded-[3px] text-white'
                      : 'bg-red-600 py-1 px-3 rounded-[3px] text-white'
                  }
                >
                  {data.active}/{data.total} Giường trống
                </div>
                <Button
                  type={timeId === data.title ? 'primary' : 'default'}
                  onClick={() => {
                    if (timeId === data.title) {
                      setTimeId(null);
                    } else {
                      setTimeId(data.title);
                    }
                  }}
                  disabled={compareTimes(data.title)}
                >
                  Chọn lịch
                </Button>
              </div>
            ))}
          </>
        )}
      </div>
      <div className="px-5 py-5 mt-5 bg-white">
        <div className="flex justify-between mb-5">
          <div className="items-center">
            <span className="font-bold text-[18px]">
              2. LỰA CHỌN DỊCH VỤ & ƯU ĐÃI
            </span>{' '}
            {!services?.length ? (
              <span className="ml-10 text-red-600">Vui lòng chọn dịch vụ</span>
            ) : (
              <>
                <span className="ml-10 text-green-600">
                  Đã chọn <strong>{services?.length}</strong> dịch vụ
                </span>
                {voucher && voucher?.length > 0 && (
                  <span className="ml-10 text-green-600">
                    Đã chọn <strong>{voucher?.length}</strong> ưu đãi
                  </span>
                )}
              </>
            )}
          </div>
          {!step2 ? (
            <LeftOutlined
              onClick={() => setStep2(true)}
              className="text-[20px]"
            />
          ) : (
            <DownOutlined
              onClick={() => setStep2(false)}
              className="text-[20px]"
            />
          )}
        </div>
        {step2 && (
          <>
            <div className="flex justify-between">
              <div className="w-[45%]">
                <div className="font-bold">Dịch vụ đã chọn</div>
                {services.map((data, index) => (
                  <div
                    key={index}
                    className="flex py-3 rounded-[10px] my-5"
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${
                          convertStringToList(data?.image_url)[0]
                        })`,
                      }}
                      className="cover w-[140px] h-[110px] flex-none rounded-[5px] bg-cover bg-center"
                    ></div>
                    <div className="ml-5">
                      <div className="mb-1">{data.name}</div>
                      <div className="">
                        Giá: {data.cost?.toLocaleString('vi-VN')} đ
                      </div>
                      <div className="my-1">Thời gian: {data.time} phút</div>
                      {services.some(
                        (service: any) => service.idSQL === data.idSQL,
                      ) ? (
                        <Button
                          onClick={() => handleAddServices(data)}
                          className="text-white bg-red-600"
                        >
                          Xoá
                        </Button>
                      ) : (
                        <Button onClick={() => handleAddServices(data)}>
                          Chọn
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className="lg:w-[45%] max-lg:w-[48%]">
                <div className="font-bold">Ưu đãi đã chọn</div>
                {voucher.map((data, index) => (
                  <div
                    className="px-2 py-2 mb-5 rounded-[10px] my-5"
                    key={index}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  >
                    <div className="flex">
                      <div className="flex flex-col justify-between">
                        <div className="mb-2">
                          <div className="">{data?.name}</div>
                          <div className="text-[#888] italic text-[13px]">
                            Ngày hết hạn:{' '}
                            <span className="">
                              {dayjs(data?.endDate).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          {data?.discountType === 1 ? (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm {data?.discountValue} %. Tối đa{' '}
                              {data?.discountMax?.toLocaleString('vi-VN')} đ
                            </div>
                          ) : (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm{' '}
                              {data?.discountValue?.toLocaleString('vi-VN')} đ
                            </div>
                          )}
                        </div>
                        {voucher.some(
                          (dataVoucher: any) => dataVoucher.id === data.id,
                        ) ? (
                          <Button
                            className="w-[90px] bg-red-600 text-white"
                            onClick={() => handleAddVoucher(data)}
                          >
                            Xoá
                          </Button>
                        ) : (
                          <Button
                            className="w-[100px]"
                            onClick={() => handleAddVoucher(data)}
                          >
                            Áp dụng
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="w-[45%]">
                <div className="my-5 font-bold text-center">
                  Bổ sung dịch vụ
                </div>
                <div className="flex">
                  <Input
                    placeholder="Nhập tên dịch vụ"
                    className="h-[40px]"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyDown={handleEnterKeyPress}
                  />
                  <Button
                    type="primary"
                    onClick={handleSearchServices}
                    className="h-[40px]"
                  >
                    Tìm kiếm
                  </Button>
                </div>
                <div>
                  {currentData.map((data, index) => (
                    <div
                      key={index}
                      className="flex border-b-[1px] border-[#999] py-3 px-5 my-3 rounded-[10px]"
                      style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                    >
                      <div
                        style={{
                          backgroundImage: `url(${
                            convertStringToList(data?.image_url)[0]
                          })`,
                        }}
                        className="cover w-[140px] h-[110px] flex-none rounded-[5px] bg-cover bg-center"
                      ></div>
                      <div className="ml-5">
                        <div className="mb-1">{data.name}</div>
                        <div className="">
                          Giá: {data.cost?.toLocaleString('vi-VN')} đ
                        </div>
                        <div className="my-1">Thời gian: {data.time} phút</div>
                        {services.some(
                          (service: any) => service.idSQL === data.idSQL,
                        ) ? (
                          <Button
                            onClick={() => handleAddServices(data)}
                            className="text-white bg-red-600"
                          >
                            Xoá
                          </Button>
                        ) : (
                          <Button onClick={() => handleAddServices(data)}>
                            Chọn
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                  <Pagination
                    current={currentPage}
                    total={listServices.length}
                    pageSize={pageSize}
                    showSizeChanger={false}
                    onChange={handlePageChange}
                    size="default"
                    className="text-center sm:mt-10 sm:pb-10 max-sm:mt-5 max-sm:pb-5"
                  />
                </div>
              </div>
              <div className="w-[45%]">
                <div className="my-5 font-bold text-center ">
                  Ưu đãi của dịch vụ
                </div>
                {currentData2.map((data, index) => (
                  <div
                    className="px-2 py-2 mb-5 rounded-[10px] my-5"
                    key={index}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col justify-between">
                        <div className="mb-2">
                          <div className="">{data?.name}</div>
                          <div className="text-[#888] italic text-[13px]">
                            Ngày hết hạn:{' '}
                            <span className="">
                              {dayjs(data?.endDate).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          {data?.discountType === 1 ? (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm {data?.discountValue} %. Tối đa{' '}
                              {data?.discountMax?.toLocaleString('vi-VN')} đ
                            </div>
                          ) : (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm{' '}
                              {data?.discountValue?.toLocaleString('vi-VN')} đ
                            </div>
                          )}
                        </div>
                      </div>
                      {voucher.some(
                        (dataVoucher: any) => dataVoucher.id === data.id,
                      ) ? (
                        <Button
                          className="w-[100px] bg-red-600 text-white"
                          onClick={() => handleAddVoucher(data)}
                        >
                          Loại bỏ
                        </Button>
                      ) : (
                        <Button
                          className="w-[150px]"
                          onClick={() => handleAddVoucher(data)}
                        >
                          Áp dụng
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <Pagination
                  current={currentPage2}
                  total={listVoucher?.length}
                  pageSize={pageSize2}
                  showSizeChanger={false}
                  onChange={handlePageChange2}
                  size="default"
                  className="text-center sm:mt-10 sm:pb-10 max-sm:mt-5 max-sm:pb-5"
                />
                <div className="my-5 font-bold text-center ">
                  Ưu đãi của hệ thống
                </div>
                {currentData3.map((data, index) => (
                  <div
                    className="px-2 py-2 mb-5 rounded-[10px] my-5"
                    key={index}
                    style={{ boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px' }}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex flex-col justify-between">
                        <div className="mb-2">
                          <div className="">{data?.name}</div>
                          <div className="text-[#888] italic text-[13px]">
                            Ngày hết hạn:{' '}
                            <span className="">
                              {dayjs(data?.endDate).format('DD/MM/YYYY')}
                            </span>
                          </div>
                          {data?.discountType === 1 ? (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm {data?.discountValue} %. Tối đa{' '}
                              {data?.discountMax?.toLocaleString('vi-VN')} đ
                            </div>
                          ) : (
                            <div className="text-[#6D8A63] font-bold text-[13px]">
                              Giảm{' '}
                              {data?.discountValue?.toLocaleString('vi-VN')} đ
                            </div>
                          )}
                        </div>
                      </div>
                      {voucher.some(
                        (dataVoucher: any) => dataVoucher.id === data.id,
                      ) ? (
                        <Button
                          className="w-[100px] bg-red-600 text-white"
                          onClick={() => handleAddVoucher(data)}
                        >
                          Loại bỏ
                        </Button>
                      ) : (
                        <Button
                          className="w-[150px]"
                          onClick={() => handleAddVoucher(data)}
                        >
                          Áp dụng
                        </Button>
                      )}
                    </div>
                  </div>
                ))}
                <Pagination
                  current={currentPage3}
                  total={listVoucher2?.length}
                  pageSize={pageSize3}
                  showSizeChanger={false}
                  onChange={handlePageChange3}
                  size="default"
                  className="text-center sm:mt-10 sm:pb-10 max-sm:mt-5 max-sm:pb-5"
                />
              </div>
            </div>
          </>
        )}
        {dataPrice ? (
          <div className="border-[1px] px-[10%] py-2 lg:mx-[20%] max-lg:mx-[10%]">
            <div className="flex">
              <div className="w-[200px]">Tổng giá dịch vụ:</div>
              <span className="ml-5 text-red-600">
                {dataPrice?.totalMoney?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px]">Tổng giảm giá:</div>
              <span className="ml-5 text-green-600">
                {dataPrice?.totalDiscount?.toLocaleString('vi-VN')} đ{' '}
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px]">Tổng hoá đơn:</div>
              <span className="ml-5 font-bold">
                {dataPrice?.totalBill?.toLocaleString('vi-VN')} đ
              </span>
            </div>
          </div>
        ) : (
          <div className="border-[1px] px-[10%] py-2 lg:mx-[20%] max-lg:mx-[10%]">
            <div className="flex">
              <div className="w-[200px]">Tổng giá dịch vụ:</div>
              <span className="ml-5 text-red-600">
                {totalMoney?.toLocaleString('vi-VN')} đ
              </span>
            </div>
            <div className="flex">
              <div className="w-[200px]">Tổng giảm giá:</div>
              <span className="ml-5 text-green-600">0 đ </span>
            </div>
            <div className="flex">
              <div className="w-[200px]">Tổng hoá đơn:</div>
              <span className="ml-5 font-bold">
                {totalMoney?.toLocaleString('vi-VN')} đ
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="px-5 py-5 mt-5 bg-white">
        <div className="flex justify-between mb-5">
          <div className="items-center">
            <span className="font-bold text-[18px]">3. LỰA CHỌN GIƯỜNG</span>{' '}
            {!bedId ? (
              <span className="ml-10 text-red-600">Vui lòng chọn giường</span>
            ) : (
              <span className="ml-10 text-green-600">
                Đã chọn <strong> Giường {bedId?.NAME}</strong>
              </span>
            )}
          </div>
        </div>
        <div className="flex lg:mx-[20%] flex-wrap">
          {listBed.map((item, index) => (
            <Button
              className="mb-3 mr-2"
              key={index}
              onClick={() => {
                if (bedId?.ID === item.ID) {
                  setBedId(null);
                } else {
                  setBedId(item);
                }
              }}
              type={bedId?.ID === item.ID ? 'primary' : 'default'}
              disabled={!item.status}
            >
              Giường {item.NAME}
            </Button>
          ))}
        </div>
      </div>
      <div className="px-5 py-5 mt-5 bg-white">
        <div className="flex justify-between mb-5">
          <div className="items-start lg:flex">
            <span className="font-bold text-[18px]">
              4. LỰA CHỌN NHÂN VIÊN PHỤC VỤ
            </span>
            <div>
              <Select
                className="lg:ml-10 lg:w-[300px] max-lg:mt-5 max-lg:w-full"
                showSearch
                placeholder="Lựa chọn nhân viên phục vụ"
                options={listStaff}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(
                    removeVietnamese(input),
                  )
                }
                value={staffId}
                onChange={(value) => setStaffId(value)}
              ></Select>
              {!staffId ? (
                <div className="mt-2 text-red-600 lg:ml-10 max-lg:mt-2">
                  Vui lòng chọn nhân viên phục vụ
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 py-5 mt-5 bg-white">
        <div className="items-center">
          <span className="font-bold text-[18px]">5. XÁC NHẬN</span>
        </div>
        <div className="lg:mx-[20%] max-lg:mx-[5%] max-lg:mt-5">
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">
              Số điện thoại khách hàng:
            </div>
            <div className="font-bold">{phone}</div>
          </div>
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">Khung giờ:</div>
            <div className="font-bold">{timeId}</div>
          </div>
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">Dịch vụ:</div>
            <div className="">
              {services.map((service, index) => (
                <div>{service.name}</div>
              ))}
            </div>
          </div>
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">Tổng thời gian:</div>
            <div className="font-bold">{serviceTime} phút</div>
          </div>
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">Tổng tiền:</div>
            <div className="font-bold">{totalMoney} đ</div>
          </div>
          <div className="flex mb-2">
            <div className="text-[#888] w-[200px]">Giường:</div>
            <div className="font-bold">Giường {bedId?.NAME}</div>
          </div>
          <div className="flex items-center mb-4">
            <div className="text-[#888] w-[200px]">Nhân viên phục vụ:</div>
            <div className="font-bold">
              <Select
                className="w-[300px]"
                showSearch
                placeholder="Lựa chọn nhân viên phục vụ"
                options={listStaff}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(
                    removeVietnamese(input),
                  )
                }
                value={staffId}
                onChange={(value) => setStaffId(value)}
              ></Select>
            </div>
          </div>
          <div className="flex items-center mb-2">
            <div className="text-[#888] w-[200px]">Thời gian dự kiến:</div>
            <InputNumber
              placeholder="Thời gian ước chừng"
              className="w-[200px] mr-1"
              value={serviceTimeS}
              onChange={(value) => setServiceTimeS(value)}
              min={0}
              formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
            />
            <span>giờ</span>
          </div>
          <Button
            type={
              !phone ||
              !staffId ||
              !bedId ||
              services.length === 0 ||
              !timeId ||
              !salonId
                ? 'default'
                : 'primary'
            }
            className="w-full mt-10 mb-20"
            onClick={onFinish}
            disabled={
              !phone ||
              !staffId ||
              !bedId ||
              services.length === 0 ||
              !timeId ||
              !salonId
                ? true
                : false
            }
          >
            XÁC NHẬN HOÀN TẤT TƯ VẤN & ĐẶT LỊCH MỚI CHO KHÁCH
          </Button>
        </div>
      </div>
    </>
  );
};
