import React, { useState, useEffect } from 'react';
import { DownCircleOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Checkbox, Select, notification } from 'antd';
import { handleStaff, handleBooking, handleCustomer } from '@services';

const VoucherCustomerCheckout = ({
  dataVoucher,
  setDataVoucher,
  listStringServices,
  dataPrice,
  setDataPrice,
}: any) => {
  const [collap, setCollap] = useState(true);
  const [listVoucher1, setListVocher1] = useState([]);
  const [listVoucher2, setListVocher2] = useState([]);
  console.log(listStringServices, 'listStringServices');
  const handleCheckPrice = async () => {
    const promotionIds = dataVoucher
      .map((sc) => sc.id)
      .filter((id) => id !== null && id !== undefined);
    const body = {
      serviceIds: listStringServices ? listStringServices?.split(',') : [],
      promotionIds: promotionIds,
    };
    handleBooking
      .CheckPriceByVoucher(body)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setDataPrice(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListVoucherSystems = async () => {
    const params = {
      pageSize: 1000,
      pageIndex: 1,
      serviceIds: -1,
      showAllActiveBooking: true,
    };
    handleBooking
      .GetListVoucher(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListVocher2(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListVoucher = async () => {
    const params = {
      pageSize: 1000,
      pageIndex: 1,
      serviceIds: listStringServices,
      showAllActiveBooking: true,
    };
    handleBooking
      .GetListVoucher(params)
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListVocher1(data);
        } else {
          throw new Error('Error');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetListVoucherSystems();
  }, []);
  useEffect(() => {
    handleGetListVoucher();
  }, [listStringServices]);
  console.log(dataVoucher, 'dataVoucher');
  const handleAddVoucher = (data: any) => {
    const indexToRemove = dataVoucher.findIndex((vo: any) => vo.id === data.id);

    if (indexToRemove !== -1) {
      const updatedServices = [...dataVoucher];
      updatedServices.splice(indexToRemove, 1);
      setDataVoucher(updatedServices);
    } else {
      const newServices = [...dataVoucher];
      newServices.push(data);
      setDataVoucher(newServices);
    }
  };
  useEffect(() => {
    if (listStringServices) {
      handleCheckPrice();
    }
  }, [dataVoucher, listStringServices]);
  return (
    <div className="w-full px-5 py-3 mt-5 bg-white">
      <div className="flex justify-between">
        <h3 className="font-semibold">ƯU ĐÃI ĐÃ ÁP DỤNG</h3>
        {collap ? (
          <DownCircleOutlined onClick={() => setCollap(false)} />
        ) : (
          <LeftCircleOutlined onClick={() => setCollap(true)} />
        )}
      </div>
      {collap && (
        <>
          {/* <div className="flex mt-3">
            <Input placeholder="Nhập mã giảm giá" className="rounded-none" />
            <Button type="primary" className="rounded-none">
              ÁP DỤNG
            </Button>
          </div>
          <div className="mt-2 mb-2">ƯU ĐÃI CHO KHÁCH</div> */}
          <div className="mt-3">
            {dataVoucher?.length > 0 ? (
              <>
                {dataVoucher.map((data, index) => (
                  <div
                    className={
                      dataVoucher &&
                      dataVoucher?.some(
                        (voucher: any) => voucher.id === data.id,
                      )
                        ? 'bg-[#1677ff25] rounded py-[5px] px-[5px] mb-1'
                        : 'bg-[#fff] rounded py-[5px] px-[5px] mb-1 shadow-lg'
                    }
                    key={index}
                  >
                    <div className="flex items-start">
                      <Checkbox
                        className="w-[60px] mt-[5px]"
                        checked={
                          dataVoucher &&
                          dataVoucher?.some(
                            (voucher: any) => voucher.id === data.id,
                          )
                        }
                        onClick={() => handleAddVoucher(data)}
                      />
                      <div>
                        <div>{data?.name}</div>
                        {data?.discountType === 1 ? (
                          <div>
                            Giảm giá: {data?.discountValue} %. Tối đa{' '}
                            {data?.discountMax?.toLocaleString('vi-VN')} đ
                          </div>
                        ) : (
                          <div>
                            Giảm giá:{' '}
                            {data?.discountValue?.toLocaleString('vi-VN')} đ
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                      <div className="text-green-600 text-[12px]">
                        Ưu đãi hợp lệ
                      </div>
                      {/* <div className="text-[#1677FF] italic text-[12px]">
                Xem đk áp dụng
              </div> */}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="text-[#888] italic">
                Khách hàng chưa áp dụng ưu đãi nào
              </div>
            )}
            {/* <div className="bg-[#8888881b] rounded py-[5px] px-[5px] mt-2">
              <div className="flex items-start">
                <Checkbox className="w-[60px] mt-[5px]" disabled />
                <div className="text-[#888]">
                  (2766) All khách hàng đến salon - Bill 200k - Tặng khách áo
                  mưa hoặc mũ bảo hiểm [SC]
                </div>
              </div>
              <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                <div className="text-red-600 text-[12px]">
                  Ưu đãi không hợp lệ
                </div>
                <div className="text-[#1677FF] italic text-[12px]">
                  Xem đk áp dụng
                </div>
              </div>
            </div> */}
          </div>
          <h3 className="mt-5 mb-2 font-semibold">ƯU ĐÃI CỦA DỊCH VỤ</h3>
          {listVoucher1?.length > 0 ? (
            <>
              {listVoucher1.map((data, index) => (
                <div
                  className={
                    dataVoucher &&
                    dataVoucher?.some((voucher: any) => voucher.id === data.id)
                      ? 'bg-[#1677ff25] rounded py-[5px] px-[5px] mb-1'
                      : 'bg-[#fff] rounded py-[5px] px-[5px] mb-1 shadow-lg'
                  }
                  key={index}
                >
                  <div className="flex items-start">
                    <Checkbox
                      className="w-[60px] mt-[5px]"
                      checked={
                        dataVoucher &&
                        dataVoucher?.some(
                          (voucher: any) => voucher.id === data.id,
                        )
                      }
                      onClick={() => handleAddVoucher(data)}
                    />
                    <div>
                      <div>{data?.name}</div>
                      {data?.discountType === 1 ? (
                        <div>
                          Giảm giá: {data?.discountValue} %. Tối đa{' '}
                          {data?.discountMax?.toLocaleString('vi-VN')} đ
                        </div>
                      ) : (
                        <div>
                          Giảm giá:{' '}
                          {data?.discountValue?.toLocaleString('vi-VN')} đ
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                    <div className="text-green-600 text-[12px]">
                      Ưu đãi hợp lệ
                    </div>
                    {/* <div className="text-[#1677FF] italic text-[12px]">
                Xem đk áp dụng
              </div> */}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="text-[#888] italic ">
              Dịch vụ này chưa có ưu đãi nào
            </div>
          )}
          <h3 className="mt-5 mb-2 font-semibold">ƯU ĐÃI CỦA HỆ THỐNG</h3>
          {listVoucher2?.length > 0 ? (
            <>
              {listVoucher2.map((data, index) => (
                <div
                  className={
                    dataVoucher &&
                    dataVoucher?.some((voucher: any) => voucher.id === data.id)
                      ? 'bg-[#1677ff25] rounded py-[5px] px-[5px] mb-1'
                      : 'bg-[#fff] rounded py-[5px] px-[5px] mb-1 shadow-lg'
                  }
                  key={index}
                >
                  <div className="flex items-start">
                    <Checkbox
                      className="w-[60px] mt-[5px]"
                      checked={
                        dataVoucher &&
                        dataVoucher?.some(
                          (voucher: any) => voucher.id === data.id,
                        )
                      }
                      onClick={() => handleAddVoucher(data)}
                    />
                    <div>
                      <div>{data?.name}</div>
                      {data?.discountType === 1 ? (
                        <div>
                          Giảm giá: {data?.discountValue} %. Tối đa{' '}
                          {data?.discountMax?.toLocaleString('vi-VN')} đ
                        </div>
                      ) : (
                        <div>
                          Giảm giá:{' '}
                          {data?.discountValue?.toLocaleString('vi-VN')} đ
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between ml-[27px] mt-[4px] mr-3">
                    <div className="text-green-600 text-[12px]">
                      Ưu đãi hợp lệ
                    </div>
                    {/* <div className="text-[#1677FF] italic text-[12px]">
                  Xem đk áp dụng
                </div> */}
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="text-[#888] italic ">
              Khách hàng chưa áp dụng ưu đãi nào
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VoucherCustomerCheckout;
