import React from 'react';
import { Button, Dropdown, Select, InputNumber, Switch } from 'antd';
import { DataType, ColumnsRendererProps } from './typeTabel';
import { Link } from 'react-router-dom';
import {
  ShoppingOutlined,
  UserOutlined,
  CalendarOutlined,
  CloseSquareOutlined,
  CheckSquareOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { useParams, useNavigate } from 'react-router-dom';
import { formatDate } from '@utils/Functions/convertDate';
import dayjs from 'dayjs';
import { convertTimeFormatV2 } from '@utils/Functions/convertDate';
import { CheckStatusToTitle } from '@utils/Functions/statiscialConvert';
export const ColumnsListSalon = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên Salon',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[120px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Mã Salon',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Miền',
      key: 'rsm',
      render: (_, record) => <>{record.rsm?.label}</>,
    },
    {
      title: 'Vùng',
      key: 'asm',
      render: (_, record) => <>{record.asm?.label}</>,
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
      width: 260,
    },
    {
      title: 'Trạng thái',
      key: 'active',
      render: (_, record) => <>{record.active ? 'Hoạt động' : 'Đóng cửa'}</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          {/* <Button onClick={() => handleDelete(record)}>Xóa</Button> */}
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListTimekeepingAwait = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã NV',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[120px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Thời gian',
      key: 'rsm',
      render: (_, record) => <>{record.rsm?.label}</>,
    },
    {
      title: 'Ca 1',
      key: 'asm',
      render: (_, record) => <>{record.asm?.label}</>,
    },
    {
      title: 'Ca 2',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ca 3',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          {/* <Button onClick={() => handleDelete(record)}>Xóa</Button> */}
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListCategory = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Hình ảnh',
      key: 'clinicCode',
      render: (_, record) => (
        <>
          <img
            src={record.imageUrl}
            alt="image"
            className="w-[30px] h-[30px]"
          />
        </>
      ),
    },
    {
      title: 'Tên danh mục',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[200px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Hiện Booking',
      key: 'address',
      render: (_, record) => (
        <>
          {record.activeBooking ? (
            <span className="text-green-600">Hiện</span>
          ) : (
            <span className="text-red-600">Ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'address',
      render: (_, record) => (
        <>
          {record.active ? (
            <span className="text-green-600">Hiển thị</span>
          ) : (
            <span className="text-red-600">Ẩn</span>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};
export const ColumnsListInventory = () => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Hình ảnh',
      key: 'clinicCode',
      render: (_, record) => (
        <>
          <img
            src={record.imageUrl}
            alt="image"
            className="w-[30px] h-[30px]"
          />
        </>
      ),
    },
    {
      title: 'Tên danh mục',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[200px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Hiện Booking',
      key: 'address',
      render: (_, record) => (
        <>
          {record.activeBooking ? (
            <span className="text-green-600">Hiện</span>
          ) : (
            <span className="text-red-600">Ẩn</span>
          )}
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListServices = ({
  handleEdit,
  hanldeChangeActive,
  hanldeChangeBooking,
  handleDelete,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã dịch vụ',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên dịch vụ',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[200px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Giá',
      key: 'cost',
      render: (_, record) => (
        <>
          <span>{record.cost ? record.cost?.toLocaleString('de-DE') : ''}</span>
        </>
      ),
    },
    {
      title: 'Thời gian',
      key: 'serviceTime',
      render: (_, record) => (
        <>
          <span>{record.serviceTime} phút</span>
        </>
      ),
    },
    {
      title: 'Hiện Booking',
      key: 'activeBooking',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            onChange={() => {
              const body = {
                id: record.id,
                active: record.active,
                activeBooking: !record.activeBooking,
              };
              hanldeChangeBooking(body);
            }}
            value={record.activeBooking}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'active',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            onChange={() => {
              const body = {
                id: record.id,
                active: !record.active,
                activeBooking: record.activeBooking,
              };
              hanldeChangeActive(body);
            }}
            value={record.active}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button
            type="primary"
            style={{ marginRight: 8 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsListVoucher = ({
  handleEdit,
  handleDelete,
  hanldeChangeBooking,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên ưu đãi',
      key: 'name',
      render: (_, record) => (
        <span className="break-all whitespace-pre-line max-w-[200px]">
          {record?.name}
        </span>
      ),
    },
    {
      title: 'Ngày bắt đầu',
      key: 'startDate',
      render: (_, record) => <>{formatDate(record.startDate)}</>,
    },
    {
      title: 'Ngày kết thúc',
      key: 'endDate',
      render: (_, record) => <>{formatDate(record.endDate)}</>,
    },
    {
      title: 'Giảm giá',
      key: 'discountValue',
      render: (_, record) => (
        <>
          {record.discountType === 1
            ? record.discountValue + ' %'
            : record.discountValue.toLocaleString('de-DE') + ' đ'}
        </>
      ),
      width: 120,
    },
    {
      title: 'Hiện Booking',
      key: 'activeBooking',
      render: (_, record) => (
        <>
          <Switch
            checkedChildren="Hiện"
            unCheckedChildren="Ẩn"
            onChange={() => {
              const body = {
                id: record.id,
                active: record.active,
                activeBooking: !record.activeBooking,
              };
              hanldeChangeBooking(body);
            }}
            value={record.activeBooking}
          ></Switch>
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </div>
      ),
    },
  ];

  return columns;
};
export const ColumnsListSendVoucher = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'clinicCode',
      key: 'clinicCode',
    },
    {
      title: 'SĐT',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Giới tính',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Email',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          {/* <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button> */}
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};

export const ColumnsListStaff = ({
  handleEdit,
  handleDelete,
  handleChangeSalon,
  edit,
}) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Salon',
      dataIndex: 'salon',
      key: 'salon',
    },
    {
      title: 'Vùng',
      dataIndex: 'region',
      key: 'region',
    },
    {
      title: 'Hoạt động',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: any) => (
        <>
          {record?.position === 'Kỹ thuật viên' && record?.salon_id && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => handleChangeSalon(record)}
            >
              Chuyển Salon
            </Button>
          )}
          {edit && (
            <Button
              style={{ marginRight: 10 }}
              onClick={() => handleEdit(record)}
              type="primary"
            >
              Sửa
            </Button>
          )}

          {/* <Button onClick={() => handleDelete(record)}>Xóa</Button> */}
        </>
      ),
    },
  ];

  return columns;
};
export const ColumnsListChangeStaff = () => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'staff_code',
      key: 'staff_code',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Ngày biến động',
      key: 'turnover_date',
      render: (item, record) => {
        return <span>{dayjs(record?.turnover_date).format('DD/MM/YYYY')}</span>;
      },
    },
    {
      title: 'Salon cũ',
      dataIndex: 'old_salon',
      key: 'old_salon',
    },
    {
      title: 'Salon mới',
      dataIndex: 'new_salon',
      key: 'new_salon',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];

  return columns;
};
export const ColumnsListCustomer = ({ handleEdit, handleDebt }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Mức chi tiêu trung bình',
      key: 'none',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.averageSpending &&
            record?.averageSpending?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Số dư topup',
      key: 'none',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.topUpAmount &&
            record?.topUpAmount?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Tổng nợ hiện tại',
      key: 'none',
      render: (_, record: any) => (
        <div>
          {record && record?.debt && record?.debt?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleDebt(record)}
          >
            Xem công nợ
          </Button>
          <Button onClick={() => handleEdit(record)} type="primary">
            Xem chi tiết
          </Button>
        </div>
      ),
    },
  ];

  return columns;
};
export const ColumnsListTopupCustomer = ({ handleEdit, handleDebt }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Số dư Topup',
      key: 'amount',
      render: (_, record: any) => (
        <div>
          {record && record?.amount && record?.amount?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)} type="default">
            Nạp topup
          </Button>
        </div>
      ),
    },
  ];

  return columns;
};
export const ColumnsListDebtCustomer = ({ handleEditDebt, listServices }) => {
  const columns = [
    {
      title: 'Ngày biến động',
      dataIndex: 'updatedDate',
      key: 'updatedDate',
    },
    {
      title: 'Mã hoá đơn',
      dataIndex: 'bill',
      key: 'code',
    },
    {
      title: 'Tên dịch vụ sử dụng',
      key: 'serviceIds',
      render: (_, record: any) => (
        <div>
          {record && record?.serviceIds && (
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              value={record?.serviceIds}
              options={listServices}
              disabled
              className="w-[400px]"
            />
          )}
        </div>
      ),
      width: 300,
    },
    {
      title: 'Tổng Bill',
      key: 'phone',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.totalBill &&
            record?.totalBill?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Đã trả',
      key: 'address',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.totalPrice &&
            record?.totalPrice?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Còn nợ',
      key: 'note',
      render: (_, record: any) => (
        <div>
          {record && record?.debt && record?.debt?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'phone',
    },
  ];

  return columns;
};
export const ColumnsListDebtCustomerModal = ({
  handleEditDebt,
  listServices,
}) => {
  const columns = [
    {
      title: 'Ngày biến động',
      dataIndex: 'updatedDate',
      key: 'updatedDate',
    },
    {
      title: 'Mã hoá đơn',
      dataIndex: 'bill',
      key: 'code',
    },
    {
      title: 'Tên dịch vụ sử dụng',
      key: 'serviceIds',
      render: (_, record: any) => (
        <div>
          {record && record?.serviceIds && (
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder="Please select"
              value={record?.serviceIds}
              options={listServices}
              disabled
              className="w-[400px]"
            />
          )}
        </div>
      ),
    },
    {
      title: 'Tổng Bill',
      key: 'phone',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.totalBill &&
            record?.totalBill?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Đã trả',
      key: 'address',
      render: (_, record: any) => (
        <div>
          {record &&
            record?.totalPrice &&
            record?.totalPrice?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Còn nợ',
      key: 'note',
      render: (_, record: any) => (
        <div>
          {record && record?.debt && record?.debt?.toLocaleString('vi-VN')}
        </div>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: any) => (
        <div className="flex">
          <InputNumber
            value={record.price}
            onChange={(e) => handleEditDebt(e, record)}
            className="w-[100px]"
            min={0}
            formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
          ></InputNumber>
        </div>
      ),
    },
  ];

  return columns;
};

export const ColumnsStatisticalBooking = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: '',
      dataIndex: 'salonName',
      key: 'salonName',
      fixed: 'left',
      width: 100,
    },
    {
      title: '08:30',
      key: 'fromTime1',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[0]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[0]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '09:30',
      key: 'fromTime2',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[1]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[1]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '10:30',
      key: 'fromTime3',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[2]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[2]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '11:30',
      key: 'fromTime4',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[3]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[3]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '12:30',
      key: 'fromTime5',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[4]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[4]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '13:30',
      key: 'fromTime6',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[5]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[5]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '14:30',
      key: 'fromTime7',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[6]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[6]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '15:30',
      key: 'fromTime8',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[7]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[7]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '16:30',
      key: 'fromTime9',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[8]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[8]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '17:30',
      key: 'fromTime10',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[9]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[9]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '18:30',
      key: 'fromTime11',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[10]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[10]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '19:30',
      key: 'fromTime12',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[11]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[11]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
    {
      title: '20:30',
      key: 'fromTime13',
      render: (_, record: any) => (
        <div className="bg-[#1677FF30] px-3 py-1 flex flex-col justify-center">
          <div className="flex items-center">
            <CalendarOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[12]?.countBooking}</div>
          </div>
          <div className="flex items-center">
            <UserOutlined className="relative top-[-1px] mr-[3px] text-[#888]" />
            <div>{record?.overViews[12]?.countKtv}</div>
          </div>
        </div>
      ),
      width: 70,
    },
  ];

  return columns;
};

export const ColumnsListBooking = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Trạng thái',
      key: 'status',
      render: (_, record: any) => (
        <span className="flex">{CheckStatusToTitle(record.status)}</span>
      ),
    },
    // {
    //   title: 'Thao tác',
    //   key: 'action',
    //   render: (_, record: DataType) => (
    //     <div className="flex">
    //       <Button onClick={() => handleEdit(record)}>Khách đã đến</Button>
    //     </div>
    //   ),
    //   fixed: 'right',
    //   width: 180,
    // },
  ];

  return columns;
};
export const ColumnsListCheckin = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)}>Khách đã đến</Button>
        </div>
      ),
      fixed: 'right',
      width: 180,
    },
  ];

  return columns;
};
export const ColumnsListCheckinSalon = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)}>Tư vấn</Button>
        </div>
      ),
      fixed: 'right',
      width: 140,
    },
  ];

  return columns;
};
export const ColumnsListCheckinChoPhucVu = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)}>Nhận phục vụ</Button>
        </div>
      ),
      fixed: 'right',
      width: 140,
    },
  ];

  return columns;
};
export const ColumnsListCheckinDangPhucVu = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)}>Phục vụ xong</Button>
        </div>
      ),
      fixed: 'right',
      width: 140,
    },
  ];

  return columns;
};
export const ColumnsListCheckout = ({ handleEdit, handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
      fixed: 'left',
      width: 60,
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      fixed: 'left',
      width: 120,
    },
    {
      title: 'Khung giờ',
      key: 'fromTime',
      render: (_, record: any) => (
        <span className="flex">{convertTimeFormatV2(record?.fromTime)}</span>
      ),
      fixed: 'left',
      width: 100,
    },
    {
      title: 'Vùng',
      dataIndex: 'asmName',
      key: 'asmName',
    },
    {
      title: 'Salon',
      dataIndex: 'salonName',
      key: 'salonName',
    },
    {
      title: 'Khách hàng',
      key: 'name',
      render: (_, record: any) => (
        <span className="flex">{record.customerInformation?.name}</span>
      ),
    },
    {
      title: 'Ngày',
      key: 'bookingDate',
      render: (_, record: any) => (
        <span className="flex">
          {dayjs(record.bookingDate).format('DD/MM/YYYY')}
        </span>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <div className="flex">
          <Button onClick={() => handleEdit(record)}>Checkout</Button>
        </div>
      ),
      fixed: 'right',
      width: 140,
    },
  ];

  return columns;
};
export const ColumnsListBill = ({ handleEdit, handleView }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customerName',
      key: 'customerName',
    },
    {
      title: 'SĐT',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Ngày tạo bill',
      dataIndex: 'createdDate',
      key: 'createdDate',
    },
    {
      title: 'Tổng Bill',
      key: 'totalBill',
      render: (_, record: any) => (
        <>{record.totalBill?.toLocaleString('vi-VN')}</>
      ),
    },
    {
      title: 'Tổng thanh toán',
      key: 'totalPrice',
      render: (_, record: any) => (
        <>
          {(Number(record.totalBill) - Number(record?.debt))?.toLocaleString(
            'vi-VN',
          )}
        </>
      ),
    },
    {
      title: 'Trạng thái thanh toán',
      key: 'status',
      render: (_, record: any) => (
        <>
          {record.status === 'PENDING_PAYMENT' ? (
            <>
              {record.debt === 0 ? (
                <span className="text-yellow-600">Đợi thanh toán</span>
              ) : (
                <span className="text-yellow-600">Ghi nợ</span>
              )}
            </>
          ) : (
            <>
              {record.status === 'PENDING_PAYMENT_WITH_DEBT' ? (
                <span className="text-yellow-600">Đợi thanh toán hết nợ</span>
              ) : (
                <>
                  {record.status === 'PENDING_OTP' ? (
                    <span className="">Đợi xác thực OTP</span>
                  ) : (
                    <>
                      {record.status === 'PAID' ? (
                        <span className="text-green-600">Đã thanh toán</span>
                      ) : (
                        <span className="text-red-600">Lỗi thanh toán</span>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: any) => (
        <div className="flex">
          <Button onClick={() => handleView(record)}>Xem</Button>
          {(record.status === 'PENDING_PAYMENT' ||
            record.status === 'PENDING_OTP') &&
            record.debt === 0 && (
              <Button
                onClick={() => handleEdit(record)}
                type="primary"
                className="ml-2"
              >
                Thanh Toán
              </Button>
            )}
        </div>
      ),
    },
  ];

  return columns;
};
export const ColumnsListVoucherCustomer = () => {
  const columns = [
    {
      title: 'Tên ưu đãi',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mã ưu đãi',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'managerName',
      key: 'managerName',
    },
    {
      title: 'SL sử dụng',
      dataIndex: 'phone',
      key: 'phone',
    },
  ];

  return columns;
};

export const ColumnsListTimeKeeping = ({ handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Ca 1',
      key: 'action',
      render: (_, record: any) => (
        <>
          {record?.shift1 ? (
            <div className="flex">
              <CheckSquareOutlined className="text-[20px] mr-[5px] text-green-600" />
              <Button size="small" onClick={() => handleDelete(record, 'A')}>
                <DeleteOutlined className="relative top-[-2px]" />
              </Button>
            </div>
          ) : (
            <CloseSquareOutlined className="text-[20px] mr-2 text-red-600" />
          )}
        </>
      ),
    },
    {
      title: 'Ca 2',
      key: 'action',
      render: (_, record: any) => (
        <>
          {record?.shift2 ? (
            <div className="flex">
              <CheckSquareOutlined className="text-[20px] mr-[5px] text-green-600" />
              <Button size="small" onClick={() => handleDelete(record, 'B')}>
                <DeleteOutlined className="relative top-[-2px]" />
              </Button>
            </div>
          ) : (
            <CloseSquareOutlined className="text-[20px] mr-2 text-red-600" />
          )}
        </>
      ),
    },
    {
      title: 'Ca 3',
      key: 'action',
      render: (_, record: any) => (
        <>
          {record?.shift3 ? (
            <div className="flex">
              <CheckSquareOutlined className="text-[20px] mr-[5px] text-green-600" />
              <Button size="small" onClick={() => handleDelete(record, 'C')}>
                <DeleteOutlined className="relative top-[-2px]" />
              </Button>
            </div>
          ) : (
            <CloseSquareOutlined className="text-[20px] mr-2 text-red-600" />
          )}
        </>
      ),
    },
  ];

  return columns;
};
export const ColumnsListSalary = () => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'staff_code',
      key: 'staff_code',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Bậc kỹ năng',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Lương cơ bản',
      key: 'base_salary',
      render: (_, record) => <>{record.base_salary?.toLocaleString('vi-VN')}</>,
    },
    {
      title: 'Lương dịch vụ',
      key: 'service_bonus',
      render: (_, record) => (
        <>{record.service_bonus?.toLocaleString('vi-VN')}</>
      ),
    },
    {
      title: 'Lương thưởng',
      key: 'extra_bonus',
      render: (_, record) => <>{record.extra_bonus?.toLocaleString('vi-VN')}</>,
    },
    {
      title: 'Tổng lương',
      key: 'total',
      render: (_, record) => <>{record.total?.toLocaleString('vi-VN')}</>,
    },
  ];

  return columns;
};
export const ColumnsListConfigSalary = ({ handleEdit }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Vị trí',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Tên bậc kỹ năng',
      dataIndex: 'level',
      key: 'level',
    },
    {
      title: 'Lương cơ bản',
      key: 'base_salary',
      render: (_, record) => <>{record.base_salary?.toLocaleString('vi-VN')}</>,
    },
    {
      title: 'Thời gian làm việc',
      key: 'base_salary',
      render: (_, record) => <>{record.total_hours} giờ</>,
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
        </>
      ),
    },
  ];

  return columns;
};
export const ColumnsListBonusSalary = ({ handleDelete }) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Mã nhân viên',
      dataIndex: 'staff_code',
      key: 'staff_code',
    },
    {
      title: 'ID nhân viên',
      dataIndex: 'staff_id',
      key: 'staff_id',
    },
    {
      title: 'Tên nhân viên',
      dataIndex: 'full_name',
      key: 'full_name',
    },
    {
      title: 'Số tiền thưởng',
      key: 'extra_bonus',
      render: (_, record) => <>{record.extra_bonus?.toLocaleString('vi-VN')}</>,
    },
    {
      title: 'Ghi chú',
      dataIndex: 'notes',
      key: 'notes',
    },
  ];

  return columns;
};
export const ColumnsListUser = ({
  handleEdit,
  handleDelete,
}: ColumnsRendererProps) => {
  const searchParams = new URLSearchParams(location.search);
  const PageIndex = searchParams.get('pageIndex');
  const PageSize = searchParams.get('pageSize');
  const columns = [
    {
      title: 'STT',
      key: 'index',
      render: (item, index, i) => {
        return (
          <span>
            {PageIndex
              ? i + (Number(PageIndex) - 1) * Number(PageSize) + 1
              : i + 1}
          </span>
        );
      },
    },
    {
      title: 'Tên người dùng',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Tên phòng khám',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'parentPhone',
      key: 'parentPhone',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Thao tác',
      key: 'action',
      render: (_, record: DataType) => (
        <>
          <Button
            style={{ marginRight: 10 }}
            onClick={() => handleEdit(record)}
          >
            Sửa
          </Button>
          <Button onClick={() => handleDelete(record)}>Xóa</Button>
        </>
      ),
    },
  ];

  return columns;
};
