import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Radio,
  notification,
  Switch,
  InputNumber,
  message,
} from 'antd';
import { SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { SalonType } from './type';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
import MapComponent from '@components/Map';
import { handleAdress } from '@services';

export const SalonForm = ({
  initialValues,
  setInitialValues,
  onFinish,
  onFinishFailed,
  rsmId,
  setRSMId,
  asmId,
  setASMId,
}) => {
  const [form] = Form.useForm();
  const formValues = form.getFieldsValue();
  const [listCity, setListCity] = useState([]);
  const [listDistric, setListDistric] = useState([]);
  const [listStress, setListStress] = useState([]);
  const [listRegionRSM, setListRegionRSM] = useState([]);
  const [listRegionASM, setListRegionASM] = useState([]);
  const [city, setCity] = useState();
  const [distric, setDistric] = useState();
  const [loadingLatLong, setLoadingLatLong] = useState(false);
  const handleGetListRegionRSM = async () => {
    handleAdress
      .GetListRegionRSM()
      .then((response: any) => {
        const { statusCode, data, message } = response;
        if (statusCode === 200) {
          setListRegionRSM(data);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListRegionASM = async (value) => {
    handleAdress
      .GetListRegionASM(value)
      .then((response: any) => {
        const { statusCode, data, message } = response;
        if (statusCode === 200) {
          setListRegionASM(data);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetCity = async () => {
    handleAdress
      .GetListCitys()
      .then((response: any) => {
        const { statusCode, data, message } = response;
        if (statusCode === 200) {
          setListCity(data);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetDistric = async (provinceCode) => {
    handleAdress
      .GetListDistricts(provinceCode)
      .then((response: any) => {
        const { statusCode, data, message } = response;
        if (statusCode === 200) {
          setListDistric(data);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetStress = async (districtCode) => {
    handleAdress
      .GetListWards(districtCode)
      .then((response: any) => {
        const { statusCode, data, message } = response;
        if (statusCode === 200) {
          setListStress(data);
        } else {
          throw new Error(message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleGetCity();
    handleGetListRegionRSM();
  }, []);
  useEffect(() => {
    if (initialValues.provinceCode) {
      handleGetDistric(initialValues.provinceCode);
      setCity(initialValues.provinceCode);
    }
    if (initialValues.districtCode) {
      handleGetStress(initialValues.districtCode);
      setDistric(initialValues.districtCode);
    }
  }, [initialValues]);
  useEffect(() => {
    if (rsmId) {
      handleGetListRegionASM(rsmId);
    }
  }, [rsmId, asmId]);
  const handleChangeCity = (value) => {
    setCity(value);
    handleGetDistric(value);
    form.setFieldsValue({ districtCode: null });
    form.setFieldsValue({ wardCode: null });
  };
  const handleChangeRegion = (value) => {
    setRSMId(value);
    handleGetListRegionASM(value);
  };
  const handleChangeDistrict = (value) => {
    setDistric(value);
    handleGetStress(value);
    form.setFieldsValue({ wardCode: null });
  };
  const handleGetLatLong = async () => {
    try {
      const formValues = form.getFieldsValue();
      const CITY = formValues['provinceCode'];
      const cityName = listCity.find((item) => {
        return item.value === CITY;
      });
      const DISTRICT = formValues['districtCode'];
      const districtName = listDistric.find((item) => {
        return item.value === DISTRICT;
      });
      const STRESS = formValues['wardCode'];
      const stressName = listStress.find((item) => {
        return item.value === STRESS;
      });
      const address =
        stressName?.label + ',' + districtName?.label + ',' + cityName?.label;
      setLoadingLatLong(true);
      handleAdress
        .GetLatLong(address)
        .then((response: any) => {
          console.log(response, 'response');
          const { query, results } = response;

          if (results.length > 0) {
            const newInitiData = { ...initialValues };
            newInitiData.latitude = results[0]?.lat;
            newInitiData.longitude = results[0]?.lon;
            newInitiData.provinceCode = CITY;
            newInitiData.districtCode = DISTRICT;
            newInitiData.wardCode = STRESS;
            form.setFieldsValue(newInitiData);
          }
          setLoadingLatLong(false);
        })
        .catch((error) => {
          console.error(error);
          notification.error({
            message: 'Lỗi',
            description:
              'Đã có lỗi trong quá trình tìm kiếm, vui lòng nhập toạ độ bằng tay',
          });
          setLoadingLatLong(false);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleChaneSalon = (value) => {
    console.log(value, 'value');
    if (!value) {
      form.setFieldsValue({ activeBooking: false });
    }
  };
  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      <Form.Item<SalonType>
        label="Tên salon /Tên viết tắt"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="mb-0"
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType>
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên Salon',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên Salon" />
          </Form.Item>
          <Form.Item<SalonType>
            name="code"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên viết tắt',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên viết tắt" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<SalonType>
        label="Địa chỉ"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType>
            name="provinceCode"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập Tỉnh/ Thành Phố',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn Tỉnh/ Thành phố"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeCity(e)}
              options={listCity}
            />
          </Form.Item>
          <Form.Item<SalonType>
            name="districtCode"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập quận/ huyện',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn quận/ huyện"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listDistric}
              onChange={(e) => handleChangeDistrict(e)}
              disabled={!city}
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<SalonType>
        label="Địa chỉ cụ thể"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType>
            name="wardCode"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập phường/ xã',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn xã/ phường"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listStress}
              disabled={!distric}
            />
          </Form.Item>
          <Form.Item<SalonType>
            name="addressMore"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập địa chỉ cụ thể',
              },
            ]}
            className="w-[40%] ml-[4%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên đường, số nhà" />
          </Form.Item>
          <Button
            type="default"
            className="mb-[1px] w-[8%]"
            onClick={handleGetLatLong}
          >
            {loadingLatLong ? (
              <LoadingOutlined className="relative top-[-2px]" />
            ) : (
              <SearchOutlined className="relative top-[-2px]" />
            )}
          </Button>
        </div>
      </Form.Item>
      <Form.Item label="Bản đồ">
        <MapComponent form={form} />
      </Form.Item>
      <Form.Item<SalonType>
        label="Toạ độ"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="mt-4"
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType>
            name="latitude"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tung độ',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <InputNumber
              placeholder="Nhập tung độ"
              className="w-full"
              min={0}
              disabled
            />
          </Form.Item>
          <Form.Item<SalonType>
            name="longitude"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập vĩ độ',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <InputNumber
              placeholder="Nhập vĩ độ"
              className="w-full"
              min={0}
              disabled
            />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<SalonType>
        label="Vùng/ Miền"
        name="config"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="mt-4"
      >
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType>
            name="regionRsmId"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn miền',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn miền"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              onChange={(e) => handleChangeRegion(e)}
              options={listRegionRSM}
            />
          </Form.Item>
          <Form.Item<SalonType>
            name="regionId"
            rules={[
              {
                required: true,
                message: 'Vui lòng chọn vùng',
              },
            ]}
            className="w-[48%] mg-bttom-0"
          >
            <Select
              showSearch
              className="w-full"
              placeholder="Lựa chọn vùng"
              optionFilterProp="children"
              filterOption={(input, option) =>
                removeVietnamese(option?.label ?? '').includes(
                  removeVietnamese(input),
                )
              }
              options={listRegionASM}
              disabled={!rsmId}
            />
          </Form.Item>
        </div>
      </Form.Item>

      <Form.Item<SalonType>
        name="numberOfBeds"
        className="w-full"
        label="Lựa chọn số giường"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn số giường',
          },
        ]}
      >
        <InputNumber
          placeholder="Lựa chọn số giường"
          className="w-full"
          max={20}
          min={0}
          formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
        />
      </Form.Item>

      <Form.Item<SalonType> label="Số điện thoại/ Email">
        <div className="flex items-center justify-between w-full h-full mb-0">
          <Form.Item<SalonType> name="phone" className="w-[48%] mg-bttom-0">
            <Input placeholder="Nhập số điện thoại" />
          </Form.Item>
          <Form.Item<SalonType> name="email" className="w-[48%] mg-bttom-0">
            <Input placeholder="Nhập địa chỉ Email" />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<SalonType>
        label="Hiển thị trên booking"
        name="activeBooking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái Booking của Salon',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item<SalonType>
        label="Trạng thái hoạt động"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái cho Salon',
          },
        ]}
      >
        <Switch
          checkedChildren="Mở"
          unCheckedChildren="Đóng"
          defaultChecked
          onChange={(value) => handleChaneSalon(value)}
        />
      </Form.Item>
      <Form.Item<SalonType> label="Ghi chú" name="description">
        <TextArea placeholder="Nhập vào ghi chú cho Salon" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
