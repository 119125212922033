// DashboardLayout.tsx
import React, { useState, ReactNode, useEffect } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShopOutlined,
  DownOutlined,
  LoginOutlined,
  UserOutlined,
  DollarOutlined,
  LineChartOutlined,
  ContainerOutlined,
  CalendarOutlined,
  TeamOutlined,
  TagOutlined,
  ShoppingOutlined,
  ApartmentOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Menu, Dropdown, notification } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import './DashboardLayout.scss';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { handleAuth, handleStaff } from '@services';
import { RootState } from '@store';
import { setInfor, clearInfor } from '@store/auth/userSlice';
import { setToken } from '@store/auth/authSlice';
import { useDispatch } from 'react-redux';

type MenuItem = Required<MenuProps>['items'][number];

type MenuItems = {
  key: React.Key;
  icon?: React.ReactNode;
  children?: MenuItem[];
  label: React.ReactNode;
  type?: 'group';
  to?: string;
};

interface DashboardLayoutProps {
  children: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [collapsed, setCollapsed] = useState<any>();
  const [openKeys, setOpenKeys] = useState(['']);
  const [selectedKeys, setSelectedKeys] = useState(['1']);
  const [data, setData] = useState<any>();
  const [role, setRole] = useState<any>();
  const navigate = useNavigate();
  const isLoading = useSelector(
    (state: { loading: { loading: boolean } }) => state.loading.loading,
  );
  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  };
  const handleCheckuser = async () => {
    handleStaff
      .GetCurrent()
      .then((response: any) => {
        const { data, statusCode } = response;
        if (statusCode === 200) {
          setData(data);
          dispatch(setInfor(data));
          setRole(data?.role);
        } else if (statusCode === 403) {
          Cookies.remove('refreshToken');
          Cookies.remove('accessToken');
          dispatch(clearInfor());
          dispatch(setToken(null));
          const currentUrl = window.location.href;
          window.location.href =
            process.env.REACT_APP_SSO_DOMAIN + '?redirect=' + currentUrl;
        } else {
          notification.error({
            message: 'Lỗi xác thực',
            description: 'Lỗi khi kiểm tra thông tin tài khoản',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    handleCheckuser();
    handleResize(); // Gọi hàm handleResize khi component được render
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (collapsed === false || collapsed === true) {
      if (location && location.pathname) {
        const url = location.pathname;

        if (url.includes('/salon/')) {
          !collapsed && setOpenKeys(['sub1']);
          setSelectedKeys(['sub1']);
        } else if (url.includes('/service/')) {
          !collapsed && setOpenKeys(['sub2']);
          if (url.includes('/category')) {
            setSelectedKeys(['21']);
          } else if (url.includes('/list')) {
            setSelectedKeys(['22']);
          } else if (url.includes('/config')) {
            setSelectedKeys(['23']);
          }
        } else if (url.includes('/voucher/')) {
          !collapsed && setOpenKeys(['sub3']);
          if (url.includes('/list')) {
            setSelectedKeys(['31']);
          } else if (url.includes('/send')) {
            setSelectedKeys(['32']);
          }
        } else if (url.includes('/staff/')) {
          !collapsed && setOpenKeys(['sub4']);
          if (url.includes('/list')) {
            setSelectedKeys(['41']);
          } else if (url.includes('/change')) {
            setSelectedKeys(['42']);
          }
        } else if (url.includes('/customer/')) {
          !collapsed && setOpenKeys(['sub5']);
          if (url.includes('/list-customer')) {
            setSelectedKeys(['51']);
          } else if (url.includes('/list-topup')) {
            setSelectedKeys(['52']);
          }
        } else if (url.includes('/checkin/')) {
          !collapsed && setOpenKeys(['sub6']);
          if (url.includes('/statistical')) {
            setSelectedKeys(['60']);
          }
          if (url.includes('/list-booking')) {
            setSelectedKeys(['61']);
          } else if (url.includes('/timeline')) {
            setSelectedKeys(['62']);
          } else if (url.includes('/list')) {
            setSelectedKeys(['63']);
          } else if (url.includes('/in-salon')) {
            setSelectedKeys(['64']);
          } else if (url.includes('/cho-phuc-vu')) {
            setSelectedKeys(['65']);
          } else if (url.includes('/dang-phuc-vu')) {
            setSelectedKeys(['66']);
          }
        } else if (url.includes('/checkout/')) {
          !collapsed && setOpenKeys(['sub7']);
          if (url.includes('/statistical')) {
            setSelectedKeys(['73']);
          } else if (url.includes('/list')) {
            setSelectedKeys(['71']);
          } else if (url.includes('/bill')) {
            setSelectedKeys(['72']);
          }
        } else if (url.includes('/timekeeping/')) {
          !collapsed && setOpenKeys(['sub8']);
          if (url.includes('/attendance')) {
            setSelectedKeys(['81']);
          } else if (url.includes('/create')) {
            setSelectedKeys(['82']);
          } else if (url.includes('/list')) {
            setSelectedKeys(['83']);
          } else if (url.includes('/awaiting')) {
            setSelectedKeys(['84']);
          }
        } else if (url.includes('/salary/')) {
          !collapsed && setOpenKeys(['sub9']);
          if (url.includes('/list')) {
            setSelectedKeys(['91']);
          } else if (url.includes('/config')) {
            setSelectedKeys(['92']);
          } else if (url.includes('/bonus')) {
            setSelectedKeys(['93']);
          }
        } else if (url.includes('/inventory/')) {
          !collapsed && setOpenKeys(['sub10']);
          if (url.includes('/list')) {
            setSelectedKeys(['101']);
          }
        }
      }
    }
  }, [location.pathname, collapsed]);
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  function getItem(
    label: string,
    key: string,
    icon?: ReactNode,
    children?: MenuItem[],
    to?: string,
  ) {
    return {
      key,
      label,
      icon,
      children,
      to,
    };
  }
  // Render menu item function
  function renderMenuItem(item: MenuItems) {
    if (item.children) {
      return (
        <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
          {item.children.map(renderMenuItem)}
        </Menu.SubMenu>
      );
    } else if (item.to) {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          <Link to={item.to}>{item.label}</Link>
        </Menu.Item>
      );
    } else {
      return (
        <Menu.Item key={item.key} icon={item.icon}>
          {item.label}
        </Menu.Item>
      );
    }
  }
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (role) {
      setItems([
        // Add an image to the menu as the first item
        {
          key: 'logo',
          label: (
            <Link to={'/'}>
              {collapsed ? (
                <img src={'/fav.png'} alt="Logo" className={'logo-collapsed'} />
              ) : (
                <img
                  src={'/logo_white.png'}
                  alt="Logo"
                  className={'logo-dis-collapsed'}
                />
              )}
            </Link>
          ),
          type: 'group', // Change type to 'group'
        },
        ['ADMIN', 'MANAGER', 'ACCOUNTANT', 'RSM', 'ASM'].includes(role) &&
          getItem('Salon', 'sub1', <ShopOutlined />, undefined, '/salon/list'),
        ['ADMIN', 'MANAGER', 'ACCOUNTANT'].includes(role) &&
          getItem('Dịch vụ', 'sub2', <ShoppingOutlined />, [
            getItem(
              'Danh mục dịch vụ',
              '21',
              undefined,
              undefined,
              '/service/category',
            ),
            getItem(
              'Danh sách dịch vụ',
              '22',
              undefined,
              undefined,
              '/service/list',
            ),
            // getItem(
            //   'Cấu hình dịch vụ Salon',
            //   '23',
            //   undefined,
            //   undefined,
            //   '/service/config',
            // ),
          ]),
        ['ADMIN', 'MANAGER', 'ACCOUNTANT'].includes(role) &&
          getItem('Quản lý ưu đãi', 'sub3', <TagOutlined />, [
            getItem(
              'Danh sách ưu đãi',
              '31',
              undefined,
              undefined,
              '/voucher/list',
            ),
            // getItem('Gửi ưu đãi', '32', undefined, undefined, '/voucher/send'),
          ]),
        [
          'ADMIN',
          'MANAGER',
          'ACCOUNTANT',
          'HUMAN_RESOURCE',
          'RSM',
          'ASM',
          'SM',
        ].includes(role) &&
          getItem('Nhân viên', 'sub4', <UserOutlined />, [
            getItem(
              'Danh sách nhân viên',
              '41',
              undefined,
              undefined,
              '/staff/list',
            ),
            getItem(
              'Biến động nhân sự',
              '42',
              undefined,
              undefined,
              '/staff/change',
            ),
          ]),
        [
          'ADMIN',
          'MANAGER',
          'ACCOUNTANT',
          'HUMAN_RESOURCE',
          'RSM',
          'ASM',
          'SM',
          'SHIFT_LEADER',
          'KTV',
        ].includes(role) &&
          getItem('Khách hàng', 'sub5', <TeamOutlined />, [
            getItem(
              'Danh sách khách hàng',
              '51',
              undefined,
              undefined,
              '/customer/list-customer',
            ),
            ['ADMIN', 'MANAGER', 'ACCOUNTANT'].includes(role) &&
              getItem(
                'Danh sách Topup',
                '52',
                undefined,
                undefined,
                '/customer/list-topup',
              ),
          ]),
        [
          'ADMIN',
          'MANAGER',
          'RSM',
          'ASM',
          'SM',
          'SHIFT_LEADER',
          'KTV',
        ].includes(role) &&
          getItem('Checkin', 'sub6', <CalendarOutlined />, [
            getItem(
              'Thống kê booking',
              '60',
              undefined,
              undefined,
              '/checkin/statistical',
            ),
            getItem(
              'Danh sách booking',
              '61',
              undefined,
              undefined,
              '/checkin/list-booking',
            ),
            // getItem('Timeline', '62', undefined, undefined, '/checkin/timeline'),
            getItem('Checkin', '63', undefined, undefined, '/checkin/list'),
            getItem(
              'Khách chờ tư vấn',
              '64',
              undefined,
              undefined,
              '/checkin/in-salon',
            ),
            getItem(
              'Khách chờ phục vụ',
              '65',
              undefined,
              undefined,
              '/checkin/cho-phuc-vu',
            ),
            getItem(
              'Khách đang phục vụ',
              '66',
              undefined,
              undefined,
              '/checkin/dang-phuc-vu',
            ),
          ]),
        [
          'ADMIN',
          'MANAGER',
          'RSM',
          'ASM',
          'SM',
          'SHIFT_LEADER',
          'KTV',
        ].includes(role) &&
          getItem('Checkout', 'sub7', <ContainerOutlined />, [
            getItem('Checkout', '71', undefined, undefined, '/checkout/list'),
            getItem(
              'Danh sách Hoá đơn',
              '72',
              undefined,
              undefined,
              '/checkout/bill',
            ),
            getItem(
              'Thống kê',
              '73',
              undefined,
              undefined,
              '/checkout/statistical',
            ),
          ]),
        ['ADMIN', 'MANAGER', 'RSM', 'ASM', 'SM'].includes(role) &&
          getItem('Xếp lịch làm việc', 'sub8', <LineChartOutlined />, [
            getItem(
              'Điểm danh',
              '81',
              undefined,
              undefined,
              '/timekeeping/attendance',
            ),
            getItem(
              'Xếp lịch làm việc',
              '82',
              undefined,
              undefined,
              '/timekeeping/create',
            ),
            getItem(
              'Danh sách lịch làm việc',
              '83',
              undefined,
              undefined,
              '/timekeeping/list',
            ),
            // getItem(
            //   'Lịch làm việc chờ duyệt',
            //   '84',
            //   undefined,
            //   undefined,
            //   '/timekeeping/awaiting',
            // ),
          ]),
        ['ADMIN', 'MANAGER', 'ACCOUNTANT'].includes(role) &&
          getItem('Lương', 'sub9', <DollarOutlined />, [
            getItem(
              'Danh sách lương',
              '91',
              undefined,
              undefined,
              '/salary/list',
            ),
            getItem(
              'Cấu hình lương cơ bản',
              '92',
              undefined,
              undefined,
              '/salary/config',
            ),
            getItem(
              'Cấu hình lương thưởng',
              '93',
              undefined,
              undefined,
              '/salary/bonus',
            ),
          ]),
        // ['ADMIN', 'MANAGER', 'ACCOUNTANT', 'SM'].includes(role) &&
        //   getItem('Kho', 'sub10', <ApartmentOutlined />, [
        //     getItem(
        //       'Báo cáo tồn kho',
        //       '101',
        //       undefined,
        //       undefined,
        //       '/inventory/list',
        //     ),
        //   ]),
      ]);
    }
  }, [collapsed, role]);

  const handleMenuOpenChange = (keys: any) => {
    setOpenKeys(keys.length > 0 ? keys : '');
  };
  const handleLogout = () => {
    // tiến hành xóa dữ liệu
    Cookies.remove('refreshToken');
    Cookies.remove('accessToken');
    dispatch(clearInfor());
    dispatch(setToken(null));
    const currentUrl = window.location.href;
    window.location.href =
      process.env.REACT_APP_SSO_DOMAIN + '?redirect=' + currentUrl;
    // navigate('/login');
  };
  const menu = (
    <Menu>
      <Menu.Item
        key={'1'}
        icon={<LoginOutlined />}
        danger={true}
        disabled={false}
        onClick={handleLogout}
      >
        Đăng xuất
      </Menu.Item>
    </Menu>
  );
  console.log(collapsed, 'collapsed');
  return (
    <Spin tip="Tải dữ liệu..." spinning={false}>
      <div className="main" style={{ display: 'flex' }}>
        <div
          className={collapsed ? 'main-sidebar max-lg:w-[0px]' : 'main-sidebar'}
        >
          {items && role && (
            <Menu
              // defaultSelectedKeys={['sub1']}
              // defaultOpenKeys={['sub2']}
              mode="inline"
              theme="dark"
              selectedKeys={selectedKeys}
              openKeys={openKeys}
              onOpenChange={handleMenuOpenChange}
              inlineCollapsed={collapsed}
              style={{ height: '100vh' }}
              className={
                collapsed ? (window.innerWidth < 1200 ? 'w-[0px]' : '') : ''
              }
            >
              {items.map(renderMenuItem)}
            </Menu>
          )}
        </div>
        <div className={!collapsed ? 'main-wrap' : 'main-wrap-collapsed'}>
          <div className="navbar">
            {collapsed ? (
              <MenuUnfoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            ) : (
              <MenuFoldOutlined
                onClick={toggleCollapsed}
                className="navbar-icon"
              />
            )}
            {data ? (
              <Dropdown overlay={menu} trigger={['click']}>
                <Button type="text">
                  Xin chào
                  <span style={{ marginLeft: 10, fontWeight: 600 }}>
                    {data?.name ? data?.name : data?.username}
                  </span>
                  <DownOutlined />
                </Button>
              </Dropdown>
            ) : (
              <Button type="text" onClick={handleLogout}>
                Đăng xuất
              </Button>
            )}
          </div>
          <div className="main-spin">
            <Spin
              tip="Đang lấy dữ liệu ..."
              spinning={isLoading}
              size="large"
              className="main-content"
            >
              {children}
            </Spin>
          </div>
        </div>
      </div>
    </Spin>
  );
};

export default DashboardLayout;
