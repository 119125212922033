import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Dropdown, Select } from 'antd';
import {
  FilterOutlined,
  DownloadOutlined,
  CloseOutlined,
  SearchOutlined,
  FileExcelOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { OPageIndex, OPageSize } from '@configs/pageSize';
import { useLocation, useNavigate } from 'react-router-dom';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import DynamicForm from './DynamicFilter';
import {
  saveDataToStorage,
  getDataFromStorage,
} from '@utils/Functions/dataStorage';
import {
  handleClinic,
  handleSalon,
  handleStaff,
  handleTimekeeping,
} from '@services';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
export const CheckinFilterConfig = ({
  dataFilter,
  setDataFilter,
  keyName,
  tableLoading,
  setData,
}) => {
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mypageIndex = queryParams.get('pageIndex') || OPageIndex;
  const mypageSize = queryParams.get('pageSize') || OPageSize;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [listSalon, setListSalon] = useState([]);
  const handleGetListSalon = async () => {
    handleSalon
      .GetDropListSalon()
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          setListSalon(data);
        } else {
          throw new Error('Invalid');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  function queryStringToObject(queryString) {
    const urlSearchParams = new URLSearchParams(queryString);
    const paramsObject = {};

    urlSearchParams.forEach((value, key) => {
      paramsObject[key] = value;
    });

    return paramsObject;
  }

  useEffect(() => {
    if (queryParams) {
      const paramsObject: any = queryStringToObject(location.search);
      console.log(paramsObject, 'paramsObject');
      if (queryParams.get('salonId')) {
        paramsObject.salonId = Number(queryParams.get('salonId'));
      }
      if (queryParams.get('bookingDate')) {
        paramsObject.bookingDate = dayjs(queryParams.get('bookingDate'));
      } else {
        paramsObject.bookingDate = dayjs();
      }
      setInitialValues(paramsObject);
    }
  }, [location.search]);

  const onFinish = (value) => {
    const params = {
      ...value,
      pageSize: mypageSize,
      pageIndex: mypageIndex,
    };
    if (value?.bookingDate) {
      params.bookingDate = dayjs(value.bookingDate).format('YYYY-MM-DD');
    }
    const filteredParams: any = Object.fromEntries(
      Object.entries(params).filter(
        ([key, value]) => value !== undefined && value !== null,
      ),
    );

    const queryString = new URLSearchParams(filteredParams).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);

    console.log(filteredParams, 'filteredParams');
  };
  const onFinishFailed = () => {
    console.log('False');
  };
  const onResetFilter = () => {
    const params = {};
    const filteredParams: any = Object.fromEntries(
      Object.entries(params).filter(([key, value]) => value !== undefined),
    );

    // Lấy danh sách tất cả các trường trong form
    const allFields = form.getFieldsValue();

    // Tạo một đối tượng mới với tất cả các trường được đặt thành null
    const resetValues = Object.keys(allFields).reduce((acc, key) => {
      acc[key] = null;
      return acc;
    }, {});

    // Đặt tất cả các trường trong form thành null hoặc undefined
    form.setFieldsValue(resetValues);

    setInitialValues({}); // Sau khi resetFields, đặt lại giá trị mặc định

    const queryString = new URLSearchParams(filteredParams).toString();

    const newUrl = `${window.location.pathname}?${queryString}`;
    navigate(newUrl);
    setData([]);
  };

  const handleAddSearch = () => {
    if (select) {
      const selectValue = dataFilter.find((item) => {
        return item.value === select;
      });
      const newData = dataFilter.filter((item) => {
        return item.value !== select;
      });
      setDataFilter(newData);
      const dataColumSearch = [...columnSearch];
      setColumnSearch([...dataColumSearch, selectValue]);
      saveDataToStorage(keyName, [...dataColumSearch, selectValue]);
      setOpenDropdown(false);
      setSelect(undefined);
    }
  };
  const handleCancel = () => {
    setOpenDropdown(false);
  };
  useEffect(() => {
    handleGetListSalon();
  }, []);
  const CustomMenu = () => {
    return (
      <div className="w-[300px] h-[165px] bg-white shadow px-5 py-2">
        <div className="pt-2 pb-1 border-b-[1px] mx-2 mb-5">
          Thêm điều kiện lọc
        </div>
        <Select
          showSearch
          value={select}
          onChange={(e) => setSelect(e)}
          className="w-full"
          placeholder="Lựa chọn điều kiện lọc"
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={dataFilter}
        />
        <div className="flex justify-between mt-5">
          <Button type="primary" onClick={handleAddSearch}>
            Thêm điều kiện
          </Button>
          <Button type="default" onClick={handleCancel}>
            Huỷ bỏ
          </Button>
        </div>
      </div>
    );
  };
  const [select, setSelect] = useState();
  const [columnSearch, setColumnSearch] = useState(getDataFromStorage(keyName));
  console.log(columnSearch, 'columnSearch');
  useEffect(() => {
    if (
      columnSearch &&
      columnSearch.length > 0 &&
      dataFilter &&
      dataFilter.length > 0
    ) {
      console.log(columnSearch, 'columnSearch');
      const newDataValue = [...dataFilter];
      const differenceArray = newDataValue.filter(
        (itemA) => !columnSearch.some((itemB) => itemA.value === itemB.value),
      );
      console.log('không chạy');
      console.log(differenceArray, 'differenceArray');
      setDataFilter(differenceArray);
    }
  }, [columnSearch.length, dataFilter.length]);

  return (
    <div className="px-10 pt-5 pb-3 mt-5 bg-white">
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        key={JSON.stringify(initialValues)}
      >
        <div className="flex flex-wrap items-center">
          <Dropdown
            overlay={CustomMenu()}
            trigger={['click']}
            className="mb-3 mr-5"
            open={openDropdown}
          >
            <Button
              type="primary"
              onClick={(e) => {
                setOpenDropdown(!openDropdown);
                e.preventDefault();
              }}
              icon={<FilterOutlined />}
            >
              Thêm điều kiện lọc
            </Button>
          </Dropdown>
          {Role !== 'SM' && Role !== 'SHIFT_LEADER' && Role !== 'KTV' && (
            <Form.Item
              name="salonId"
              className="w-[260px] mr-5 relative top-[4px]"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn Salon',
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Lựa chọn Salon"
                options={listSalon}
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                  removeVietnamese(option?.label ?? '').includes(
                    removeVietnamese(input),
                  )
                }
              ></Select>
            </Form.Item>
          )}
          {/* <Form.Item
            name="phoneNumber"
            className="w-[360px] mr-5 relative top-[4px]"
          >
            <Input placeholder="Nhập số điện thoại khách, mã Booking"></Input>
          </Form.Item> */}
          <DynamicForm
            columnSearch={columnSearch}
            setColumnSearch={setColumnSearch}
            dataFilter={dataFilter}
            setDataFilter={setDataFilter}
            keyName={keyName}
            initialValues={initialValues}
            form={form}
          />
        </div>
        <Form.Item className="mt-3">
          <Button type="primary" htmlType="submit" className="mr-5 w-[177px]">
            {!tableLoading ? (
              <SearchOutlined className="relative top-[-3px]" />
            ) : (
              <LoadingOutlined className="relative top-[-3px]" />
            )}{' '}
            Xem dữ liệu
          </Button>
          <Button onClick={onResetFilter} className="mr-5">
            Reset Lọc
          </Button>
          {/* <Button onClick={exportExcel} className="button-green">
            <FileExcelOutlined className="relative top-[-3px]" />
            Xuất file Excel
          </Button> */}
        </Form.Item>
      </Form>
    </div>
  );
};
