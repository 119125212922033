import React, { useEffect, useState } from 'react';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { MAX_FILE_SIZE, ALLOWED_TYPE_PHOTO } from '@configs/image';
import { Upload, notification, Modal, Image } from 'antd';
import { handleUploadImage } from '@services/upload';

interface UploadCustomProps {
  fileList: any;
  setFileList: any;
  forlderName: string;
  MaxCount: number;
}

const UploadMutiple: React.FC<UploadCustomProps> = ({
  fileList,
  setFileList,
  forlderName,
  MaxCount,
}) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const onChangeImage: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    setIsUploading(true);
    setFileList(newFileList);
  };
  // useEffect(() => {
  //   if (listImage.length > 0) {
  //     const listNews = listImage.map((item) => {
  //       return {
  //         uid: 'oke',
  //         lastModified: null,
  //         lastModifiedDate: '',
  //         name: 'oke',
  //         size: 490015,
  //         type: 'image/png',
  //         percent: 100,
  //         originFileObj: {
  //           uid: 'oke',
  //         },
  //         status: 'done',
  //         response: item,
  //         thumbUrl: item,
  //       };
  //     });
  //     setFileList(listNews);
  //   }
  // }, [listImage]);
  const customRequest = async (options: any) => {
    const { file, onSuccess, onError } = options;
    try {
      if (file.size > MAX_FILE_SIZE) {
        return notification.error({
          message: 'Lỗi',
          description: 'Kích thước tệp quá lớn. Vui lòng chọn tệp nhỏ hơn 3MB.',
        });
      }
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (!ALLOWED_TYPE_PHOTO.includes(fileExtension)) {
        return notification.error({
          message: 'Loại tệp không hợp lệ',
          description: 'Vui lòng chọn tệp ảnh (jpg, jpeg, png, webp)',
        });
      }
      const newFileName = `${Date.now()}.${fileExtension}`;

      // Create a new file object with the new file name
      const newFile = new File([file], newFileName, { type: file.type });

      // Use the new file object in handleUploadImage
      const { statusCode, message, data } = await handleUploadImage(
        newFile,
        forlderName,
      );
      if (statusCode === 200) {
        onSuccess(data);
      }
    } catch (error) {
      onError(error);
    }
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    console.log(file, 'src');

    if (!src) {
      try {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as RcFile);
          reader.onload = () => resolve(reader.result as string);
        });
      } catch {
        src = file.response;
      }
    }
    Modal.info({
      width: 400,
      title: '',
      content: <Image src={src} alt="Preview" style={{ maxWidth: '100%' }} />,
      maskClosable: true,
      icon: null,
      okButtonProps: { style: { display: 'none' } },
    });
  };
  return (
    <Upload
      customRequest={customRequest}
      listType="picture-card"
      fileList={fileList}
      onChange={onChangeImage}
      onPreview={onPreview}
      className="mx-5 my-5"
      maxCount={MaxCount}
      accept=".jpg,.jpeg,.png,.webp"
    >
      {fileList.length < MaxCount && '+ Tải Ảnh'}
    </Upload>
  );
};

export default UploadMutiple;
