import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Switch, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import { validatePhoneNumber } from '@utils/Functions/validate';

export const TopupForm = ({ initialValues, onFinish, onFinishFailed }) => {
  const listTopup = [
    { label: 'Topup: 20.000 đ', value: 20000 },
    { label: 'Topup: 50.000 đ', value: 50000 },
    { label: 'Topup: 100.000 đ', value: 100000 },
    { label: 'Topup: 150.000 đ', value: 150000 },
    { label: 'Topup: 200.000 đ', value: 200000 },
    { label: 'Topup: 300.000 đ', value: 30000 },
    { label: 'Topup: 500.000 đ', value: 500000 },
    { label: 'Topup: 1.000.000 đ', value: 1000000 },
    { label: 'Topup: 1.500.000 đ', value: 1500000 },
    { label: 'Topup: 2.000.000 đ', value: 2000000 },
    { label: 'Topup: 2.500.000 đ', value: 2500000 },
    { label: 'Topup: 3.000.000 đ', value: 3000000 },
    { label: 'Topup: 4.000.000 đ', value: 4000000 },
    { label: 'Topup: 5.000.000 đ', value: 5000000 },
  ];
  return (
    <Form
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
    >
      {!initialValues?.config && (
        <>
          <Form.Item label="Tên khách hàng" name="name" className="mb-0">
            <span>{initialValues?.name}</span>
          </Form.Item>
          <Form.Item label="Số dư Topup" name="name" className="mb-0">
            <span>{initialValues?.price?.toLocaleString('vi-VN')} đ</span>
          </Form.Item>
        </>
      )}
      <Form.Item
        label="Nhập số điện thoại khách"
        name="phoneNumber"
        rules={[
          {
            required: true,
            validator: validatePhoneNumber,
          },
        ]}
        className="mb-0"
      >
        <Input
          placeholder="Số điện thoại khách"
          disabled={!initialValues.config}
        />
      </Form.Item>
      <Form.Item
        label="Mệnh giá"
        name="amount"
        rules={[
          {
            required: true,
            message: 'Vui lòng lựa chọn mệnh gía Topup',
          },
        ]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn mệnh giá Topup"
          optionFilterProp="children"
          filterOption={(input, option) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listTopup}
        />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {initialValues?.config ? 'Tạo mới ' : 'Xác nhận nạp'}
        </Button>
      </div>
    </Form>
  );
};
