import { useState, useEffect } from 'react';
import TitleBox from '@components/TitleBox';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, notification, Spin } from 'antd';
import { handleTimekeeping } from '@services';
import { searchIgnoreCaseAndAccent } from '@utils/Functions/IgnoreCase';
import { FilterSalon } from '@components/Filter/Salon';
import TabelComponent from '@components/Table';
import { breadcrumbStaticficalBooking as breadcrumbItems } from '@components/Breadcrumb';
import { ColumnsListTimeKeeping as columns } from '@components/Table/Columns';
import { getParamsFromUrlConfigUnLimited } from '@utils/Functions/getParams';
import { StaticFialFilter } from '@components/Filter/StaticfialBooking';
import { useDispatch, useSelector } from 'react-redux';
import '../index.scss';
import AdminStatiscal from '@components/Statistical/admin';
import SMStatiscal from '@components/Statistical/sm';

export const StatisticalBooking = () => {
  const [loading] = useState(false);
  const { UserInfor, Role } = useSelector((state: any) => state.userName);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams: any = new URLSearchParams(location.search);
  const date = queryParams.get('date');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [open, setOpen] = useState(false);
  const [dataShiftA, setDataShiftA] = useState([]);
  const [dataShiftB, setDataShiftB] = useState([]);
  const [dataShiftC, setDataShiftC] = useState([]);
  const [modalData, setModalData] = useState<any>();
  const [tableLoading, setTableLoading] = useState(false);
  const salonId = queryParams.get('salonId');
  const title = 'Thống kê Booking của Salon';
  const titleButton = '';
  const routerAdd = '';
  const keyName = '';

  const [dataFilter, setDataFilter] = useState([]);

  const customTotal = () => {
    return (
      <span className="pagination-table-title">
        Tổng {totalItems} nhân viên
      </span>
    );
  };
  return (
    <Spin spinning={loading}>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <StaticFialFilter
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        keyName={keyName}
        tableLoading={tableLoading}
      />
      <div>
        {Role ? (
          <>
            {Role === 'SM' || Role === 'SHIFT_LEADER' || Role === 'KTV' ? (
              <SMStatiscal />
            ) : (
              <AdminStatiscal />
            )}
          </>
        ) : (
          <Spin />
        )}
      </div>
    </Spin>
  );
};
