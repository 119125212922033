import CategoryEndpoint from '@api/category';
import { notification } from 'antd';

export const GetListCategory = async (param: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.getListCategory(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });
  }
};

export const CreateCategory = async (body: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.createCategory(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateCategory = async (body: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.updateCategory(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDetailCategory = async (id: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.getDetailCategory(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const GetDropListCategory = async (): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.getDropListCategory();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.updateBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const UpdateActive = async (body: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.updateActive(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const DeleteCategory = async (id: any): Promise<any | Error> => {
  try {
    const response = await CategoryEndpoint.deleteCategory(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
