import React, { useState, useEffect } from 'react';
import {
  Button,
  Input,
  Select,
  Form,
  DatePicker,
  Radio,
  notification,
  Switch,
  Checkbox,
  InputNumber,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { ServiceType } from './type';
import {
  validateIdentificationNumber,
  validatePhoneNumber,
  validateEmail,
  validateAdress,
  validateFullName,
} from '@utils/Functions/validate';
import { handleCategory, handleService } from '@services';
import { removeVietnamese } from '@utils/Functions/RemoveVietnamese';
import TextArea from 'antd/es/input/TextArea';
const { Option } = Select;
import MapComponent from '@components/Map';

export const ServicesForm = ({ initialValues, onFinish, onFinishFailed }) => {
  const [form] = Form.useForm();
  const [listCategory, setListCategory] = useState([]);
  const [listServices, setListServices] = useState([]);
  const [combo, setCombo] = useState<boolean>();
  const [category, setCategory] = useState();

  const handleGetListCategory = async () => {
    handleCategory
      .GetDropListCategory()
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListCategory(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleGetListServices = async (categoryId) => {
    handleService
      .GetListDropService({ categoryId: categoryId })
      .then((response: any) => {
        const { statusCode, data, optional } = response;
        if (statusCode === 200) {
          setListServices(data);
        } else {
          notification.error({
            message: 'Lỗi',
            description: 'Đã có lỗi trong quá trình lấy dữ liệu !!',
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleChangeCategory = (value) => {
    console.log(value, 'value');
    setCategory(value);
    handleGetListServices(value);
  };
  useEffect(() => {
    handleGetListCategory();
  }, []);
  useEffect(() => {
    if (initialValues) {
      if (initialValues?.combo) {
        setCombo(initialValues.combo);
      }
      if (initialValues?.categoryId) {
        setCategory(initialValues.categoryId);
        handleGetListServices(initialValues.categoryId);
      }
    }
  }, [initialValues]);
  const handleChangeActive = (value) => {
    console.log(value, 'value');
    if (!value) {
      form.setFieldsValue({ activeBooking: false });
    }
  };
  return (
    <Form
      form={form}
      key={JSON.stringify(initialValues)}
      initialValues={initialValues}
      labelCol={{ span: 6 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      className="mb-10 main-add-form"
      // onValuesChange={(changedValues, allValues) => {
      //   handleChangeValue(changedValues, allValues);
      // }}
    >
      <Form.Item<ServiceType>
        label="Tên dịch vụ/ Mã dịch vụ"
        name="name"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
      >
        <div className="flex">
          <Form.Item<ServiceType>
            name="name"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập tên dịch vụ',
              },
            ]}
            className="w-[90%] mg-bttom-0"
          >
            <Input placeholder="Nhập tên dịch vụ" />
          </Form.Item>
          <Form.Item<ServiceType>
            name="code"
            className="h-[33px] w-[120px] ml-4 mg-bttom-0"
          >
            <Input placeholder="Mã dịch vụ" disabled />
          </Form.Item>
        </div>
      </Form.Item>
      <Form.Item<ServiceType>
        label="Danh mục dịch vụ"
        name="categoryId"
        rules={[
          {
            required: true,
            message: 'Vui lòng lựa chọn danh mục dịch vụ',
          },
        ]}
      >
        <Select
          showSearch
          className="w-full"
          placeholder="Lựa chọn danh mục dịch vụ"
          optionFilterProp="children"
          filterOption={(input, option: any) =>
            removeVietnamese(option?.label ?? '').includes(
              removeVietnamese(input),
            )
          }
          options={listCategory}
          onChange={(value) => handleChangeCategory(value)}
        />
      </Form.Item>
      <Form.Item<ServiceType> label="Dịch vụ Combo" name="combo">
        <Switch
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={() => setCombo(!combo)}
        />
      </Form.Item>
      {combo && (
        <Form.Item<ServiceType>
          label="Chọn dịch vụ Combo"
          name="comboServiceId"
        >
          <Select
            mode="multiple"
            showSearch
            className="w-full"
            placeholder="Lựa chọn dịch vụ combo"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              removeVietnamese(option?.label ?? '').includes(
                removeVietnamese(input),
              )
            }
            options={listServices}
            disabled={!category}
          />
        </Form.Item>
      )}
      <Form.Item<ServiceType>
        label="Giá"
        name="cost"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập giá dịch vụ',
          },
        ]}
        className="w-full"
      >
        <InputNumber
          placeholder="Nhập giá dịch vụ"
          className="w-full"
          min={0}
          formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
        />
      </Form.Item>
      <Form.Item<ServiceType>
        label="Thời gian phục vụ"
        name="serviceTime"
        rules={[
          {
            required: true,
            message: '',
          },
        ]}
        className="w-full"
      >
        <div className="flex items-center w-full">
          <Form.Item<ServiceType>
            name="serviceTime"
            rules={[
              {
                required: true,
                message: 'Vui lòng nhập thời gian phục vụ',
              },
            ]}
            className="w-full mg-bttom-0"
          >
            <InputNumber
              placeholder="Nhập thời gian phục vụ"
              className="w-full"
              min={0}
              formatter={(value) => `${value}`.replace(/[^0-9]/g, '')}
            />
          </Form.Item>
          <span className="ml-4">Phút</span>
        </div>
      </Form.Item>
      {/* <Form.Item<ServiceType>
        label="Hoa hồng cho Checkin"
        name="bonusForCheckin"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập hệ số thưởng cho Checkin',
          },
        ]}
        className="w-full"
      >
        <InputNumber
          placeholder="Nhập hệ số hoa hồng"
          className="w-full"
          min={0}
        />
      </Form.Item> */}
      <Form.Item<ServiceType>
        label="Hoa hồng cho phục vụ"
        name="bonusForStaff"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập hệ số thưởng cho nhân viên phục vụ',
          },
        ]}
        className="w-full"
      >
        <InputNumber
          placeholder="Nhập hệ số hoa hồng"
          className="w-full"
          min={0}
        />
      </Form.Item>
      <Form.Item<ServiceType>
        label="Hoa hồng cho quản lý"
        name="bonusForManager"
        rules={[
          {
            required: true,
            message: 'Vui lòng nhập hệ số thưởng cho quản lý',
          },
        ]}
        className="w-full"
      >
        <InputNumber
          placeholder="Nhập hệ số hoa hồng"
          className="w-full"
          min={0}
        />
      </Form.Item>
      <Form.Item<ServiceType>
        label="Hiển thị trên booking"
        name="activeBooking"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn hiển thị booking',
          },
        ]}
      >
        <Switch checkedChildren="Mở" unCheckedChildren="Đóng" defaultChecked />
      </Form.Item>
      <Form.Item<ServiceType>
        label="Trạng thái hoạt động"
        name="active"
        rules={[
          {
            required: true,
            message: 'Vui lòng chọn trạng thái hoạt động',
          },
        ]}
      >
        <Switch
          checkedChildren="Mở"
          unCheckedChildren="Đóng"
          defaultChecked
          onChange={(value) => handleChangeActive(value)}
        />
      </Form.Item>
      <Form.Item<ServiceType> label="Ghi chú" name="description">
        <TextArea placeholder="Nhập vào ghi chú cho dịch vụ" />
      </Form.Item>
      <div className="flex justify-end">
        <Button type="primary" className="button-submit" htmlType="submit">
          {!initialValues?.config ? 'Tạo mới ' : 'Cập nhật'}
        </Button>
      </div>
    </Form>
  );
};
