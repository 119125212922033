import { instance } from '@configs/instance';

const getListClinics = (param: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_BASE_URL + `/api/admin/clinic-manager/get`,
    { params: param },
  );
};

const createClinic = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_BASE_URL + `/api/admin/clinic-manager/create-clinic`,
    body,
  );
};

const getDetailClinics = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_BASE_URL + `/api/admin/clinic-manager/get?id=${id}`,
  );
};

export default {
  getListClinics,
  createClinic,
  getDetailClinics,
};
