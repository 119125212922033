import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { handleCustomer, handleBooking } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { convertTypeBill } from '@utils/Functions/configArray';

const ListBill = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [listBill, setListBill] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const [services, setServices] = useState<any>([]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalData(null);
    setServices([]);
  };
  const handleGetListBill = async () => {
    const params = {
      pageIndex: 1,
      pageSize: 1000,
    };
    setLoading(true);
    handleCustomer
      .getListBillCutomers(id, params)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setListBill(data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const handleGetListServices = async (serviceIds) => {
    handleBooking
      .GetListServicesSQL(serviceIds)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          console.log(data, 'data');
          setServices(data);
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetListBill();
    }
  }, [id]);
  const handleViewDetail = (item: any) => {
    setModalData(item);
    const listIds = item?.serviceIds.join(',');
    handleGetListServices(listIds);
    setIsModalOpen(true);
  };
  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        destroyOnClose
        width={800}
      >
        <div className="w-full mt-5">
          <div className="border-[1px] border-[#1677FF] w-[100px] text-center px-2 py-1 rounded-md text-[#1677FF]">
            {modalData?.createdDate}
          </div>
          <div className="flex items-start w-full px-4 py-4 mt-3 bg-white">
            <ShopOutlined className="text-2xl text-[#1677FF]" />
            <div className="w-full ml-2">
              <div className="flex justify-between">
                <div className="text-[17px] font-medium">
                  {modalData?.salonName}
                </div>
              </div>
              <div className="flex mt-3">
                <div className="w-[150px] text-[#888]">Nhân viên phục vụ:</div>
                <div className="">{modalData?.staffCheckin}</div>
              </div>
              <div className="flex mt-2">
                <div className="w-[150px] text-[#888]">Nhân viên checkin:</div>
                <div className="">{modalData?.staffServiceName}</div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="w-[180px] text-[#888]">DỊCH VỤ:</div>
                <div className="w-full">
                  {services &&
                    services.length > 0 &&
                    services.map((service, index) => (
                      <div
                        className="flex bg-[#dedede70] px-[7px] py-[2px] justify-between mb-1"
                        key={index}
                      >
                        <div className="">{service?.name}</div>
                        <div className="w-[180px] text-right">
                          {service?.cost?.toLocaleString('vi-VN')} đ
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="flex justify-between mt-4">
                <div className="w-[180px] text-[#888]"></div>
                <div className="w-full">
                  <div className="flex bg-[#dedede70] px-[7px] py-[2px] justify-between">
                    <div className="font-bold">TỔNG TIỀN</div>
                    <div className="w-[180px] text-right font-bold">
                      {modalData?.totalPrice?.toLocaleString('vi-VN')} đ
                    </div>
                  </div>
                  <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between">
                    <div className="">TỔNG GIẢM TRỪ</div>
                    <div className="w-[180px] text-right">
                      {modalData?.discount?.toLocaleString('vi-VN')} đ
                    </div>
                  </div>
                  <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                    <div className="">TỔNG BILL</div>
                    <div className="w-[180px] text-right">
                      {modalData?.totalBill?.toLocaleString('vi-VN')} đ
                    </div>
                  </div>
                  <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                    <div className="">ĐÃ THANH TOÁN</div>
                    <div className="w-[180px] text-right">
                      {modalData?.paid?.toLocaleString('vi-VN')} đ
                    </div>
                  </div>
                  <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                    <div className="">CÒN NỢ</div>
                    <div className="w-[180px] text-right">
                      {modalData?.debt?.toLocaleString('vi-VN')} đ
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex mt-3">
                <div className="w-[140px] text-[#888]">Trạng thái:</div>
                <div className="">Tiền mặt</div>
              </div>
              <div className="flex mt-3">
                <div className="w-[140px] text-[#888]">PTTT:</div>
                <div className="">{convertTypeBill(modalData?.status)}</div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {loading ? (
        <div className="flex justify-center py-20 mt-5 bg-white">
          <Spin />
        </div>
      ) : (
        <>
          {listBill && listBill.length > 0 ? (
            <>
              {listBill.map((item, index) => (
                <div className="w-full mt-5" key={index}>
                  <div className="border-[1px] border-[#1677FF] w-[100px] text-center px-2 py-1 rounded-md text-[#1677FF]">
                    {item?.createdDate}
                  </div>
                  <div className="flex items-start w-full px-5 py-4 mt-3 bg-white">
                    <ShopOutlined className="text-2xl text-[#1677FF]" />
                    <div className="w-[92%] ml-2">
                      <div className="flex justify-between">
                        <div className="text-[17px] font-medium">
                          {item?.salonName}
                        </div>
                        <Button
                          type="primary"
                          size="small"
                          onClick={() => handleViewDetail(item)}
                        >
                          Xem chi tiết
                        </Button>
                      </div>
                      <div className="flex mt-3">
                        <div className="w-[150px] text-[#888]">
                          Nhân viên phục vụ:
                        </div>
                        <div className="">{item?.staffCheckin}</div>
                      </div>
                      <div className="flex mt-2">
                        <div className="w-[150px] text-[#888]">
                          Nhân viên checkin:
                        </div>
                        <div className="">{item?.staffServiceName}</div>
                      </div>
                      {/* <div className="flex justify-between mt-4">
                    <div className="w-[180px] text-[#888]">SẢN PHẨM:</div>
                    <div className="w-full">
                      <div className="flex bg-[#dedede70] px-[7px] py-[2px] justify-between">
                        <div className="">
                          MDV101 Liệu trình chăm sóc da bổ sung năng lượng và
                          tăng cường sức đề kháng cho da
                        </div>
                        <div className="w-[180px] text-right">5.000.000 đ</div>
                      </div>
                      <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between">
                        <div className="">
                          MDV101 Liệu trình chăm sóc da bổ sung năng lượng và
                          tăng cường sức đề kháng cho da
                        </div>
                        <div className="w-[180px] text-right">5.000.000 đ</div>
                      </div>
                    </div>
                  </div> */}
                      <div className="flex justify-between mt-4">
                        <div className="w-[180px] text-[#888]"></div>
                        <div className="w-full">
                          <div className="flex bg-[#dedede70] px-[7px] py-[2px] justify-between">
                            <div className="font-bold">TỔNG TIỀN</div>
                            <div className="w-[180px] text-right font-bold">
                              {item?.totalPrice?.toLocaleString('vi-VN')} đ
                            </div>
                          </div>
                          <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between">
                            <div className="">TỔNG GIẢM TRỪ</div>
                            <div className="w-[180px] text-right">
                              {item?.discount?.toLocaleString('vi-VN')} đ
                            </div>
                          </div>
                          <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                            <div className="">TỔNG BILL</div>
                            <div className="w-[180px] text-right">
                              {item?.totalBill?.toLocaleString('vi-VN')} đ
                            </div>
                          </div>
                          <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                            <div className="">ĐÃ THANH TOÁN</div>
                            <div className="w-[180px] text-right">
                              {item?.paid?.toLocaleString('vi-VN')} đ
                            </div>
                          </div>
                          <div className="flex bg-[#dedede70] px-[7px] py-[2px] mt-[5px] justify-between font-bold">
                            <div className="">CÒN NỢ</div>
                            <div className="w-[180px] text-right">
                              {item?.debt?.toLocaleString('vi-VN')} đ
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex mt-3">
                        <div className="w-[140px] text-[#888]">Trạng thái:</div>
                        <div className="">Tiền mặt</div>
                      </div>
                      <div className="flex mt-3">
                        <div className="w-[140px] text-[#888]">PTTT:</div>
                        <div className="">{convertTypeBill(item?.status)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="mt-5">Khách hàng chưa có hoá đơn nào</div>
          )}
        </>
      )}
    </>
  );
};

export default ListBill;
