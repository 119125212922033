import { useState, useEffect } from 'react';
import TitleBox from '../../components/TitleBox';
import { notification } from 'antd';
import { handleStaff } from '@services';
import { useNavigate, useParams } from 'react-router-dom';
import { breadcrumbDetailStaff as breadcrumbItems } from '@components/Breadcrumb';
import UploadCustomV2 from '@components/UploadImage/UploadMono';
import { StaffForm } from '@components/Form/Staff';
import { ImageCalssEnum } from '@utils/constant/ImageType.dto';
import dayjs from 'dayjs';
import '../index.scss';

export const DetailStaff = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState<any>({ config: true });
  const { id } = useParams();

  const [avatar_url, setAvatarUrl] = useState<string>('');
  const [front_id_card_url, setFrontIdCardUrl] = useState<string>('');
  const [back_id_card_url, setBackIdCardUrl] = useState<string>('');

  const title = 'Chỉnh sửa nhân viên';
  const titleButton = '';
  const routerAdd = '';
  const forlderName = 'staff';

  const onFinish = async (values: any) => {
    try {
      const body = {
        id: id,
        username: values.username,
        avatar_url: avatar_url,
        front_id_card_url: front_id_card_url,
        back_id_card_url: back_id_card_url,
        full_name: values.full_name,
        id_card_no: values.id_card_no,
        date_of_issue: values.date_of_issue
          ? dayjs(values.date_of_issue).format('YYYY-MM-DD')
          : null,
        place_of_issue: values.place_of_issue,
        dob: values.dob ? dayjs(values.dob).format('YYYY-MM-DD') : null,
        gender: values.gender,
        province: values.province,
        district: values.district,
        ward: values.ward,
        address: values.address,
        phone: values.phone,
        email: values.email,
        social_insurance_no: values.social_insurance_no,
        tax_id_no: values.tax_id_no,
        contract_type_id: values.contract_type_id,
        work_description: values.work_description,
        promotion_date: values.promotion_date
          ? dayjs(values.promotion_date).format('YYYY-MM-DD')
          : null,
        onboarding_date: values.onboarding_date
          ? dayjs(values.onboarding_date).format('YYYY-MM-DD')
          : null,
        labour_source_id: values.labour_source_id,
        salon_id: values.salon_id,
        role_id: values.role_id,
        department_id: values.department_id,
        position_id: values.position_id,
        level_id: values.level_id,
        status_id: values.status_id,
        notes: values.notes,
        region_id: values.region_id,
        sub_region_id: values.sub_region_id,
        config: false,
      };
      console.log(body, 'body');
      setLoading(true);
      handleStaff
        .UpdateStaff(body)
        .then((response: any) => {
          const { statusCode } = response;
          if (statusCode === 200) {
            setLoading(false);
            notification.success({
              message: 'Thành công',
              description: 'Bạn đã thành công cập nhật thông tin nhân sự',
            });
            handleGetDetail();
          } else {
            throw new Error('Invalid');
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
          throw new Error('Invalid');
        });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Thành công',
        description: 'Đã có lỗi xảy ra trong quá trình xử lý dữ liệu',
      });
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };
  console.log(initialValues, 'initialValues');
  const handleGetDetail = async () => {
    handleStaff
      .GetDetailStaff(id)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          if (data) {
            const newdata = {
              id: data.id,
              username: data.username,
              full_name: data.full_name,
              id_card_no: data.id_card_no,
              date_of_issue: data.date_of_issue
                ? dayjs(data.date_of_issue)
                : null,
              place_of_issue: data.place_of_issue,
              dob: data.dob ? dayjs(data.dob) : null,
              gender: data.gender,
              province: data.province,
              district: data.district,
              ward: data.ward,
              address: data.address,
              phone: data.phone,
              email: data.email,
              social_insurance_no: data.social_insurance_no,
              tax_id_no: data.tax_id_no,
              contract_type_id: data.contract_type_id,
              work_description: data.work_description,
              promotion_date: data.promotion_date
                ? dayjs(data.promotion_date)
                : null,
              onboarding_date: data.onboarding_date
                ? dayjs(data.onboarding_date)
                : null,
              labour_source_id: data.labour_source_id,
              salon_id: data.salon_id,
              role_id: data.role_id,
              department_id: data.department_id,
              position_id: data.position_id,
              level_id: data.level_id,
              status_id: data.status_id,
              notes: data.notes,
              region_id: data.region_id,
              sub_region_id: data.sub_region_id,
              config: false,
            };
            setInitialValues(newdata);
            if (data.avatar_url) {
              setAvatarUrl(data.avatar_url);
            }
            if (data.front_id_card_url) {
              setFrontIdCardUrl(data.front_id_card_url);
            }
            if (data.back_id_card_url) {
              setBackIdCardUrl(data.back_id_card_url);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (id) {
      handleGetDetail();
    }
  }, [id]);
  return (
    <>
      <TitleBox
        breadcrumbItems={breadcrumbItems}
        title={title}
        titleButton={titleButton}
        routerAdd={routerAdd}
      />
      <div className="main main-add">
        <div className="main-left">
          {initialValues && initialValues?.id && (
            <StaffForm
              initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              loading={loading}
            />
          )}
        </div>
        <div className="main-right">
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh Avatar</h4>
            <div className="pb-4 mt-4 ml-5">
              <UploadCustomV2
                setImageUrl={setAvatarUrl}
                urlImage={avatar_url}
                imageClass={ImageCalssEnum.Avatar}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
          </div>
          <div className="main-right-wrap-card">
            <h4 className="main-right-wrap-card-title">Ảnh 2 mặt CCCD</h4>
            <div className="my-2 ml-5">
              <UploadCustomV2
                setImageUrl={setFrontIdCardUrl}
                urlImage={front_id_card_url}
                imageClass={ImageCalssEnum.Certificate}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
            <div className="pb-4 mt-5 ml-5">
              <UploadCustomV2
                setImageUrl={setBackIdCardUrl}
                urlImage={back_id_card_url}
                imageClass={ImageCalssEnum.Certificate}
                forlderName={forlderName}
              ></UploadCustomV2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
