import { instance } from '@configs/instance';

const listSalary = (params: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/salary/summary`, {
    params: params,
  });
};

const listConfigSalary = (params: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/salary/base-config`, {
    params: params,
  });
};

const createConfigSalary = (body: any): Promise<any> => {
  return instance.post(
    process.env.REACT_APP_API_STAFF + `/salary/base-config`,
    body,
  );
};

const updateConfigSalary = (body: any): Promise<any> => {
  return instance.put(
    process.env.REACT_APP_API_STAFF + `/salary/base-config`,
    body,
  );
};

const detailConfigSalary = (id: any): Promise<any> => {
  return instance.get(
    process.env.REACT_APP_API_STAFF + `/salary/base-config/${id}`,
  );
};

const getListSalaryBonus = (params: any): Promise<any> => {
  return instance.get(process.env.REACT_APP_API_STAFF + `/salary/extra-bonus`, {
    params: params,
  });
};

export default {
  listSalary,
  listConfigSalary,
  createConfigSalary,
  updateConfigSalary,
  detailConfigSalary,
  getListSalaryBonus,
};
