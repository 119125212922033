import ServiceEndpoint from '@api/service';
import { notification } from 'antd';

export const GetListService = async (param: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListService(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateBooking = async (body: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateBooking(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateActive = async (body: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateActive(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const UpdateService = async (body: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.updateService(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const CreateService = async (body: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.createService(body);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetDetailService = async (id: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getDetailService(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GenerateCodeService = async (): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.generateCodeService();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const GetListDropService = async (param): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.getListDropService(param);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
export const DeleteService = async (id: any): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.deleteService(id);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const dropdownServiceMarketing = async (): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.dropdownServiceMarketing();
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};

export const DeleteServices = async (servicesId): Promise<any | Error> => {
  try {
    const response = await ServiceEndpoint.deleteServices(servicesId);
    if (response.data) {
      return response.data;
    }
  } catch (error) {
    notification.error({
      message: 'Lỗi',
      description: error?.response?.data?.message,
    });

    return error?.response?.data;
  }
};
